import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {useNavigate } from 'react-router-dom';
import Banner from "../includes/customer/Banner";
import Footer from "../includes/Footer";
import keyhandler from "../../assets/images/customer/key.png";
import events from "../../assets/images/customer/event.png";
import { Link } from "react-router-dom";
import Header from "../includes/Header";
import { accountsConfig } from "../../axiosConfig";
import Helmet from "../includes/Helmet";

export default function CustomerCelebration() {
  const [customerCelebrate, setCustomerCelebrate] = useState([]);
  const [datas, setdatas] = useState({});

  const navigate = useNavigate();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  
  useEffect(() => {
    accountsConfig.get('web/list-link-seo/',{
      params:{
        links:'/our-community'
      }
    })
    .then((res) => {
     const { data , StatusCode } = res.data.app_data;
       if(StatusCode === 6000){
          setdatas(data[0])
       }else{
          setdatas({})
       }
    })
  },[])
  
  useEffect(() => {
    accountsConfig
      .get(`customer_celebrations/list-customer-celebrations/`, {})
      .then((res) => {
        const data = res.data.app_data.data;
        if (res.data.app_data.StatusCode == 6000) {
          setCustomerCelebrate(data);
        } else {
          setCustomerCelebrate([]);
        }
      });
  }, []);

  return (
    <Container>
      <Helmet titleData={datas?.meta_title} descriptionData={datas?.meta_description} />
      <Header />
      <Banner />
      <Cards>
        <Card onClick={()=>navigate(`/${customerCelebrate[0]?.celebration_name?.toLowerCase().replace(/ /g, '-')}`)}>
          <ImageContainer>
            <img src={customerCelebrate[0]?.image} alt="" />
          </ImageContainer>
          <Description>
            {customerCelebrate[0] && customerCelebrate[0]?.description}
          </Description>
          <See
            to={`/${customerCelebrate[0]?.celebration_name?.toLowerCase().replace(/ /g, '-')}`}
          >
            See More
          </See>
        </Card>
        <Card onClick={()=>navigate(`/${customerCelebrate[1]?.celebration_name?.toLowerCase().replace(/ /g, '-')}`)}>
          <ImageContainer>
            <img src={customerCelebrate[1]?.image} alt="" />
          </ImageContainer>
          <Description>
            {customerCelebrate[1] && customerCelebrate[1]?.description}
          </Description>
          <See
            to={`/${customerCelebrate[1]?.celebration_name?.toLowerCase().replace(/ /g, '-')}`}
          >
            See More
          </See>
        </Card>
      </Cards>
      <Footer />
    </Container>
  );
}
const Container = styled.div`
  background-color: #f8f8f8;
`;
const Cards = styled.div`
  display: flex;
  padding: 80px 0;
  justify-content: center;
  gap: 50px;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Card = styled.div`
  width: 30%;
  background-color: #fff;
  padding-bottom: 10px;
  cursor: pointer;
  
  @media all and (max-width: 1440px) {
    width: 40%;
  }
  @media all and (max-width: 768px) {
    width: 65%;
    margin: 0 auto;
  }
  @media all and (max-width: 480px) {
    width: 80%;
  }
`;
const ImageContainer = styled.div`
  margin-bottom: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
const Description = styled.p`
  line-height: 28px;
  text-align: justify;
  width: 95%;
  margin: 0 auto;
  @media all and (max-width: 480px) {
    width: 85%;
  }
`;
const See = styled(Link)`
  width: fit-content;
  padding-top: 20px;
  text-decoration: none;
  cursor: pointer;
  display: block;
  color: #000;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--orange);
  margin-left: 18px;
`;
