import React from "react";
import styled from "styled-components";
export default function Map({ getSingleProject }) {
  return (
    <MainContainer>
      <Wrapper className="wrapper">
        <Content>
          <Title>
            Location <span>Map</span>
          </Title>
          <Cover>
            <Left>
              <MapCover>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getSingleProject?.location_embed_link,
                  }}
                />
              </MapCover>
            </Left>
            {getSingleProject?.location_image !== null && (
              <Right>
                <img src={getSingleProject?.location_image} alt="" />
              </Right>
            )}
          </Cover>
        </Content>
      </Wrapper>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  padding: 80px 0;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Title = styled.h1`
  color: #606060;
  letter-spacing: 2px;
  font-family: "gellix-bold";
  font-size: 50px;
  margin-bottom: 30px;
  span {
    color: #eb6502;
  }
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
`;
const Cover = styled.div`
  display: flex;
  gap: 50px;
  justify-content: space-between;
  @media all and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;
const Left = styled.div`
  width: 100%;
`;
const Right = styled.div`
  width: 100%;
`;

const MapCover = styled.div`
  width: 100%;

  iframe {
    width: 100% !important;
    max-height: 800px;
    min-height: 650px;
  }
  @media (max-width: 480px) {
    display: block;
  }
`;
