import { useEffect } from 'react'

function Helmet({titleData,descriptionData}) {
    const title =  document.querySelector('title')
    const meta_desc =  document.querySelector('meta[name=description]')
    useEffect(() => {
        
        if(titleData){
           title.innerText = titleData;
        }

        if(descriptionData){
           meta_desc.setAttribute('content',descriptionData);
        }

        return () => {
            title.innerText = "Classic Homes";
            meta_desc.setAttribute('content','');
        };
    }, [titleData,descriptionData])
    
    return 
}

export default Helmet;