import React, { useEffect, useState } from "react";
import styled from "styled-components";

import callIcon from '../../../assets/images/contact/callBtn.svg'
import callIcon2 from '../../../assets/images/contact/callBtn2.svg'
import mailIcon from '../../../assets/images/contact/mailIcon.svg'
import locationIcon from '../../../assets/images/contact/locationIcon.svg'
import landPhnIcon from '../../../assets/images/icons/landphone.svg';
import { accountsConfig } from "../../../axiosConfig";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";

function ContactInfo() {
  const [contactInfo, setcontactInfo] = useState({});
  const [formData, setformData] = useState({name:'',phone:'',email:'',message:''});
  const [submited, setsubmited] = useState(false);
  const [loading, setloading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  
  useEffect(() => {
    accountsConfig.get('web/list-contact-details/').then((response)=>{
      const { StatusCode , data}  = response.data.app_data;
      
      if(StatusCode === 6000){
        setcontactInfo(data[0])
      }
    })
  }, [])

  const handleChange = (e)=>{
    const { name , value } = e.target
    setformData((prev)=>{
      return {
        ...prev,
        [name] : value
      }
    })
  }

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      timer: 1500,
      iconColor: "#0e2d5e",
    });
  };

  const submitEnquiry = (e) => {
    e.preventDefault()
    setloading(true)
    const form = new FormData();
    Object.entries(formData).forEach((item)=>{
      form.append(item[0],item[1]);
    })

    accountsConfig.post('projects/quick-enquiry/',form).then((response)=>{
      const {data,StatusCode} = response.data.app_data;
      if(StatusCode === 6000){
         setloading(false)
         e.target.reset()
         showSuccessAlert();
      }
    })
    
  }

  return (
    <Section>
      <Wrapper className="wrapper">
        <Container>
          <BoxContainer>
            <Box>
            <Title>
              <Classic>CONTACT</Classic>US
            </Title>
              <Line />
              <SubTitle>Leave in your details below for a quick catchup</SubTitle>
            </Box>
            <Box>
               <Para>
               Get in touch with us at Classic Homes and turn your dream home into a reality. Our dedicated team is here to assist you every step of the way. Reach out today, and to know more and schedule a visit to find the right home that fits your tastes and lifestyle preferences.
               </Para>
            </Box>
          </BoxContainer>
          <MapMob>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31432.787877305567!2d76.30750610828346!3d10.00872346620095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080c60494e1fe5%3A0x52a834b68c110ec!2sClassic%20Homes!5e0!3m2!1sen!2sin!4v1699357698331!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </MapMob>
           <BoxContainer className='enquiry-form-box'>
            <Box className="address">
              <InfoBoxMob>
                  <InfoBoxSubTitle>
                    HAVE A QUESTION?
                  </InfoBoxSubTitle>
                  <LineMob/>
                  <InfoBoxTitle>
                  Talk To Our <SpanMob>Expert</SpanMob>
                  </InfoBoxTitle>
              </InfoBoxMob>
               <AddressContainer>
                 <Item>
                    <Icon>
                    <img src={callIcon} alt="" />
                    </Icon>
                    <Content>
                      <Span href={`tel:${contactInfo?.phone1}`}>
                      {contactInfo?.phone1}
                      </Span>
                      <Span href={`tel:${contactInfo?.phone2}`}>
                        {contactInfo?.phone2}
                      </Span>
                    </Content>
                 </Item>
                 <Item>
                    <Icon>
                    <img src={landPhnIcon} alt="" />
                    </Icon>
                    <Content>
                    <Span href={`tel:${contactInfo?.phone3}`}>
                        {contactInfo?.phone3}
                      </Span>
                    </Content>
                 </Item>
                 <Item>
                    <Icon>
                    <img src={mailIcon} alt="" />
                    </Icon>
                    <Content>
                    <Span href={`mailto:${contactInfo?.email1}`}>
                        {contactInfo?.email1}
                      </Span>
                    {/* <Span href={`mailto:${contactInfo?.email2}`}>
                        {contactInfo?.email2}
                    </Span> */}
                    </Content>
                 </Item>
                 <Item>
                    <Icon>
                    <img src={locationIcon} alt="" />
                    </Icon>
                    <Content>
                       <Span className="location-info">
                       {contactInfo?.address}
                       </Span>
                    </Content>
                 </Item>
               </AddressContainer>
            </Box>
            <Box className="enquiry-form">
               <BoxTitle>
               Get in touch
               </BoxTitle>
               {/* {
                submited && <Success> Message Sent Successfully </Success>
               } */}
               <Form onSubmit={(e)=>submitEnquiry(e)}>
                <FormItem className="fw">
                    <Name>
                      Your Name*
                    </Name>
                    <TextInput 
                     name="name"
                     type="text"
                     required={true}
                     onChange={(e)=>handleChange(e)}
                    />
                </FormItem>
                <FormItem>
                    <Name>
                      Email
                    </Name>
                    <TextInput
                    name="email"
                    type="email"
                    onChange={(e)=>handleChange(e)}
                    />
                </FormItem>
                <FormItem>
                    <Name>
                      Number*
                    </Name>
                    <TextInput
                    name="phone"
                    type="number"
                    onChange={(e)=>handleChange(e)}
                    required
                    />
                </FormItem>
                <FormItem className="fw">
                    <Name>
                      Message
                    </Name>
                    <TextAreaInput
                    name="message"
                    type="text"
                    required
                    rows={2}
                    onChange={(e)=>handleChange(e)}
                    />
                </FormItem>

                <SubmitBtn>
                  {loading ? <ClipLoader
                    color={color}
                    loading={loading}
                    // cssOverride={override}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  /> : "Send Message"}
                </SubmitBtn>
                 
               </Form>
            </Box>
          </BoxContainer>
        </Container>
      </Wrapper>
      <Map>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31432.787877305567!2d76.30750610828346!3d10.00872346620095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080c60494e1fe5%3A0x52a834b68c110ec!2sClassic%20Homes!5e0!3m2!1sen!2sin!4v1699357698331!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </Map>
    </Section>
  );
}

export default ContactInfo;

const Section = styled.div`
      display: flex;
      flex-direction: column;
      gap: 130px;
      margin-top: 130px;

      @media screen and (max-width:580px) {
        margin-top: 60px;
      }
`;

const Wrapper = styled.div`
    @media(max-width:580px){
        width: 100%;
    }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 130px;

  @media(max-width:580px){
    gap:65px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  
  @media(max-width:1200px){
    flex-direction: column;
    gap:65px;
  }

  @media(max-width:580px){
    width:80%;
    margin: 0 auto;
    gap:35px;

    &.enquiry-form-box{
      width:100%;
    }
  }
`;

const Box = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: flex-start;

  &.enquiry-form{
    background-color: #fff;
    justify-content: flex-start;
    padding: 45px;
  }

  @media(max-width:1200px){
        justify-content: center;
        align-items: center;
  }

  @media(max-width:580px){
    &.address{
      width:80%;
      margin: 0 auto;
    }

    &.enquiry-form{
      background-color: #F5F5F5;
      width: 80%;
      padding: 35px;
    }
  }

`;
const Title = styled.div`
  display: flex;
  color: #eb6502;
  font-family: "gellix-regular";
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 3.75px;
  gap: 10px;

  @media(max-width:580px){
    font-size: var(--heading-fz);
  }
`;

const Line = styled.div`
  height: 0.5px;
  width: 50px;
  background-color:#606060;
`;

const SubTitle = styled.div`
  color: #000000;

  /* Para reg 16 caps */
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
  text-transform: uppercase;

  @media (max-width: 580px) {
    text-align: center;
    width: 100%;
  }
`;

const Classic = styled.div`
  color: #606060;
  font-size: 65px;

  @media(max-width:580px){
    font-size: var(--heading-fz);
  }
`;

const Para = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  text-align: justify;
  
  @media(max-width:615px){
      text-align: justify;
  }

  `;

const AddressContainer = styled.div`
      display: flex;
      flex-direction: column;
      gap: 55px;
      width:100%;
      
      @media(max-width:1200px){
        flex-direction: row;
        flex-wrap: wrap;
      }

      @media(max-width:768px){
        flex-direction:column;
      }


`;


const Item = styled.div`
      display: flex;
      gap: 40px;
      
      @media(max-width:1200px){
        width: 45%;
      }

      @media(max-width:768px){
        width:50%;
        margin: 0 auto;
      }
      @media(max-width:580px){
        width:90%;
        margin: 0 auto;
      }
      @media(max-width:375px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
`;

const Icon = styled.div`
      display: flex;
      align-items: center;
      justify-content:center;
      
      img{
        width: 60px;
        height: 60px;
      }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap:18px;
    justify-content: center;
`;

const Span = styled.a`
        color: #606060;
        font-family: 'gellix-regular';
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 21px */
        display: flex;
        align-items:center;
        text-decoration: none;

        &.location-info{
            width: 21ch;
        }

        @media(max-width:375px){
          justify-content: center;
          text-align: center;
        }
`;

const BoxTitle = styled.p`
    color: #606060;
    font-family: 'gellix-regular';
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.51px;color: #606060;
    font-family: Gellix;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.51px;
    margin: 0;
    padding: 0;
`;

const Form = styled.form`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap:35px;
    justify-content: space-between;

    @media(max-width:480px){
      padding: 30px;
    }
`;

const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    width:45%;
    gap: 15px;

    &.fw{
        width: 100%;
    }

    @media(max-width:580px){
      width: 100%;
    }
`;

const Name = styled.div`
        color: #706E6C;
        text-align: justify;
        font-family: 'gellix-regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
`;

const TextInput = styled.input`
      outline:none;
      border: none;
      border-bottom: 1px solid #606060;

      @media(max-width:580px){
        background-color: transparent;
      }
`;

const TextAreaInput =styled.textarea`
      outline:none;
      border: none;
      border-bottom: 1px solid #606060;
      resize: vertical;

      @media(max-width:580px){
        background-color: transparent;
      }
`;

const SubmitBtn = styled.button`
    padding: 16px 27px;
    background: #EB6502;
    outline: none;
    border: none;
    color: #FFF;
    font-family: 'gellix-regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 20px */
    cursor: pointer;
`;

const Map = styled.div`
    display: flex;
    width: 100%;

    iframe{
        width:100% !important;
        max-height: 800px;
        min-height: 650px;
    }

    @media(max-width:580px){
      display: none;
    }
`;

const MapMob = styled.div`
    display: flex;
    width: 100%;

    iframe{
        width:100% !important;
        max-height: 600px;
        min-height: 450px;
    }

    @media(min-width:580px){
      display: none;
    }
`;

const InfoBoxMob = styled.div`
      display: flex;
      flex-direction: column;
      gap:24px;
      width:100%;
      margin-bottom: 24px;

      @media(min-width:580px){
        display: none;
      }
`;

const InfoBoxTitle = styled.p`
      color: #606060;
      text-align: center;
      font-family: "gellix-regular";
      font-size: 34px;
      font-style: normal;
      font-weight: 600;
      line-height: 85%; /* 28.9px */
      letter-spacing: 0.51px;
      display: flex;
      gap:10px;
      justify-content: center;
      align-items: center;
      margin: 0;

    @media(max-width:400px){
    font-size: 29px;
    }
`;

const LineMob = styled.div`
    height: 1px;
    width: 40px;
    background-color:#EB6502;
    margin: 0 auto;
`;

const InfoBoxSubTitle = styled.p`
      color: #606060;
      text-align: center;
      font-family: 'gellix-regular';
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 3.6px;
`;

const SpanMob = styled.p`
      color: #EB6502;
      margin:0;
      padding: 0;
`;

const Success = styled.p`
    margin: 0;
    padding: 0;
    color: green;
    font-size: 14px;
`;