import React from "react";
import styled from "styled-components";
import client from "../../../../assets/images/customer/clientslider.png";
import Slider from "react-slick";

export default function ViewDetail({ data, fetchedData }) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    vertical: true,
    arrows: false,
    verticalSwiping: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 2000,
  };
  return (
    <Container>
      <Top>
        <Right>
          <Detail>
            {/* <Text >{fetchedData.name}</Text> */}
            <Text dangerouslySetInnerHTML={{ __html: fetchedData.name }}></Text>
            <Span>
              {fetchedData.designation} <br /> {fetchedData.location}
            </Span>
          </Detail>
        </Right>
        <Left>
          <Slider {...settings}>
            {data.map((item, index) => (
              <ImageContainer key={index}>
                <img src={item.image} alt="image" />
              </ImageContainer>
            ))}
          </Slider>
        </Left>
      </Top>
    </Container>
  );
}

const Container = styled.div`
  background-color: #1a1a18;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
`;
const Top = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
`;
const Left = styled.div`
  width: 50%;
  .slick-track {
    height: auto !important;
  }
`;
const Right = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Detail = styled.div`
  margin-top: 40px;
`;
const Text = styled.h2`
  color: var(--orange);
  text-align: center;
  font-size: 32px;
  margin-bottom: 10px;
`;
const Span = styled.h2`
  color: #fff;
  text-align: center;
`;
const ImageContainer = styled.div``;
