import React from 'react'
import styled from 'styled-components';

function VillasAbout() {
  return (
    <MainContainer>
       <Title>Classic Elegance, Modern Comfort, Convenient Living</Title>
        <Para>Classic Homes presents premium 3 & 4 BHK villas at Kakkanad, Kochi offering calm living away from city rush with luxury and convenience. Located near Infopark, they come in proximity with every expected amenity.</Para>
    </MainContainer>
  )
}

export default VillasAbout

const MainContainer = styled.div`
  padding-top: 40px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media all and (max-width: 480px) {
    padding-top: 40px;
  }
`;

const Title = styled.h2`
  font-family: "gellix-medium";
  text-align: center;
  margin-top: 10px;
  width: 76%;


  @media (max-width: 1300px) {
    font-size: 21px;
    width: 90%;
  }
`;

const Para = styled.p`
  line-height: 28px;
  width: 66%;
  text-align: justify;

  @media (max-width: 1300px) {
    width: 90%;
  }
`;