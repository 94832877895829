import React, { useState } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  EmailShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon,
} from "react-share";
import Swal from "sweetalert2";

import printIcon from "../../../assets/images/blogInner/icons/print.svg";
import copyIcon from "../../../assets/images/blogInner/icons/copy.svg";
import copiedIcon from "../../../assets/images/blogInner/icons/copied.svg";
import shareIcon from "../../../assets/images/blogInner/icons/share.svg";
import playButton from "../../../assets/images/blogInner/icons/playBtn.svg";
import { useEffect } from "react";
import { accountsConfig } from "../../../axiosConfig";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nodata from "../helper/Nodata";
import ClipLoader from "react-spinners/ClipLoader";
import Helmet from "../Helmet";

function BlogDetail() {
  const [show, setshow] = useState(false);
  const [copied, setcopied] = useState(false);
  const [share, setshare] = useState(false);
  const [blog, setblog] = useState({});
  const [blogs, setblogs] = useState({});
  const [comments, setcomments] = useState([]);
  const [state, setstate] = useState(false);
  const [loading, setloading] = useState(false);
  const url = window.location.href;
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    accountsConfig.get("web/single-blog/" + slug).then((response) => {
      const { data, StatusCode } = response.data.app_data;
      if (StatusCode === 6000) {
        setblog(data);
        setstate(!state);
      } else {
        window.location.href = "/page-not-found";
      }
    });

    accountsConfig.get("web/list-blogs/").then((response) => {
      const { data, StatusCode } = response.data.app_data;
      if (StatusCode === 6000) {
        setblogs(data);
      }
    });
  }, [slug]);

  useEffect(() => {
    if (blog?.id) {
      accountsConfig.get("web/list-comment/" + blog?.id).then((response) => {
        const { data, StatusCode } = response.data.dev_data;
        if (StatusCode === 6000) {
          setcomments(data);
        } else {
          setcomments([]);
        }
      });
    }
  }, [state]);

  const CreateComment = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    setloading(true);
    accountsConfig
      .post("web/create-comment/" + blog?.id + "/", form)
      .then((response) => {
        const { data, StatusCode } = response.data.dev_data;
        if (StatusCode === 6000) {
          setloading(false);
          setstate(!state);
          showSuccessAlert();
          e.target.reset();
        } else {
          setcomments([]);
        }
      });
  };

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      html: "<p>Submitted successfully and waiting for approval</p>",
      timer: 2500,
      iconColor: "#1eb300",
    });
  };

  const handlePrint = () => {
    window.print();
  };

  const FilterByTag = (id) => {
    navigate("/blogs/", {
      state: {
        tagId: id,
      },
    });
  };

  const handleCopy = () => {
    setcopied(true);
    setTimeout(() => {
      setcopied(false);
    }, 3000);
  };

  const handleClick = (url) => {
    navigate("/blog/" + url, {
      state: {
        Page: 1,
      },
    });
  };

  return (
    <Section>
      <Helmet titleData={blog?.meta_title} descriptionData={blog?.meta_description} />
      <Wrapper className="wrapper">
        <Left>
          <BlogConatiner>
            <BlogFile>
              {blog?.image ? (
                <img src={blog.image} alt={blog?.alt_tag} />
              ) : (
                <video src={blog.video}></video>
              )}
            </BlogFile>
            <BlogTitle>{blog?.title}</BlogTitle>
            <Para dangerouslySetInnerHTML={{ __html: blog?.description }} />
          </BlogConatiner>
          <Bottom>
            {blog?.project && (
              <VistProject
                to={
                  blog?.project?.project_type === "villas"
                    ? `/villas-for-sale-in-kochi/${blog?.project?.project_slug}`
                    : `/apartments-for-sale-in-kochi/${blog?.project?.project_slug}`
                }
              >
                Visit this project
              </VistProject>
            )}
            <ActionButtons>
              <Button onClick={handlePrint}>
                <img src={printIcon} alt="" />
              </Button>
              <Button>
                <CopyToClipboard text={url} onCopy={() => handleCopy()}>
                  <img src={copied ? copiedIcon : copyIcon} alt="" />
                </CopyToClipboard>
              </Button>
              <Button onClick={() => setshare(!share)}>
                <img src={shareIcon} alt="" />
                {share && (
                  <ShareBtnContainer>
                    <FacebookShareButton url={url}>
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>

                    <EmailShareButton url={url}>
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>

                    <WhatsappShareButton url={url}>
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>

                    <LinkedinShareButton url={url}>
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>

                    <TwitterShareButton url={url}>
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                  </ShareBtnContainer>
                )}
              </Button>
            </ActionButtons>
          </Bottom>
        </Left>
        <Right>
          <RecentBlogs>
            <RecentBlogsTitle>RECENT POSTS</RecentBlogsTitle>
            <Line />
            <RecentBlogsList>
              {blogs?.results?.slice(-5)?.map((obj, indx) => (
                <SingleRecentBlog onClick={() => handleClick(obj?.slug)}>
                  <Img>
                    <img src={obj.image} alt={obj?.alt_tag} />
                  </Img>
                  <RecentBlogDesc
                    dangerouslySetInnerHTML={{
                      __html: obj?.title,
                    }}
                  >
                  </RecentBlogDesc>
                </SingleRecentBlog>
              ))}
            </RecentBlogsList>
          </RecentBlogs>

          <TagsContainer>
            <TagsTitle>TAGS</TagsTitle>
            <Line />
            <TagsList>
              {blog?.tags?.length == 0
                ? "No tags found"
                : blog?.tags?.map((item) => {
                    return (
                      <TagItem
                        className="active"
                        onClick={() => FilterByTag(item?.id)}
                      >
                        {item?.name}
                      </TagItem>
                    );
                  })}
            </TagsList>
          </TagsContainer>

        </Right>
      </Wrapper>
      <Wrapper className="wrapper">
        <CommentSection>
          <LeftSide>
            <Container>
              <Heading>Comments</Heading>
              {/* {comments?.length > 2 && (
                <ViewAll onClick={() => setshow(!show)}>
                  <Span>{show ? "View Less" : "View All"}</Span>
                  <Icon className={show && "active"}>
                    <img src={icon} alt="" />
                  </Icon>
                </ViewAll>
              )} */}
            </Container>
            <Comments>
              {comments?.length === 0 ? (
                <Nodata h={"200px"} />
              ) : (
                comments.map((obj, indx) => (
                  <Comment>
                    <Top>
                      <Name>{obj.name}</Name>
                      <Date>{obj.created_at}</Date>
                    </Top>
                    <Text>{obj.comment}</Text>
                  </Comment>
                ))
              )}
            </Comments>
          </LeftSide>
          <RightSide>
            <Heading>Leave A Comment</Heading>
            <Form onSubmit={(e) => CreateComment(e)}>
              <FormItem className="fw">
                <LabelName>Your Comment*</LabelName>
                <TextInput name="comment" type="text" required />
              </FormItem>
              <FormItem>
                <LabelName>Name*</LabelName>
                <TextInput name="name" type="text" required />
              </FormItem>
              <FormItem>
                <LabelName>Email*</LabelName>
                <TextInput name="email" type="email" required />
              </FormItem>

              <SubmitBtn>
                {loading ? (
                  <ClipLoader
                    color={"#ffff"}
                    loading={loading}
                    // cssOverride={override}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Post Comment"
                )}
              </SubmitBtn>
            </Form>
          </RightSide>
        </CommentSection>
      </Wrapper>
    </Section>
  );
}

export default BlogDetail;

const Section = styled.div`
  display: flex;
  margin-bottom: 90px;
  flex-direction: column;
  gap: 40px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  /* flex: 2; */
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Right = styled.div`
  /* flex: 1; */
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const BlogConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  @media (max-width: 568px) {
    gap: 32px;
  }
`;

const SingleBlog = styled.div``;

const BlogImage = styled.div``;

const BlogInfo = styled.div`
  padding: 35px;
  background-color: #ffff;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const Para = styled.p`
  color: #706e6c;
  text-align: left;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  width: 100%;

  p {
    word-wrap: break-word;
  }
`;

const Tags = styled.div`
  display: flex;
  gap: 35px;
`;

const Tag = styled.div`
  color: #ffa466;
  /* Para reg 16 caps */
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
`;

const BlogTitle = styled.div`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.51px;

  @media (max-width: 475px) {
    font-size: 25px;
  }
`;

const BlogDesc = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
`;

const BlogBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ReadMore = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  border-bottom: 1px solid #eb6502;
`;

const SearchContainer = styled.div`
  padding: 40px;
  width: 100%;
  background-color: #fff;
`;

const BlogFile = styled.div`
  position: relative;
  &.video {
    &::after {
      position: absolute;
      content: "";
      inset: 0;
      background-image: url(${playButton});
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center;
    }
  }

  img {
    width: 100%;
    object-fit: contain;
    display: block;
  }
`;

const RecentBlogs = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  width: calc(100% - 80px);

  @media (max-width: 350px) {
    padding: 40px 20px;
    width: calc(100% - 40px);
  }
`;

const Bottom = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column-reverse;
    gap: 25px;
  }
`;

const Category = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  width: calc(100% - 80px);

  @media (max-width: 350px) {
    padding: 40px 20px;
    width: calc(100% - 40px);
  }
`;

const TagsContainer = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  width: calc(100% - 80px);

  @media (max-width: 350px) {
    padding: 40px 20px;
    width: calc(100% - 40px);
  }
`;

const SearchInput = styled.input`
  background: #f4f4f4;
  padding: 15px;
  border: none;
  outline: none;
  position: relative;
  width: 90%;
  ${(props) => props.icon && `background-image: url('${props.icon}')`};
  background-repeat: no-repeat;
  background-position: 95% center;

  &::placeholder {
    font-size: 15px;
    font-size: "gellix-regular";
  }
`;

const RecentBlogsTitle = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.1px;
  width: 100%;

  @media (max-width: 400px) {
    font-size: 25px;
  }
  @media (max-width: 350px) {
    font-size: 18px;
  }
`;

const Line = styled.div`
  height: 0.5px;
  background: #bcbcbc;
  width: 100%;
`;

const RecentBlogsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const SingleRecentBlog = styled.div`
  display: flex;
  gap: 20px;
  cursor: pointer;

  @media (max-width: 580px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Img = styled.div`
  flex: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const RecentBlogDesc = styled.div`
  flex: 2;
  color: #706e6c;
  text-align: left;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  display: flex;
  align-items: center;
`;

const CategoryTitle = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.1px;
  width: 100%;
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const CategoryItem = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
`;

const TagsTitle = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.1px;
  width: 100%;
`;

const TagsList = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const TagItem = styled.div`
  padding: 8px 16px;
  color: #fff;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  background-color: #aaa;
  cursor: pointer;

  &.active {
    background: #eb6502;
  }
`;

const CommentSection = styled.div`
  width: calc(100% - 110px);
  padding: 55px;
  background-color: #ffff;
  display: flex;
  gap: 55px;

  @media (max-width: 1150px) {
    flex-direction: column;
    gap: 40px;
  }

  @media (max-width: 450px) {
    width: calc(100% - 50px);
    padding: 25px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  flex: 1;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  flex: 1;
`;

const Heading = styled.h1`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.51px;

  @media (max-width: 400px) {
    font-size: 25px;
  }
`;

const Comments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0 5px;

  height: 325px;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff8f8;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffd3b3dc;
    width: 10px;
  }
`;

const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Top = styled.div`
  display: flex;
  gap: 55px;
  align-items: center;

  @media (max-width: 475px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    align-items: flex-start;
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
`;

const Name = styled.p`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  letter-spacing: 0.36px;
  margin: 0;
`;

const Date = styled.div`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.24px;
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 35px;
  justify-content: space-between;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 30px;

  &.fw {
    width: 100%;
  }

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const LabelName = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
`;

const TextInput = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid #606060;
`;

const SubmitBtn = styled.button`
  padding: 16px 27px;
  background: #eb6502;
  outline: none;
  border: none;
  color: #fff;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* @media(max-width:475px){
    flex-direction: column;
  } */
`;

const ViewAll = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    img {
      transform: rotate(180deg);
    }
  }
`;

const Span = styled.span`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  border-bottom: 1px solid #ffa466;

  @media (max-width: 475px) {
    display: none;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  @media (max-width: 450px) {
    width: 50%;
    margin: 0 auto;
  }
`;

const Button = styled.div`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
`;

const ShareBtnContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  gap: 15px;
  position: absolute;
  top: calc(19px + 110%);
  right: 0%;

  &::after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    top: -19px;
    right: 5px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #ffffff;
  }
`;

const VistProject = styled(Link)`
  text-decoration: none;
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%; /* 38.5px */
  border-bottom: 1px solid #ffa466;
  cursor: pointer;
  height: 50px;

  @media (max-width: 450px) {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }
`;
