import React, { useState } from "react";
import styled from "styled-components";
import phone from "../../../../assets/images/apartments/talk/phone.png";
import headphone from "../../../../assets/images/apartments/talk//headphone.png";
import landphone from '../../../../assets/images/icons/landphone.svg'
import mail from "../../../../assets/images/apartments/talk/mail.png";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { accountsConfig } from "../../../../axiosConfig";
import ButtonLoader from "../../../general/loader/ButtonLoader";

export default function TalkToExpert({ getSingleProject }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState("");
  const { id } = useParams();

  const formData = new FormData();
  formData.append("name", name);
  formData.append("phone", number);
  formData.append("email", email);
  formData.append("message", message);
  formData.append("project", getSingleProject?.id);

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      timer: 1500,
      iconColor: "#0e2d5e",
    });
    setName("");
    setNumber("");
    setEmail("");
    setMessage("");
  };
  //show alert for error
  const notify = () => {
    toast.error("Please try with another number!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  //submit enquiry
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    accountsConfig
      .post("projects/add-enquiry/", formData)
      .then((res) => {
        if (res.data.app_data.StatusCode === 6000) {
          setLoading(false);
          showSuccessAlert();
        } else {
          setLoading(false);
          notify();
        }
      })
      .catch((error) => {});
  };

  return (
    <MainContainer>
      <Wrapper className="wrapper">
        <Content>
          <Left>
            <SubTitle>HAVE A QUESTION?</SubTitle>
            <Line></Line>
            <Title>
              Talk To Our <span>Expert</span>
            </Title>
            <Contact>
              <Cover>
                <Icon>
                  <img src={phone} alt="" />
                </Icon>
                <CoverNumber>
                  <Number className="num" href="tel:+91 9072372377">+91 9072372377</Number>
                  <Number className="num" href="tel:+91 9048306817">+91 9048306817</Number>
                </CoverNumber>
              </Cover>
              <Cover>
                <Icon>
                  <img src={landphone} alt="" />
                </Icon>
                <CoverNumber>
                  <Number className="num" href="tel:+91 484 2427277">+91 484 2427277</Number>
                </CoverNumber>
              </Cover>
              <Cover>
                <Icon>
                  <img src={mail} alt="" />
                </Icon>
                <CoverNumber>
                  <Number className="num" href="mailto:sales@classichomes.in">
                    sales@classichomes.in
                  </Number>
                  {/* <Number href="mailto:marketing@classichomes.in">
                    marketing@classichomes.in
                  </Number> */}
                </CoverNumber>
              </Cover>
            </Contact>
          </Left>
          <Right>
            <CallBack onSubmit={(e) => submitHandler(e)}>
              <TitleBar>Request a callback</TitleBar>
              <Label>Your Name*</Label>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <CoverInput>
                <CoverLabel>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </CoverLabel>
                <CoverLabel>
                  <Label>Number*</Label>
                  <Input
                    type="number"
                    required
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </CoverLabel>
              </CoverInput>
              <Label>Message</Label>
              <TextArea
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              ></TextArea>
              {isLoading ? (
                <Button>
                  <ButtonLoader />
                </Button>
              ) : (
                <Button>Send Message</Button>
              )}
            </CallBack>
          </Right>
        </Content>
      </Wrapper>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  padding: 80px 0;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
  @media all and (max-width: 580px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
const Line = styled.div`
  width: 30px;
  height: 1px;
  background-color: #eb6502;
  margin-top: 20px;
`;
const SubTitle = styled.h5`
  color: #606060;
  letter-spacing: 3px;
  font-family: "gellix-light";
`;

const Title = styled.h1`
  color: #606060;
  letter-spacing: 2px;
  font-family: "gellix-bold";
  font-size: 50px;
  margin-bottom: 30px;
  span {
    color: #eb6502;
  }
  
  @media screen and (max-width:580px){
      text-align:center;
  }

  @media all and (max-width: 480px) {
    font-size: 28px;
    margin-top: 20px;
  }
`;
const Contact = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;

  @media(max-width:580px){
    margin-top: 20px;
    width:80%;
  }
`;
const Cover = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 20px;
  width: 50%;
  @media all and (max-width: 580px) {
    width: 100%;
  }
`;
const Icon = styled.div`
  width: 65px;
  @media all and (max-width: 480px) {
    width: 50px;
  }
`;
const CoverNumber = styled.div``;
const Number = styled.a`
  color: #606060;
  margin-bottom: 10px;
  display: block;
  &.num{
    color:#000;
    font-family: 'gellix-bold';
    font-weight: 600;
  }
`;
const Right = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const CallBack = styled.form`
  background-color: #f5f5f5;
  padding: 40px;
`;
const TitleBar = styled.h3`
  color: #606060;
  font-family: "gellix-medium";
  font-size: 24px;
  margin-bottom: 20px;
`;
const Label = styled.h4`
  color: #706e6c;
`;
const Input = styled.input`
  outline: none;
  background: none;
  border: none;
  height: 30px;
  border-bottom: 1px solid #9c9c9c;
  width: 100%;
  margin-bottom: 20px;
`;
const CoverInput = styled.div`
  display: flex;
  gap: 20px;
`;
const CoverLabel = styled.div`
  width: 50%;
`;
const TextArea = styled.textarea`
  width: 100%;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #9c9c9c;
  padding: 10px 0 0 0;
  height: 60px;
`;
const Button = styled.button`
  width: 150px;
  background-color: var(--orange);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
`;
