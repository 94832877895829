import React, { useState } from 'react'
import Header from '../includes/Header';
import BreadCrumb from '../includes/about/BreadCrumb';
import Footer from '../includes/Footer';
import BriefInfo from '../includes/about/BriefInfo';
import styled from 'styled-components';
import MoreInfo from '../includes/about/MoreInfo';
import FounderInfo from '../includes/about/FounderInfo';
import Partners from '../includes/about/Partners';
import { useEffect } from 'react';
import Helmet from '../includes/Helmet';
import { accountsConfig } from '../../axiosConfig';

function About() {
  const [datas, setdatas] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  
  useEffect(() => {
    accountsConfig.get('web/list-link-seo/',{
      params:{
        links:'/about'
      }
    })
    .then((res) => {
     const { data , StatusCode } = res.data.app_data;
       if(StatusCode === 6000){
          setdatas(data[0])
       }else{
        setdatas({})
       }
    })
  }, [])
  
  return (
    <>
    <Helmet titleData={datas?.meta_title} descriptionData={datas?.meta_description} />
     <Header/>
     <Section>
        <BreadCrumb/>
        <BriefInfo/>
        <Partners/>
        <MoreInfo/>
     </Section>
     <Footer/>
    </>
  )
}

export default About;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap:130px;

    @media(max-width:1200px){
      gap:64px;
    }
`;