import React from "react";
import styled from "styled-components";

import img1 from "../../../assets/images/about/partners/img-1.png";
import img2 from "../../../assets/images/about/partners/img-2.png";
import img3 from "../../../assets/images/about/partners/img-3.png";

function Partners() {
  return (
    <Section>
      <Wrapper className="wrapper">
        <Title>
          <Classic>OUR</Classic> PARTNERS
        </Title>
        <CardList>
          <Card>
            <Img>
              <img src={img1} alt="" />
            </Img>
            <Container>
              <Info>
                <Name>Petty Mathew</Name>
                <Designation>Human Resources</Designation>
              </Info>
            </Container>
          </Card>
          <Card>
            <Img>
              <img src={img2} alt="" />
            </Img>
            <Container>
              <Info>
                <Name>Abel Thomas Mathew</Name>
                <Designation>Legal Associate</Designation>
              </Info>
            </Container>
          </Card>
          <Card>
            <Img>
              <img src={img3} alt="" />
            </Img>
            <Container>
              <Info>
                <Name>Paul Thomas Mathew</Name>
                <Designation>Civil Engineer</Designation>
              </Info>
            </Container>
          </Card>
        </CardList>
      </Wrapper>
    </Section>
  );
}

export default Partners;

const Section = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media(max-width:575px){
    gap:40px;
  }
`;

const Title = styled.div`
  display: flex;
  color: #eb6502;
  font-family: "gellix-regular";
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 3.75px;
  gap: 15px;

  @media (max-width: 875px) {
    font-size: 50px;
  }
  @media (max-width: 580px) {
    text-align: center;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    font-size: var(--heading-fz);
    gap: 5px;
  }

  /* @media(max-width: 450px){
    font-size: 45px;
    gap:0;
  } */
`;
const Classic = styled.div`
  color: #606060;

  @media (max-width: 580px) {
    font-size: var(--heading-fz);
  }

`;

const Container = styled.div`
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -50px;
`;


const CardList = styled.div`
  display: flex;
  gap: 75px;
  justify-content: center;
  align-items: center;

  @media(max-width:1200px){
    flex-wrap: wrap;
    gap:100px;
  }
  @media(max-width:900px){
    gap:75px;
  }
  @media(max-width:768px){
    gap:100px;
  }
`;

const Card = styled.div`
  /* background-image: ${(props) => `url(${props.bg})`};
    background-repeat: no-repeat;
    background-size: contain; */
  width: 100%;
  position: relative;
  @media(max-width:1200px){
    width: 43%;
  }
  @media(max-width:768px){
    width:75%;
  }

  @media(max-width:525px){
    width:100%;
  }
`;

const Info = styled.div`
  padding: 15px 30px;
  background-color: #eb6502;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
`;

const Name = styled.p`
  color: #fff;
  text-align: center;
  font-family: "gellix-regular";
  font-size: 22.14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.107px;
  margin: 0;
  padding: 0;

  @media(max-width:1350px){
    font-size: 17.5px;
  }
`;

const Designation = styled.p`
  color: #fff;
  text-align: center;
  font-family: "gellix-regular";
  font-size: 15.182px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
`;

const Img = styled.div`
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`;
