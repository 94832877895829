import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import soldOut from '../../../assets/images/apartments/icons/sold-out.svg';

export default function VillasList({ villasList }) {
  return (
    <MainContainer>
      <Content>
        <Wrapper className="wrapper">
          <TopBar>
            {/* {tabs.map((item, index) => (
              <Item
                key={index}
                onClick={() => setActive(item.title)}
                className={isActive == item.title && "active"}
              >
                {item.title}
              </Item>
            ))} */}
          </TopBar>
          <Cards>
            {villasList?.map((item, index) => (
              <Card key={index} to={`/villas-for-sale-in-kochi/${item.slug}`}>
                <ImageContainer isSold={item?.soldout ? true : false}>
                  <img src={item.thumbnail} alt={item.name} />
                </ImageContainer>
                <Title>{item.name}</Title>
                <Address>{item.location}</Address>
              </Card>
            ))}
          </Cards>
          <Description>
            <Cover>
              <TitleSection>
                Exquisite Living with Classic Homes' Luxury Villas in Kochi
              </TitleSection>
              <Para>
                With the improved living standards, demand for premium villas has increased significantly over the past few years. Classic Homes hence presents you magnificent villas on the outskirts of Kochi, surrounded by all city attractions build by rigorous planning, high-quality materials and superb craftsmanship. Conveniently located at Kakkanad, near Infopark at the best-suited area to reside, well connected by road to multi-speciality hospitals, famous educational institutions and commercial malls. So, if you are looking for premium ready to occupy villas for sale in Kochi, Classic Homes thereby becomes an excellent choice.
              </Para>
            </Cover>
            <Cover>
              <TitleSection>Unique Luxury and Comfort at Premium Villas in Kochi</TitleSection>
              <Para>By the concepts of luxury villas, possibilities of better lifestyle facilities have also been radically reimagined. The same adds value to its primary offering, which is privacy and exclusivity. At Classic Homes, we do understand that the best is what you always search for. Because of which our 3 & 4 BHK luxury villas in Kakkanad, Kochi, come with a variety of amenities that add value to your lifestyle. Fitness Centre, Swimming Pool, Elder's Park, Children's Play Area etc are some of the crucial features designed to enhance your living experience.
              </Para>
            </Cover>
            <Para>As you all prefer a modern lifestyle that caters to your comfort and requirements. It's high time you own a villa well-crafted with ample space and high-quality infrastructure. To wake up to the sight of lush green and spend your evenings amidst the chirping birds there's nothing better than Classic Homes.
            </Para>
            <Para>Regardless of how appealing these perks may appear, the personal satisfaction and contentment that comes with owning a villa is ultimately what matters most, and that is inexpressible. While the aforementioned advantages are objective facts and observations, each of us may have our own subjective advantages and motivations behind purchasing a villa. There are a number of high-quality Villa projects in Kakkanad, also many real estate firms come forward to aid you in purchasing unique properties as well. But no one else will provide the guidance and assistance that Classic does.</Para>
            {/* <Para>
              With the improved living standards, demand for premium villas has
              increased significantly over the past few years. Classic Homes
              hence presents you magnificent villas on the outskirts of Kochi,
              surrounded by all city attractions build by rigorous planning,
              high-quality materials, and superb craftsmanship.Conveniently
              located near Infopark at the best-suited area to reside, well
              connected by road to multi-speciality hospitals, famous
              educational institutions, and commercial malls. So if you are
              looking for premium ready to occupy villas for sale in Kochi,
              Classic Homes thereby becomes an excellent choice.
            </Para>
            <Para>
              By the concepts of luxury villas, possibilities of better
              lifestyle facilities have also been radically reimagined. The same
              adds value to its primary offering, which is privacy and
              exclusivity. At Classic Homes, we do understand that the best is
              what you always search for. Because of which our 3 & 4 BHK luxury
              villas in Kochi, comes with a variety of amenities that add value
              to your lifestyle. Fitness Centre, Swimming Pool, Elder's Park,
              Children's Play Area etc are some of the crucial features designed
              to enhance your living experience.
            </Para>
            <Para>
              As you all prefer a modern lifestyle that caters to your comfort
              and requirements. It's high time you own a villa well crafted with
              ample space and high-quality infrastructure. To wake up to the
              sight of lush green and spend your evenings amidst the chirping
              birds there's nothing better than Classic Homes.
            </Para>
            <Para>
              Regardless of how appealing these perks may appear, the personal
              satisfaction and contentment that comes with owning a villa is
              ultimately what matters most, and that is inexpressible. While the
              aforementioned advantages are objective facts and observations,
              each of us may have our own subjective advantages and motivations
              behind purchasing a villa. There are a number of high-quality
              Villa projects in Kochi, also many real estate firms come forward
              to aid you in purchasing unique properties as well. But no one
              else will provide the guidance and assistance that Classic does.
            </Para> */}
          </Description>
        </Wrapper>
      </Content>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  padding: 0 0;
  background-color: #f8f8f8;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const TopBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-bottom: 30px;
  @media all and (max-width: 480px) {
    gap: 20px;
  }
`;
const Item = styled.h3`
  color: #777777;
  cursor: pointer;
  padding-bottom: 10px;
  font-family: "gellix-medium";
  @media all and (max-width: 480px) {
    font-size: 14px;
  }

  &.active {
    color: #eb6502;
    border-bottom: 1px solid #eb6502;
  }
`;
const Cards = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
`;
const Card = styled(Link)`
  width: 40%;
  cursor: pointer;
  @media all and (max-width: 768px) {
    width: 80%;
  }
  @media all and (max-width: 480px) {
    width: 90%;
  }
`;
const ImageContainer = styled.div`
  position: relative;

  &::after {
    ${(props) => props.isSold ? 'display: flex;' : 'display:none;'}
    content: '';
    background-image:url(${soldOut});
    position: absolute;
    background-size: contain;
    height: 65px;
    width: 65px;
    top: 15px;
    right: 15px;
  }
`;
const Description = styled.div`
  margin-top: 80px;
  margin-bottom: 60px;
`;
const Para = styled.p`
  margin-bottom: 20px;
  line-height: 28px;
  text-align: justify;
`;
const Title = styled.h2`
  color: #606060;
  margin: 20px 0;
  letter-spacing: 2px;
  text-align: center;
  font-family: "gellix-bold";
  span {
    color: #eb6502;
  }
  @media all and (max-width: 480px) {
    font-size: 18px;
  }
`;
const Address = styled.div`
  color: #2b2a29;
  text-align: center;
  font-family: "gellix-medium";
  letter-spacing: 1px;
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;
const Cover = styled.div`
  margin-bottom: 30px;
`;
const TitleSection = styled.h3`
  color: #000000b2;
  font-family: "gellix-bold";
  letter-spacing: 2px;
  margin-bottom: 20px;
`;