import blog1 from "../images/blogs/blog-1.png";
import blog2 from "../images/blogs/blog-2.png";
import blog3 from "../images/blogs/blog-3.png";

import rBlog from "../../assets/images/blogs/recentBlog/blog1.png";
import rBlog1 from "../../assets/images/blogs/recentBlog/blog2.png";
import rBlog2 from "../../assets/images/blogs/recentBlog/blog3.png";

import rentals from "../../assets/images/services/rental.svg";
import marketing from "../../assets/images/services/marketing.svg";
import contract_works from "../../assets/images/services/contract_works.svg";
import home_loans from "../../assets/images/services/home_loan.svg";
import home_care_services from "../../assets/images/services/homecare_services.svg";
import interior from "../../assets/images/services/interior.svg";

import img from "../../assets/images/events/img-lg-1.png";
import img_sm from "../../assets/images/events/img-sm-1.png";
import img_1 from "../../assets/images/events/img-lg-2.png";
import img_sm_1 from "../../assets/images/events/img-sm-2.png";
import img_2 from "../../assets/images/events/img-lg-3.png";
import img_sm_2 from "../../assets/images/events/img-sm-3.png";
import img_3 from "../../assets/images/events/img-lg-4.png";
import img_sm_3 from "../../assets/images/events/img-sm-4.png";
import img_4 from "../../assets/images/events/img-lg-5.png";
import img_sm_4 from "../../assets/images/events/img-sm-5.png";

import video from "../../assets/images/events/video/video.mp4";

import skelton_img1 from '../../assets/images/events/IMG.png';
import skelton_img2 from '../../assets/images/events/IMG1.png';

export const blogsData = [
  {
    tags: ["DESIGN", "INTERIOR", "URBAN"],
    name: "Elevate Your Lifestyle: Premium Flats in Kochi Worth Exploring",
    brief: `The metropolitan Kochi successfully combines history and modernity.
                It is an experience rather than merely a place to go. The options for luxury living change along with this thriving seaside metropolis.
                Premium flats in Kochi are raising the bar for upmarket living by providing tenants with an unmatched lifestyle that precisely captures the character....`,
    date: "Apr 2, 2023",
    img: blog1,
  },
  {
    tags: ["DESIGN", "INTERIOR", "URBAN"],
    name: "Elevate Your Lifestyle: Premium Flats in Kochi Worth Exploring",
    brief: `The metropolitan Kochi successfully combines history and modernity.
                It is an experience rather than merely a place to go. The options for luxury living change along with this thriving seaside metropolis.
                Premium flats in Kochi are raising the bar for upmarket living by providing tenants with an unmatched lifestyle that precisely captures the character....`,
    date: "Apr 2, 2023",
    img: blog2,
  },
  {
    tags: ["DESIGN", "INTERIOR", "URBAN"],
    name: "Elevate Your Lifestyle: Premium Flats in Kochi Worth Exploring",
    brief: `The metropolitan Kochi successfully combines history and modernity.
                It is an experience rather than merely a place to go. The options for luxury living change along with this thriving seaside metropolis.
                Premium flats in Kochi are raising the bar for upmarket living by providing tenants with an unmatched lifestyle that precisely captures the character....`,
    date: "Apr 2, 2023",
    img: blog3,
  },
];

export const recentBlogsData = [
  {
    img: rBlog,
    desc: "Experience the High Life: Luxury Apartments in Kochi",
  },
  {
    img: rBlog1,
    desc: "Advantages of a Gated Community for Your Children",
  },
  {
    img: rBlog2,
    desc: "Why are Apartments in Kochi NRI’s Best Pick?",
  },
];

export const serviceData = [
  {
    name: "RENTALS",
    desc: `
      A home in Kochi is always an investment as well as an asset. For our outstation clients, Classic Homes also provides rental services.
      Makes arrangements for tenants, execute rental agreements and co-ordinate the prompt collection of rent.
      Your investment is guaranteed to bring you good returns, even while you are away. Rental options include fully furnished, semi-furnished and non-furnished Apartments and Villas.
      Classic Homes also caters to the resale of property guaranteeing good appreciation.Apartments and Villas. He also caters to the resale of property guaranteeing good appreciations.  Nunc tincidunt, arcu sed tincidunt tris- tique, neque risus tincidunt est, eget pellentesque libero sapien nec magna.
      neque risus tincidunt est, eget pellentesque libero sapien nec magna.neque risus tincidunt est, eget pellentesque libero sapien nec magna.neque risus 
      `,
    img: rentals,
    cl: "grey",
  },
  {
    name: "MARKETING & SALES",
    desc: `
        Er. K.T Mathew is a renowned advisor in the field of engineering with 30 years of experience. His real estate licence (K-RERA/AG/0029/2020) and competent leadership in marketing and sales has led Classic Homes and its subsidiary firms to new heights.
        Today, Classic Homes is one of the prominent and most approached builders in Kochi. Such exquisite Villas & Apartments has for sure changed the real estate marketing graph forever. Each work Classic Homes undertakes for sure comes out with a new level of brilliance as well.
        Villas & Apartments has for sure changed the real estate marketing graph forever. Each work he undertakes for sure comes out with a new level of brilliance as well. Sed rhoncus sollicitudin nisi. Nunc tincidunt, arcu sed tincidunt tris- tique, neque risus tincidunt est, eget pellentesque libero sapien nec magna. neque risus tincidunt est, eget pellentesque libero sapien nec., 
        `,
    img: marketing,
  },
  {
    name: "HOME CARE SERVICES",
    desc: `
        Home Care Services caters to all home care solutions like Plumbing | Electrical | Carpentry | Tiling | Gardening | Full house cleaning works. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Praesent ultricies enim at justo convallis consequat. Fusce rhoncus magna ut commodo mattis. Nulla tempor enim sem, ac blandit mauris fringilla volutpat. Duis sed aliquam eros. Suspendisse non nisl a odio rutrum ullamcorper in in mi.
        Integer fringilla, metus quis faucibus ultrices, justo est dapibus leo, sed rhoncus diam quam vel sem. Sed rhoncus sollicitudin nisi. Nunc tincidunt, arcu sed tincidunt tris- tique, neque risus tincidunt est, eget pellentesque libero sapien nec magna.
        neque risus tincidunt est, eget pellentesque libero sapien nec magna.neque risus tincidunt est, eget pellentesque libero sapien nec magna.neque risus tincidunt est, eget pellentesque libero sapien nec magna.neque risus tincidunt es
        `,
    img: home_care_services,
  },
  {
    name: "HOME/OFFICE INTERIORS",
    desc: `
        The best to consult for creating seamless interior design experiences, for homes, hospitality, retail, and other commercial spaces across South India.
        Careful thought is all it takes to ensure your home/office space is equally stylish and productive.
        Classic Insides located in Kinfra export promotion industrial park at Kakkanad creates intelligent, beautifully designed spaces for homes and workspaces.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Praesent ultricies enim at justo convallis consequat. Fusce rhoncus magna ut commodo mattis. Nulla tempor enim sem, ac blandit mauris fringilla volutpat. Duis sed aliquam eros. Suspendisse non nisl a odio rutrum ullamcorper in in mi. Integer fringilla, metus quis faucibus ultrices, justo est dapibus leo, sed rhoncus diam quam vel sem. Sed rhoncus sollicitudin nisi. Nunc tincidunt, arcu sed tincidunt tris- tique, neque risus tincidunt est, 
        `,
    img: interior,
    cl: "grey",
  },
  {
    name: "HOME LOAN",
    desc: `
        Facilitating the financing of your home. Right from, identifying the correct financial institution, to securing all necessary documents, to submitting paperwork on time, services are at your disposal every step of the way.
        Provides all legwork to get your loan sanctioned, at no extra cost! The general procedure for availing a home loan from any financial institution is as follows:Duly filled Application formId proofs of Applicant/s (passport copies, pan card etc…)Original salary certificate/pay slips (last 3 months)Bank statements (last 6 months)Once these documents are received,
        the rest will be coordinated with the respective banks offering to get your home loan sanctioned.
        `,
    img: home_loans,
    cl: "grey",
  },
  {
    name: "CONTRACT WORKS",
    desc: `
        Has a strong history of delivering unique, quality homes to our clients on a contract basis.
        Timely delivery and craftsmanship are known throughout the industry for being the absolute best.
        Have been in the business for years and understands the ins and outs of the latest trends.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultricies enim at justo convallis consequat.
        Fusce rhoncus magna ut commodo mattis. Nulla tempor enim sem, ac blandit mauris fringilla volutpat. 
        Duis sed aliquam eros. Suspendisse non nisl a odio rutrum ullamcorper in in mi. Integer fringilla, metus quis faucibus ultrices, justo est dapibus leo, sed rhoncus diam quam vel sem. Sed rhoncus sollicitudin nisi. Nunc tincidunt, arcu sed tincidunt tris- tique, neque risus tincidunt est, eget pellentesque libero sapien nec magna. neque risus tincidunt est, eget pellentesque libero sapien nec magna.neque risus 
        `,
    img: contract_works,
  },
];

export const commentsData = [
  {
    name: "Anonymous",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultricies enim at justo convallis consequat. Fusce rhoncus magna ut commodo mattis. Nulla tempor enim sem, ac blandit mauris fringilla volutpat.",
    date: "April 4, 2023",
  },
  {
    name: "Anonymous1",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultricies enim at justo convallis consequat. Fusce rhoncus magna ut commodo mattis. Nulla tempor enim sem, ac blandit mauris fringilla volutpat.",
    date: "April 4, 2023",
  },
  {
    name: "Anonymous2",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultricies enim at justo convallis consequat. Fusce rhoncus magna ut commodo mattis. Nulla tempor enim sem, ac blandit mauris fringilla volutpat.",
    date: "April 4, 2023",
  },
  {
    name: "Anonymous3",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultricies enim at justo convallis consequat. Fusce rhoncus magna ut commodo mattis. Nulla tempor enim sem, ac blandit mauris fringilla volutpat.",
    date: "April 4, 2023",
  },
  {
    name: "Anonymous4",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultricies enim at justo convallis consequat. Fusce rhoncus magna ut commodo mattis. Nulla tempor enim sem, ac blandit mauris fringilla volutpat.",
    date: "April 4, 2023",
  },
  {
    name: "Anonymous5",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultricies enim at justo convallis consequat. Fusce rhoncus magna ut commodo mattis. Nulla tempor enim sem, ac blandit mauris fringilla volutpat.",
    date: "April 4, 2023",
  },
];

export const eventSliderData = [
  { id: 1, img: img, sm: img_sm, type: "img" },
  { id: 2, img: img_1, sm: img_sm_1, type: "img" },
  { id: 3, img: img_2, sm: img_sm_2, type: "img" },
  { id: 4, img: img_3, sm: img_sm_3, type: "img" },
  { id: 5, img: img_4, sm: img_sm_4, type: "img" },
  { id: 6, img: img_1, sm: img_sm_1, video: video, type: "vid" },
  { id: 7, img: img_3, sm: img_sm_3, type: "img" },
  { id: 8, img: img_4, sm: img_sm_4, type: "img" },
  { id: 9, img: img_1, sm: img_sm_1, video: video, type: "vid" },
];

export const CarlSliderSkeleton = [
  {
    id: 3,
    type: "image",
    image:skelton_img1,
    videos:
      "http://127.0.0.1:8000/media/celebration/video/WhatsApp_Video_2023-12-11_at_4.08.42_PM.mp4",
    year: 2022,
    show:true
  },
  {
    id: 2,
    type: "image",
    image: skelton_img2,
    videos:
      "http://127.0.0.1:8000/media/celebration/video/WhatsApp_Video_2023-12-11_at_4.08.42_PM.mp4",
    year: 2022,
    show:true
  },
];

export const CarlSliderSkeleton1 = [
    {
      id: 2,
      type: "image",
      image:{skelton_img1},
      videos:
        "http://127.0.0.1:8000/media/celebration/video/WhatsApp_Video_2023-12-11_at_4.08.42_PM.mp4",
      year: 2022,
      show:true
    },
  ];

