import React, { useEffect, useState } from "react";
import Slider from "react-slick";

//imports
import styled from "styled-components";
import flat3 from "../../../assets/images/flat3.png";
import img4 from "../../../assets/images/Asset 38.png";
import Testimonials from "./Testimonials";
import { accountsConfig } from "../../../axiosConfig";
import { Navigate, useNavigate } from "react-router-dom";
import UpdatedTestimonial from "./UpdatedTestimonial";

function Projects() {
  const [getProjectList, setProjectList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    accountsConfig.get("projects/list-project/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode === 6000) {
        setProjectList(data);
      } else {
        setProjectList([]);
      }
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <SubTitle>PROJECTS</SubTitle>
          <Line></Line>
          <TitleCard>FEATURED PROJECTS</TitleCard>
          <ProjectsCard>
            {getProjectList.map(
              (item, index) =>
                item.featured && (
                  <Card
                    key={index}
                    onClick={() =>
                      navigate(
                        item?.project_type === "villas"
                          ? `/villas-for-sale-in-kochi/${item?.slug}`
                          : `/apartments-for-sale-in-kochi/${item?.slug}`
                      )
                    }
                  >
                    <Image>
                      <img src={item.featured_image} alt="flat-image" />
                    </Image>
                    <Title>{item.name}</Title>
                    {/* <Location>
                      {item.rera_number
                        ? "(" + item.rera_number + ")"
                        : item.rera_number == null && item.building_type == ""
                        ? null
                        : "(" + item.building_type + ")"}
                    </Location> */}
                    <Small>{item.location}</Small>
                  </Card>
                )
            )}
          </ProjectsCard>
          <ProjectsCardMob className="mobile-slider">
            <Slider {...settings}>
              {getProjectList.map(
                (item, index) =>
                  item.featured && (
                    <Card
                      key={index}
                      onClick={() =>
                        navigate(
                          item?.project_type === "villas"
                            ? `/villas-for-sale-in-kochi/${item?.slug}`
                            : `/apartments-for-sale-in-kochi/${item?.slug}`
                        )
                      }
                    >
                      <Image>
                        <img src={item.featured_image} alt="flat-image" />
                      </Image>
                      <Title>{item.name}</Title>
                      {/* <Location>
                        {item.rera_number
                          ? "(" + item.rera_number + ")"
                          : item.rera_number == null && item.building_type == ""
                          ? "(RERA not required)"
                          : "(" + item.building_type + ")"}
                      </Location> */}
                      <Small>{item.location}</Small>
                    </Card>
                  )
              )}
            </Slider>
          </ProjectsCardMob>
        </Content>
        <UpdatedTestimonial />
      </Wrapper>

      <CoverBar></CoverBar>
    </Container>
  );
}

export default Projects;

const Container = styled.div`
  padding: 60px 0 0;
  background-color: #f4f5f5;
  position: relative;
  z-index: 10;
`;
const CoverStory = styled.div`
  padding: 60px 0;
  width: 70%;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 80%;
  }

  @media (max-width: 425px) {
    width: 90%;
  }
`;
const Wrapper = styled.div`
  @media (max-width:600px) {
    width: 100% !important;
  }
`;
const CoverBar = styled.div`
  height: 350px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  z-index: -1;
  width: 100%;
`;
const CoverTitle = styled.div``;
const Content = styled.div`
  @media (max-width: 600px) {
    width: 80%;
    margin: 0 auto;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`;
const SubTitle = styled.h3`
  font-family: "gellix-light";
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 30px;
`;
const Line = styled.div`
  background-color: var(--orange);
  width: 60px;
  height: 1px;
  margin: ${({ type }) => (type == "str" ? "" : "0 auto")};
`;
const TitleCard = styled.h2`
  font-family: "gellix-medium";
  color: var(--orange);
  text-align: center;
  margin: 20px 0;
  font-size: 32px;

  @media (max-width: 375px) {
    font-size: 28px;
  }
`;
const ProjectsCard = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;
const ProjectsCardMob = styled.div`
  display: none;
  gap: 10px;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;

  .slick-slider {
    width: 100%;
  }

  @media (max-width: 768px) {
    display: flex;
  }

 .slick-dots{
  bottom: -33px !important;
 }
`;
const Card = styled.div`
  width: 33.33%;
  cursor: pointer;
`;
const CardCover = styled.div`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`;
const Title = styled.h2`
  font-family: "gellix-medium";
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 1300px) {
    font-size: 21px;
  }
`;
const Image = styled.div``;
const Small = styled.h5`
  text-align: center;
  margin-top: 10px;
`;
const Location = styled.h3`
  text-align: center;
`;
const ClassicStory = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
  z-index: 10;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ImageSection = styled.div`
  width: 50%;
  @media (max-width: 1300px) {
    width: 100%;
  }

  img {
    height: 100% !important;
  }
`;
const StoryDetail = styled.div`
  background-color: #2a2a28;
  color: #fff;
  width: 50%;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const SubtitleStry = styled.div`
  font-family: "gellix-regular";
  letter-spacing: 2px;
  margin-bottom: 20px;
`;
const StrTitle = styled.h1`
  margin-top: 35px;
  font-size: 45px;
  letter-spacing: 1px;
  margin-bottom: 20px;

  @media (max-width: 1600px) {
    font-size: 2rem;
  }
`;
const PAraOne = styled.p`
  line-height: 26px;
  text-align: justify;

  @media (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;
const ParaTwo = styled.p`
  text-align: justify;
  line-height: 26px;
  margin-bottom: 20px;

  @media (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;
const Button = styled.a`
  border: 1px solid var(--orange);
  width: fit-content;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  cursor: pointer;
`;
