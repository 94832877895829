import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

export default function SlideCard({
  fetchedImage,
  fetchedId,
  mediaType,
  item,
  index,
}) {
  const playerStyle = {
    width: "100%",
    height: "100%",
  };
  return (
    <SlideCards>
      {fetchedId == item.id && mediaType == "image" ? (
        <img
          src={
            fetchedId == item.id && fetchedImage
              ? fetchedImage
              : item.event_assets[0] && item.event_assets[0].image
          }
          alt="image"
        />
      ) : fetchedId == item.id ? (
        <ReactPlayer
          className="player"
          url={fetchedImage}
          controls
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
        />
      ) : (
        <img
          src={
            fetchedId == item.id && fetchedImage
              ? fetchedImage
              : item.event_assets[0] && item.event_assets[0].image
          }
          alt="image"
        />
      )}
    </SlideCards>
  );
}

const SlideCards = styled.div`
  width: 50%;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
