import React from "react";
import styled from "styled-components";

//imports
import bg from "../../../assets/images/Gardens.png";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();

  return (
    <Container>
      {/* <Overlay></Overlay> */}
      <Wrapper className="wrapper">
        <Content>
          <TextContainer>
            <TitleSection>
              <SubTitle>BEST BUILDERS IN KOCHI</SubTitle>
              <Line></Line>
              <Title>
                CLASSIC <br /> HOMES
              </Title> 
            </TitleSection>
            <Details>
              <Paragraph>
                Every home should be embraced in the dual arms of harmony and serenity. That duality is what makes our homes Classic Homes in Kakkanad, sought after place for reside within Kochi. Our legacy of unwavering dedication has woven dreams into reality as we spend countless hours perfecting the art of crafting spaces that are bold and timeless. As pioneers in home building, we are the flare of reliability and trust. We are offering not just homes but sanctuaries where aspirations thrive.
              </Paragraph>
              <Button onClick={() => navigate("/about")}>Read More</Button>
            </Details>
          </TextContainer>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default About;
const Button = styled.div`
  border: 1px solid var(--orange);
  width: fit-content;
  height: 40px;
  color: #616160;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  cursor: pointer;
`;
const Overlay = styled.div`
  background-color: #fff;
  z-index: -5;
  height: 100%;
  width: 100%;
`;
const Container = styled.div`
  background: url(${bg});
  height: 900px;
  background-color: #fff;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 75px 0px 0px;
  box-sizing: border-box;
  margin-top: 0px;
  background-position: bottom;
  @media (min-width: 2300px) {
    height: 1400px;
  }

  @media (max-width: 2299px) {
    height: 1250px;
  }

  @media (max-width: 1999px) {
    height: 1100px;
  }
  @media (max-width: 1750px) {
    height: 1050px;
  }
  @media (max-width: 1600px) {
    height: 950px;
  }
  @media (max-width: 1480px) {
    height: 900px;
  }

  @media (max-width: 900px) {
    height: 865px;
  }

  @media (max-width: 580px) {
    height: 815px;
  }
`;
const Wrapper = styled.div`
  @media (max-width: 425px) {
    width: 88%;
  }
`;
const Content = styled.div``;
const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 580px) {
    gap: 4px;
  }
`;
const TitleSection = styled.div``;
const SubTitle = styled.h1`
  color: #616160;
  font-size: 16px;
  font-family: "gellix-light";
  letter-spacing: 1px;
  margin-bottom: 30px;

  @media (max-width: 580px) {
    text-align: center;
    margin-bottom: 15px;
  }
`;
const Line = styled.div`
  width: 60px;
  height: 1px;
  background-color: #616160;

  @media (max-width: 580px) {
    margin: 0 auto;
    width: 90px;
    height: 1px;
    background-color: var(--orange);
  }
`;
const Title = styled.h2`
  color: #616160;
  font-family: "gellix-bold";
  letter-spacing: 1px;
  font-size: 60px;
  margin-top: 20px;

  @media (max-width: 900px) {
    br {
      display: none;
    }
  }

  @media (max-width: 580px) {
    font-size: 55px;
  }

  @media (max-width: 525px) {
    font-size: 50px;
    text-align: center;
  }
  @media (max-width: 575px) {
    font-size: 40px;
  }

  @media (max-width: 375px) {
    font-size: 35px;
  }
  @media (max-width: 325px) {
    font-size: 33px;
  }
`;
const Details = styled.div`
  width: 65%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Paragraph = styled.p`
  color: #616160;
  line-height: 28px;
  font-family:'gellix-regular' ;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */

  @media (max-width: 580px) {
    text-align: justify;
  }
`;
