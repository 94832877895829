export const oldUrlAndNewurl = [
    {
        old:'/apartments-in-kochi/',
        new:'/apartments-for-sale-in-kochi'
    },
    {
        old:'/blog/?p=1715',
        new:'/blogs'
    },
    {
        old:'/video-testimonials/',
        new:'/key-handover'
    },
    {
        old:'/residential-property-in-kochi/tata-tritvam',
        new:'/apartments-for-sale-in-kochi'
    },{
        old:'/noreferrer',
        new:'/contact'
    },{
        old:'/news-events',
        new:'/events'
    },
    {
        old:'/classic-utsav-2018',
        new:'/events'
    },{
        old:'/flats-in-kochi/',
        new:'/blog/kochi-flats-where-convenience-meets-comfort'
    },{
        old:'/classic-sunny-sands/',
        new:'/villas-for-sale-in-kochi'
    },{
        "old": "/blog/checklist-to-make-sure-your-property-is-legally-transparent/feed/",
        "new": "/blog/checklist-to-make-sure-your-property-is-legally-transparent"
      },
      {
        "old": "/classic-gardens",
        "new": "/villas-for-sale-in-kochi/classic-gardens"
      },
      {
        "old": "/villas",
        "new": "/villas-for-sale-in-kochi"
      },
      {
        "old": "/classic-summer-sands",
        "new": "/apartments-for-sale-in-kochi/classic-summer-sands"
      },
      {
        "old": "/classic-tropicana-hills",
        "new": "/villas-for-sale-in-kochi/classic-tropicana-hills"
      },
      {
        "old": "/apartments",
        "new": "/apartments-for-sale-in-kochi"
      },
      {
        "old": "/classic-capitol",
        "new": "/apartments-for-sale-in-kochi/classic-capitol"
      },
      {
        "old": "/villas/classic-gardens",
        "new": "/villas-for-sale-in-kochi/classic-gardens"
      },
      {
        "old": "/villas/classic-red-carpet-homes",
        "new": "/villas-for-sale-in-kochi/classic-red-carpet-homes"
      },
      {
        "old": "/blog/?p=1736",
        "new": "/blogs"
      },
      {
        "old": "/site/about",
        "new": "/about"
      },
      {
        "old": "/blog/attitude/feed/",
        "new": "/blogs"
      },
      {
        "old": "/blog/why-are-apartments-in-kochi-nris-best-pick/services",
        "new": "/blog/why-are-apartments-in-kochi-nris-best-pick"
      },
      {
        "old": "/blog/5-common-home-buying-myths-busted/services",
        "new": "/blog/5-common-home-buying-myths-busted/"
      },
      {
        "old": "/blog/discover-serene-living-apartments-in-kakkanad-for-a-perfect-blend-of-comfort-and-convenience/services",
        "new": "/blog/discover-serene-living-apartments-in-kakkanad-for-a-perfect-blend-of-comfort-and-convenience/"
      },
      {
        "old": "/blog/get-rid-off-damage-of-walls-from-dampness/services",
        "new": "/blog/get-rid-off-damage-of-walls-from-dampness/"
      },
      {
        "old": "/classic-avenir-suites",
        "new": "/apartments-for-sale-in-kochi/classic-avenir-suites"
      },
      {
        "old": "/blog/tag/apartments-in-kochi/page/3/",
        "new": "/apartments-for-sale-in-kochi"
      },
      {
        "old": "/blog/author/adminclassichomes/page/5/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/classic-capitol",
        "new": "/apartments-for-sale-in-kochi/classic-capitol"
      },
      {
        "old": "/blog/luxury-living-exploring-the-best-villas-in-kakkanad-for-a-lavish-lifestyle/services",
        "new": "/blog/luxury-living-exploring-the-best-villas-in-kakkanad-for-a-lavish-lifestyle/"
      },
      {
        "old": "/blog/how-to-make-the-right-choice-between-a-villa-and-an-apartment/feed/",
        "new": "/blog/how-to-make-the-right-choice-between-a-villa-and-an-apartment/"
      },
      {
        "old": "/apartments/classic-vintage",
        "new": "/apartments-for-sale-in-kochi/classic-vintage"
      },
      {
        "old": "/about-us",
        "new": "/about"
      },
      {
        "old": "/blog/tag/top-builders/",
        "new": "/blogs"
      },
      {
        "old": "/blog/category/premium-flats-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/summer/services",
        "new": "/apartments-for-sale-in-kochi"
      },
      {
        "old": "/about/services",
        "new": "/about"
      },
      {
        "old": "/blog/discover-your-dream-home-ready-to-occupy-flats-in-kochi-find-your-perfect-space-today/services",
        "new": "/blog/discover-your-dream-home-ready-to-occupy-flats-in-kochi-find-your-perfect-space-today/"
      },
      {
        "old": "/villas/classic-tropicana-hills",
        "new": "/villas-for-sale-in-kochi/classic-tropicana-hills"
      },
      {
        "old": "/blog/why-do-buyers-prefer-flats-that-are-ready-to-move-in/services",
        "new": "/blog/why-do-buyers-prefer-flats-that-are-ready-to-move-in/"
      },
      {
        "old": "/blog/why-2021-is-the-right-time-to-invest-in-property/services",
        "new": "/blog/why-2021-is-the-right-time-to-invest-in-property/"
      },
      {
        "old": "/apartments/undefined/services",
        "new": "/services"
      },
      {
        "old": "/blog/tag/ready-to-occupy-flats-in-kakkanad/feed/",
        "new": "/blogs"
      },
      {
        "old": "/blog/3-main-sustainable-lifestyle-practices-for-apartment-residents-ecologically-sustainable-living-inspire-their-whole-neighborhood-to-embrace-similar-practices/services",
        "new": "/blog/3-main-sustainable-lifestyle-practices-for-apartment-residents-ecologically-sustainable-living-inspire-their-whole-neighborhood-to-embrace-similar-practices/"
      },
      {
        "old": "/blog/11-mistakes-first-time-home-buyers-should-avoid/services",
        "new": "/blog/11-mistakes-first-time-home-buyers-should-avoid/"
      },
      {
        "old": "/villas/classic-tropicana-hills/services",
        "new": "/villas-for-sale-in-kochi/classic-tropicana-hills"
      },
      {
        "old": "/blog/advantages-of-a-gated-community-for-your-children/services",
        "new": "/blog/advantages-of-a-gated-community-for-your-children/"
      },
      {
        "old": "/villas/villas",
        "new": "/villas-for-sale-in-kochi"
      },
      {
        "old": "/apartments/vintage/services",
        "new": "/apartments-for-sale-in-kochi/classic-vintage"
      },
      {
        "old": "/blog/how-to-welcome-monsoon-in-kerala/services",
        "new": "/blog/how-to-welcome-monsoon-in-kerala/"
      },
      {
        "old": "/blog/living-in-kochi-things-you-need-to-know/services",
        "new": "/blog/living-in-kochi-things-you-need-to-know/"
      },
      {
        "old": "/site/video_testiomonial",
        "new": "/about"
      },
      {
        "old": "/villas-for-sale-in-kochi/services",
        "new": "/villas-for-sale-in-kochi/services"
      },
      {
        "old": "/apartments/classic-capitol/services",
        "new": "/apartments-for-sale-in-kochi/classic-capitol"
      },
      {
        "old": "/classic-villas",
        "new": "/villas-for-sale-in-kochi/classic-villas"
      },
      {
        "old": "/blog/tips-to-make-your-apartments-clean/services",
        "new": "/blog/tips-to-make-your-apartments-clean/"
      },
      {
        "old": "/blog/the-ultimate-guide-to-finding-the-perfect-flats-for-sale-in-kochi/services",
        "new": "/blog/the-ultimate-guide-to-finding-the-perfect-flats-for-sale-in-kochi/"
      },
      {
        "old": "/blog/how-to-know-which-builder-to-trust/services",
        "new": "/blog/how-to-know-which-builder-to-trust/"
      },
      {
        "old": "/blogs/services",
        "new": "/blogs"
      },
      {
        "old": "/blog/attitude/services",
        "new": "/blogs"
      },
      {
        "old": "/blog/your-search-ends-here-discover-your-dream-flat-in-kakkanads-ready-to-move-properties/services",
        "new": "/blog/your-search-ends-here-discover-your-dream-flat-in-kakkanads-ready-to-move-properties/"
      },
      {
        "old": "/blog/kochi-is-the-best-trending-travel-destination-in-the-world-tripadvisor/services",
        "new": "/blog/kochi-is-the-best-trending-travel-destination-in-the-world-tripadvisor/"
      },
      {
        "old" : "/blog/kochi-is-the-best-trending-travel-destination-in-the-world-tripadvisor/feed/",
        "new" : "/blog/kochi-is-the-best-trending-travel-destination-in-the-world-tripadvisor/"
      },
      {
        "old": "/apartments/classic-summer-sands/services",
        "new": "/apartments-for-sale-in-kochi/classic-summer-sands"
      },
      {
        "old": "/villas-for-sale-in-kochi/classic-red-carpet-homes/services",
        "new": "/villas-for-sale-in-kochi/classic-red-carpet-homes/"
      },
      {
        "old": "/blog/page/6/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/classic-avenir-suites/services",
        "new": "/apartments/classic-avenir-suites/"
      },
      {
        "old": "/blog/difference-between-an-apartment-and-a-flat/services",
        "new": "/blog/difference-between-an-apartment-and-a-flat/"
      },
      {
        "old": "/blog/tag/premium-flats-in-kochi/page/3/",
        "new": "/blogs"
      },
      {
        "old": "/blog/kochi-flats-where-convenience-meets-comfort/feed/",
        "new": "/blog/kochi-flats-where-convenience-meets-comfort/"
      },
      {
        "old": "/blog/colors-used-in-apartments/services",
        "new": "/blog/colors-used-in-apartments/"
      },
      {
        "old": "/blog/tag/premium-flats-in-kochi/page/4/",
        "new": "/blogs"
      },
      {
        "old": "/blog/author/adminclassichomes/page/11/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/luxury-apartments-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/discover-the-best-flats-in-kochi-for-a-comfortable-and-luxurious-living-experience/services",
        "new": "/blog/discover-the-best-flats-in-kochi-for-a-comfortable-and-luxurious-living-experience/"
      },
      {
        "old": "/contact/services",
        "new": "/contact"
      },
      {
        "old": "/apartments/undefined",
        "new": "/apartments-for-sale-in-kochi"
      },
      {
        "old": "/blog/category/apartments-in-thrissur/",
        "new": "/blogs"
      },
      {
        "old": "/blog/elevate-your-lifestyle-premium-flats-in-kochi-worth-exploring/feed/",
        "new": "/blog/elevate-your-lifestyle-premium-flats-in-kochi-worth-exploring/"
      },
      {
        "old": "/blog/kochi-a-haven-of-opportunities-and-quality-living/services",
        "new": "/blog/kochi-a-haven-of-opportunities-and-quality-living/"
      },
      {
        "old": "/villas/services",
        "new": "/villas-for-sale-in-kochi"
      },
      {
        "old": "/classic-vintage",
        "new": "/apartments-for-sale-in-kochi/classic-vintage"
      },
      {
        "old": "/blog/?p=1783",
        "new": "/blogs"
      },
      {
        "old": "/villas/classic-villas",
        "new": "/villas-for-sale-in-kochi/classic-villas"
      },
      {
        "old": "/blog/tag/luxury-villas-in-kakkanad/",
        "new": "/blogs"
      },
      {
        "old": "/blessing-of-classic-insides-factory-",
        "new": "/blogs"
      },
      {
        "old": "/apartments/classic-avenir-suites",
        "new": "/apartments-for-sale-in-kochi/classic-avenir-suites"
      },
      {
        "old": "/blog/luxury-redefined-explore-exclusive-villas-for-sale-in-kochi/services",
        "new": "/blog/luxury-redefined-explore-exclusive-villas-for-sale-in-kochi/"
      },
      {
        "old": "/blog/page/3/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/services/services",
        "new": "/apartments-for-sale-in-kochi"
      },
      {
        "old": "/blog/category/uncategorized/page/7/",
        "new": "/blogs"
      },
      {
        "old": "/villas/classic-red-carpet-homes/services",
        "new": "/villas-for-sale-in-kochi/classic-red-carpet-homes"
      },
      {
        "old": "/blog/living-in-style-discovering-the-best-luxury-apartments-in-kochi/services",
        "new": "/blog/living-in-style-discovering-the-best-luxury-apartments-in-kochi/"
      },
      {
        "old": "/blog/tag/premium-villas-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/vintage",
        "new": "/apartments-for-sale-in-kochi/classic-vintage"
      },
      {
        "old": "/blog/importance-of-a-terrace-garden-in-an-apartment/services",
        "new": "/blog/importance-of-a-terrace-garden-in-an-apartment/"
      },
      {
        "old": "/apartments/cap/services",
        "new": "/apartments-for-sale-in-kochi"
      },
      {
        "old": "/blog/apartments-in-kochi-five-things-to-know-before-choosing-the-location/feed/",
        "new": "/blog/apartments-in-kochi-five-things-to-know-before-choosing-the-location/"
      },
      {
        "old": "/blog/author/adminclassichomes/page/10/",
        "new": "/blogs"
      },
      {
        "old": "/classic-capitol/",
        "new": "/apartments-for-sale-in-kochi/classic-capitol"
      },
      {
        "old": "/blog/author/adminclassichomes/page/3/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tips-and-tricks-to-make-relocation-easier/feed/",
        "new": "/blog/tips-and-tricks-to-make-relocation-easier/"
      },
      {
        "old": "/blog/10-places-that-makes-kochi-feel-like-home/services",
        "new": "/blog/10-places-that-makes-kochi-feel-like-home/"
      },
      {
        "old": "/classic-red-carpet-homes",
        "new": "/villas-for-sale-in-kochi/classic-red-carpet-homes"
      },
      {
        "old": "/blog/why-villa-5-advantages-of-living-in-a-villa/services",
        "new": "/blog/why-villa-5-advantages-of-living-in-a-villa/"
      },
      {
        "old": "/villas/gardens/services",
        "new": "/villas-for-sale-in-kochi"
      },
      {
        "old": "/classic-gardens-",
        "new": "/villas-for-sale-in-kochi/classic-gardens"
      },
      {
        "old": "/blog/?elementor_library=header-exp/",
        "new": "/blogs"
      },
      {
        "old": "/blog/category/villas-for-sale-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/elevate-your-lifestyle-premium-flats-in-kochi-worth-exploring/services",
        "new": "/blog/elevate-your-lifestyle-premium-flats-in-kochi-worth-exploring/"
      },
      {
        "old": "/blog/tag/3-bhk-flats-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/cap",
        "new": "/apartments-for-sale-in-kochi/"
      },
      {
        "old": "/blog/page/4/",
        "new": "/blogs"
      },
      {
        "old": "/blog/author/adminclassichomes/page/8/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/avenier",
        "new": "/apartments-for-sale-in-kochi/"
      },
      {
        "old": "/villas/villas/services",
        "new": "/villas-for-sale-in-kochi"
      },
      {
        "old": "/apartments-for-sale-in-kochi/classic-summer-sands/services",
        "new": "/apartments-for-sale-in-kochi/classic-summer-sands/"
      },
      {
        "old": "/blog/tag/flats-in-kochi/page/3/",
        "new": "/blogs"
      },
      {
        "old": "/blog/apartment-living-is-easy-living/services",
        "new": "/blog/apartment-living-is-easy-living/"
      },
      {
        "old": "/villas/classic-gardens/services",
        "new": "/villas-for-sale-in-kochi"
      },
      {
        "old": "/apartments-for-sale-in-kochi/classic-avenir-suites/services",
        "new": "/apartments-for-sale-in-kochi/classic-avenir-suites/"
      },
      {
        "old": "/blog/author/adminclassichomes/",
        "new": "/blogs"
      },
      {
        "old": "/blog/category/villas-in-kakkanad/",
        "new": "/blogs"
      },
      {
        "old": "/villas/red/services",
        "new": "/villas-for-sale-in-kochi"
      },
      {
        "old": "/blog/category/uncategorized/page/6/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/luxury-apartments-in-kochi/page/3/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/classic-vintage/services",
        "new": "/apartments-for-sale-in-kochi/classic-vintage"
      },
      {
        "old": "/blog/tag/flats-in-kakkanad/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/classic-summer-sands",
        "new": "/apartments-for-sale-in-kochi/classic-summer-sands"
      },
      {
        "old": "/blog/tag/flats-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/apartments-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/summer",
        "new": "/apartments-for-sale-in-kochi/classic-summer-sands"
      },
      {
        "old": "/blog/tag/ready-to-move-flats-in-kochi/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/blog/category/flats-in-kakkanad/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/villas-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/flats-in-kochi/feed/",
        "new": "/blogs"
      },
      {
        "old": "/blog/author/adminclassichomes/page/4/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/builders-in-kochi/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/services",
        "new": "/apartments-for-sale-in-kochi"
      },
      {
        "old": "/blog/category/builders-in-thrissur/",
        "new": "/blogs"
      },
      {
        "old": "/apartments/avenier/services",
        "new": "/apartments-for-sale-in-kochi/classic-avenir-suites"
      },
      {
        "old": "/blog/category/builders-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/benefits-of-buying-apartments/services",
        "new": "/blog/benefits-of-buying-apartments/"
      },
      {
        "old": "/blog/experience-the-high-life-luxury-apartments-in-kochi-your-ultimate-guide-to-opulent-living/services",
        "new": "/blog/experience-the-high-life-luxury-apartments-in-kochi-your-ultimate-guide-to-opulent-living/"
      },
      {
        "old": "/blog/page/5/?elementor_library=header-exp/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/flats-for-rent-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/premium-flats-in-kochi/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/blog/category/apartments-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/testimonials",
        "new": "/contact"
      },
      {
        "old": "/blog/experience-the-high-life-luxury-apartments-in-kochi-your-ultimate-guide-to-opulent-living/feed/",
        "new": "/blog/experience-the-high-life-luxury-apartments-in-kochi-your-ultimate-guide-to-opulent-living/"
      },
      {
        "old": "/blog/author/adminclassichomes/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/flats-for-sale-in-kochi/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/blog/pros-and-cons-of-a-furnished-apartment/services",
        "new": "/blog/pros-and-cons-of-a-furnished-apartment/"
      },
      {
        "old": "/blog/tag/flats-in-kochi/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/flats-for-sale-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/benefits-and-drawbacks-of-home-automation-in-apartments/services",
        "new": "/blog/benefits-and-drawbacks-of-home-automation-in-apartments/"
      },
      {
        "old": "/blog/category/uncategorized/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/flats-in-kochi/page/4/",
        "new": "/blogs"
      },
      {
        "old": "/blog/finding-your-dream-home-exploring-the-best-flats-in-kochi-for-comfortable-living/feed/",
        "new": "/blog/finding-your-dream-home-exploring-the-best-flats-in-kochi-for-comfortable-living/"
      },
      {
        "old": "/blog/page/5/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/apartments-for-sale-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/flats-for-sale/",
        "new": "/blogs"
      },
      {
        "old": "/summer-sands-kakkanad-kochi.html",
        "new": "/blog/top-10-mistakes-to-avoid-while-buying-an-apartment/services"
      },
      {
        "old": "/villas-for-sale-in-kochi/classic-villas/services",
        "new": "/villas-for-sale-in-kochi/classic-villas"
      },
      {
        "old": "/classic-villas/",
        "new": "/villas-for-sale-in-kochi/classic-villas"
      },
      {
        "old": "/blog/category/flats-for-sale-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/flats-for-sale-in-kochi/page/3/",
        "new": "/blogs"
      },
      {
        "old": "/blog/category/villas-in-kakkanad/feed/",
        "new": "/blogs"
      },
      {
        "old": "/villas-for-sale-in-kochi/classic-tropicana-hills/services",
        "new": "/villas-for-sale-in-kochi/classic-tropicana-hills/"
      },
      {
        "old": "/blog/top-10-mistakes-to-avoid-while-buying-an-apartment/services",
        "new": "/blog/top-10-mistakes-to-avoid-while-buying-an-apartment/"
      },
      {
        "old": "/blog/tag/luxury-flats-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/page/7/?elementor_library=social-media-widget/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/villas-for-sale-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/category/apartments-in-kakkanad/",
        "new": "/blogs"
      },
      {
        "old": "/blog/how-to-build-an-environmentally-sustainable-home/services",
        "new": "/blog/how-to-build-an-environmentally-sustainable-home/"
      },
      {
        "old": "/blog/tag/apartments-in-kakkanad/",
        "new": "/blogs"
      },
      {
        "old": "/blog/author/adminclassichomes/page/7/",
        "new": "/blogs"
      },
      {
        "old": "/blog/why-choosing-the-right-builder-is-crucial-for-a-successful-construction-project-in-kochi/services",
        "new": "/blog/why-choosing-the-right-builder-is-crucial-for-a-successful-construction-project-in-kochi/"
      },
      {
        "old": "/blog/tag/villas-in-kakkanad/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/ready-to-occupy-flats-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/category/uncategorized/page/5/",
        "new": "/blogs"
      },
      {
        "old": "/villas/red",
        "new": "/villas-for-sale-in-kochi"
      },
      {
        "old": "/blog/tag/apartments-in-kochi/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/blog/author/adminclassichomes/page/9/",
        "new": "/blogs"
      },
      {
        "old": "/villas-for-sale-in-kochi/classic-gardens/services",
        "new": "/villas-for-sale-in-kochi/classic-gardens"
      },
      {
        "old": "/classic-summer-sands/",
        "new": "/apartments-for-sale-in-kochi/classic-summer-sands"
      },
      {
        "old": "/blog/?elementor_library=header-exp/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/luxury-villas-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/",
        "new": "/blogs"
      },
      {
        "old": "/blog/tag/apartments-for-sale-in-kochi/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/apartments-for-sale-in-kochi/classic-capitol/services",
        "new": "/apartments-for-sale-in-kochi/classic-capitol"
      },
      {
        "old": "/blog/the-ultimate-apartment-checklist/services",
        "new": "/blog/the-ultimate-apartment-checklist/"
      },
      {
        "old": "/blog/luxurious-living-discover-the-best-3-bhk-flats-in-kochi-for-unmatched-comfort/services",
        "new": "/blog/luxurious-living-discover-the-best-3-bhk-flats-in-kochi-for-unmatched-comfort/"
      },
      {
        "old": "/blog/tips-and-tricks-to-make-relocation-easier/services",
        "new": "/blog/tips-and-tricks-to-make-relocation-easier/"
      },
      {
        "old": "/classic-tropicana-hills/",
        "new": "/villas-for-sale-in-kochi/classic-tropicana-hills"
      },
      {
        "old": "/apartments-for-sale-in-kochi/services",
        "new": "/apartments-for-sale-in-kochi/"
      },
      {
        "old": "/blog/tag/top-builders-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/discover-your-dream-home-exploring-the-best-3-bhk-flats-in-kochi-for-a-luxurious-living-experience/services",
        "new": "/blog/discover-your-dream-home-exploring-the-best-3-bhk-flats-in-kochi-for-a-luxurious-living-experience/"
      },
      {
        "old": "/foundation-stone-laying-ceremony-of-classic-avenir-suites",
        "new": "/apartments-for-sale-in-kochi/classic-avenir-suites"
      },
      {
        "old": "/blog/dampness-or-wet-wall/",
        "new": "/blogs"
      },
      {
        "old": "/villas/gardens",
        "new": "/villas-for-sale-in-kochi/classic-gardens"
      },
      {
        "old": "/site/video_testimonial",
        "new": "/our-community"
      },
      {
        "old": "/blog/choose-the-best-floor-plan-for-your-family/services",
        "new": "/blog/choose-the-best-floor-plan-for-your-family/"
      },
      {
        "old": "/villas/tropicana/services",
        "new": "/villas-for-sale-in-kochi/classic-tropicana-hills"
      },
      {
        "old": "/blog/tag/builders-in-kochi/page/3/",
        "new": "/blogs"
      },
      {
        "old": "/services/services",
        "new": "/services"
      },
      {
        "old": "/blog/tag/ready-to-move-flats-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/where-can-we-find-the-best-flats-for-sale-in-kochi/services",
        "new": "/blog/where-can-we-find-the-best-flats-for-sale-in-kochi/"
      },
      {
        "old": "/about-us/",
        "new": "/about"
      },
      {
        "old": "/blog/category/flats-in-kochi/",
        "new": "/blogs"
      },
      {
        "old": "/blog/page/8/?elementor_library=social-media-widget/",
        "new": "/blogs"
      },
      {
        "old": "/classic-vintage/",
        "new": "/apartments-for-sale-in-kochi/classic-vintage"
      },
      {
        "old": "/classic-red-carpet-homes/",
        "new": "/villas-for-sale-in-kochi/classic-red-carpet-homes"
      },
      {
        "old": "/blog/page/2/",
        "new": "/blogs"
      },
      {
        "old": "/undefined",
        "new": "/our-community"
      },
      {
        "old": "/blog/kochi-flats-where-convenience-meets-comfort/services",
        "new": "/blog/kochi-flats-where-convenience-meets-comfort/"
      },
      {
        "old": "/villas/services/services",
        "new": "/villas-for-sale-in-kochi"
      },
      {
        "old": "/blog/author/adminclassichomes/page/6/",
        "new": "/contact"
      },
      {
        "old": "/blog/all-about-stamp-duty-and-registration-charges-in-kochi/services",
        "new": "/blog/all-about-stamp-duty-and-registration-charges-in-kochi/"
      },
      {
        "old": "/blog/category/uncategorized/page/4/",
        "new": "/contact"
      },
      
      
]