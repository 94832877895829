import React, { useEffect, useRef, useState } from "react";
import left from "../../../../assets/images/customer/events/left.png";
import right from "../../../../assets/images/customer/events/right.png";
import styled from "styled-components";
import SlideCard from "./slidecard/SlideCard";
import Slider from "react-slick";
import ReactPlayer from "react-player";

export default function Gallery({ listEvents }) {
  const [fetchedImage, setFetchedImage] = useState("");
  const [fetchedId, setFetchedId] = useState("");
  const [scrollId, setScrollID] = useState("");
  const [mediaType, setMediaType] = useState("image");

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 100; // Adjust the scroll amount as needed
    }
  };
  const scrollRight = (item) => {
    if (sliderRef.current && fetchedId == item.id) {
      sliderRef.current.scrollLeft += 100; // Adjust the scroll amount as needed
    }
  };
  const settings = {
    slidesToShow: 6,
    slidesToScroll: 3,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        {listEvents.map((item, index) => (
          <CoverSlider key={item.id} onClick={() => setFetchedId(item.id)}>
            <Top>
              <FixedCard>
                <ImageContainer>
                  <img src={item.image} alt="image" />
                </ImageContainer>
                <Title>{item.event_name}</Title>
              </FixedCard>
              <Label>{item.event_name}</Label>
              {/* --------other side fetching media -------- */}
              <SlideCard
                fetchedImage={fetchedImage}
                fetchedId={fetchedId}
                mediaType={mediaType}
                item={item}
                index={index}
              />
              {/* --------other side fetching media -------- */}
            </Top>
            <CoverBottom>
              <BottomSlider>
                <Slider {...settings} id="event">
                  {item.event_assets.map((item, index) => (
                    <Image
                      onClick={() => {
                        setMediaType("image");

                        item.type == "video"
                          ? setFetchedImage(item.videos)
                          : setFetchedImage(item.image);
                        setMediaType(item.type);
                      }}
                      key={index}
                    >
                      {item.type == "video" ? (
                        <ReactPlayer
                          className="player"
                          url={item.videos}
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        <img src={item.image} alt="image" />
                      )}
                      {item.type == "video" && (
                        <Play>
                          <i class="ri-play-fill"></i>
                        </Play>
                      )}
                    </Image>
                  ))}
                </Slider>
              </BottomSlider>
            </CoverBottom>
          </CoverSlider>
        ))}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 80px 0;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Play = styled.div`
  position: absolute;
  color: #fff;
  border: 1px solid #fff;
  top: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const CoverBottom = styled.div`
  position: relative;
`;
const Arrow = styled.div`
  position: absolute;
  top: 35%;
  z-index: 1;
  cursor: pointer;
  @media all and (max-width: 480px) {
    width: 50px;
  }
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
`;
const CoverSlider = styled.div`
  margin-bottom: 150px;
  @media all and (max-width: 980px) {
    margin-bottom: 100px;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 50px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
const Top = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
  @media all and (max-width: 480px) {
    display: block;
  }
`;
const FixedCard = styled.div`
  position: relative;
  width: 50%;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const Label = styled.div`
  display: none;
  @media all and (max-width: 480px) {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #000000;
    border-bottom: 1px solid var(--orange);
    width: fit-content;
    padding-bottom: 10px;
  }
`;
const ImageContainer = styled.div`
  height: 100%;
  filter: brightness(0.7);
  img {
    height: 100%;
  }
`;
const Title = styled.h1`
  color: #fff;
  font-weight: 600;
  font-size: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  @media all and (max-width: 980px) {
    font-size: 40px;
  }
`;

const BottomSlider = styled.div`
  transition: transform 0.3s ease;
`;
const BottomSliders = styled.div`
  display: flex;
  gap: 10px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  transition: transform 0.3s ease;
`;
const Image = styled.div`
  transition: transform 0.3s ease;
  /* min-width: 150px !important; */
  width: 96% !important;
  filter: brightness(0.6);
  position: relative;
  @media all and (max-width: 480px) {
    min-width: 100px;
  }
  cursor: pointer;
`;
