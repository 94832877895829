import React from "react";
import styled from "styled-components";
import { blogsData, recentBlogsData } from "../../../assets/data/dummyData";

import arrow from "../../../assets/images/blogs/icon/arrow.svg";

import searchIcon from "../../../assets/images/blogs/icon/search_icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { accountsConfig } from "../../../axiosConfig";
import { useState } from "react";
import Nodata from "../helper/Nodata";

function BlogsDisplay() {
  const navigate = useNavigate();
  const [blogs, setblogs] = useState({ num_pages: 0 });
  const [page, setpage] = useState(1);
  const [categories, setcategories] = useState([]);
  const [search, setsearch] = useState("");
  const [category, setcategory] = useState("");
  const location = useLocation();
  const tagId = location?.state?.tagId;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (tagId) {
      accountsConfig.get("web/list-tag-blog/" + tagId).then((response) => {
        const { data, StatusCode } = response.data.app_data;
        if (StatusCode === 6000) {
          setblogs(data);
        } else {
          setblogs({ num_pages: 0 });
        }
      });
    } else {
      if (search) {
        accountsConfig
          .get("web/search-blog/", {
            params: {
              q: search,
              page: page,
            },
          })
          .then((response) => {
            const { data, StatusCode } = response.data.app_data;
            if (StatusCode === 6000) {
              setblogs(data);
            } else {
              setblogs({ num_pages: 0 });
            }
          });
      } else if (category) {
        accountsConfig
          .get("web/list-category-blog/" + category, {
            params: {
              page: page,
            },
          })
          .then((response) => {
            const { data, StatusCode } = response.data.app_data;
            if (StatusCode === 6000) {
              setblogs(data);
            } else {
              setblogs({ num_pages: 0 });
            }
          });
      } else {
        accountsConfig.get("web/list-blogs/",{
          params:{
            page:page
          }
        }).then((response) => {
          const { data, StatusCode } = response.data.app_data;
          if (StatusCode === 6000) {
            setblogs(data);
          } else {
            setblogs({ num_pages: 0 });
          }
        });
      }
    }

    accountsConfig.get("web/list-categories/").then((response) => {
      const { data, StatusCode } = response.data.app_data;
      if (StatusCode === 6000) {
        setcategories(data);
      }
    });
  }, [page]);

  useEffect(() => {
    if (search) {
      accountsConfig
        .get("web/search-blog/", {
          params: {
            q: search,
            page: 1,
          },
        })
        .then((response) => {
          const { data, StatusCode } = response.data.app_data;
          if (StatusCode === 6000) {
            setblogs(data);
          } else {
            setblogs({ num_pages: 0 });
          }
        });
    }
  }, [search]);

  const handleCategoryFilter = (id) => {
    accountsConfig.get("web/list-category-blog/" + id).then((response) => {
      const { data, StatusCode } = response.data.app_data;
      if (StatusCode === 6000) {
        setblogs(data);
      }
    });
  };

  const handleClick = (url) => {
    navigate("/blog/" + url);
  };
  return (
    <Section>
      <Wrapper className="wrapper">
        <Left>
          <BlogsConatiner>
            {blogs?.num_pages === 0 ? (
              <Nodata h={"40%"} />
            ) : (
              blogs?.results?.map((obj) => {
                return (
                  <SingleBlog onClick={() => handleClick(obj.slug)}>
                    <BlogImage>
                      <img  src={obj.image} alt={obj?.alt_tag} />
                    </BlogImage>
                    <BlogInfo>
                      {/* <Tags>
                          {obj.tags.map((tag) => {
                            return <Tag>{tag}</Tag>;
                          })}
                        </Tags> */}
                      <BlogTitle>{obj.title}</BlogTitle>
                      <BlogDesc
                        dangerouslySetInnerHTML={{
                          __html: obj.description.slice(0, 200) + "...",
                        }}
                      ></BlogDesc>
                      <BlogBottom>
                        <Date>{obj.blog_date}</Date>
                        <ReadMore>Read More</ReadMore>
                      </BlogBottom>
                    </BlogInfo>
                  </SingleBlog>
                );
              })
            )}
          </BlogsConatiner>
          <Paginator>
            {page > 1 && (
              <PrevArrow onClick={() => setpage(page - 1)}>
                <img src={arrow} alt="" />
              </PrevArrow>
            )}

            {page !== 1 && (
              <PageInfo onClick={() => setpage(page - 1)}>
                {" "}
                {page - 1}{" "}
              </PageInfo>
            )}

            <PageInfo className="current">{page}</PageInfo>
            {blogs.num_pages > page + 1 && (
              <PageInfo onClick={() => setpage(page + 1)}>
                {" "}
                {page + 1}{" "}
              </PageInfo>
            )}

            {page < blogs?.num_pages && (
              <NextArrow onClick={() => setpage(page + 1)}>
                {" "}
                <img src={arrow} alt="" />{" "}
              </NextArrow>
            )}
          </Paginator>
        </Left>
        <Right>
          <SearchContainer>
            <SearchInput
              onChange={(e) => setsearch(e.target.value)}
              icon={searchIcon}
              placeholder="Search here..."
            />
          </SearchContainer>
          <RecentBlogs>
            <RecentBlogsTitle>RECENT POSTS</RecentBlogsTitle>
            <Line />
            <RecentBlogsList>
              {blogs?.results?.slice(-5)?.map((obj, index) => (
                <SingleRecentBlog onClick={() => handleClick(obj.slug)}>
                  <Img>
                    <img src={obj.image} alt={obj?.alt_tag} />
                  </Img>
                  <RecentBlogDesc
                    dangerouslySetInnerHTML={{
                      __html: obj?.title,
                    }}
                  ></RecentBlogDesc>
                </SingleRecentBlog>
              ))}
            </RecentBlogsList>
          </RecentBlogs>

          <Category>
            <CategoryTitle>CATEGORIES</CategoryTitle>
            <Line />
            <CategoryList>
              {categories.map((item) => {
                return (
                  <CategoryItem
                    className={item?.id === category && "active"}
                    onClick={() => {
                      if (category === item.id) {
                        setcategory("");
                      } else {
                        setcategory(item.id);
                        handleCategoryFilter(item?.id);
                      }
                    }}
                  >
                    {item.name}
                  </CategoryItem>
                );
              })}
            </CategoryList>
          </Category>

          {/* <TagsContainer>
            <TagsTitle>
               TAGS
            </TagsTitle>
            <Line/>
            <TagsList>
              <TagItem className="active">
              Apartments
              </TagItem>
              <TagItem className="active">
              Villas
              </TagItem>
              <TagItem className="active">
              Flats
              </TagItem>
              <TagItem>
                Properties
              </TagItem>
            </TagsList>
          </TagsContainer> */}
        </Right>
      </Wrapper>
    </Section>
  );
}

export default BlogsDisplay;

const Section = styled.div`
  display: flex;
  margin-bottom: 90px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 425px) {
    width: 90%;
  }
`;

const Left = styled.div`
  width:60%;
  /* flex:2; */
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Right = styled.div`
  width:40%;
  /* flex:1; */
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const BlogsConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SingleBlog = styled.div`
  cursor: pointer;
  width: 100%;
`;

const BlogImage = styled.div`
    width: 100%;

    img{
      width: 100%;
      display:block;
      object-fit: contain;
      max-width: 100%;
    }
`;

const BlogInfo = styled.div`
  padding: 35px;
  background-color: #ffff;
  gap: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 425px) {
    padding: 35px 20px;
  }
`;

const Tags = styled.div`
  display: flex;
  gap: 35px;

  @media (max-width: 425px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

const Tag = styled.div`
  color: #ffa466;
  /* Para reg 16 caps */
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
`;

const BlogTitle = styled.div`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.42px;

  @media (max-width: 580px) {
    font-size: 18px;
  }
`;

const BlogDesc = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  width: 100%;

  p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
`;

const BlogBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Date = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
`;

const ReadMore = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  border-bottom: 1px solid #eb6502;
`;

const SearchContainer = styled.div`
  padding: 40px;
  width: 100%;
  background-color: #fff;

  @media (max-width: 1200px) {
    width: calc(100% - 80px);
  }
`;

const RecentBlogs = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  width: 100%;

  @media (max-width: 1200px) {
    width: calc(100% - 80px);
  }
`;

const Category = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  width: 100%;

  @media (max-width: 1200px) {
    width: calc(100% - 80px);
  }
`;

const TagsContainer = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  width: 100%;
`;

const SearchInput = styled.input`
  background: #f4f4f4;
  padding: 15px;
  border: none;
  outline: none;
  position: relative;
  width: 90%;
  ${(props) => props.icon && `background-image: url('${props.icon}')`};
  background-repeat: no-repeat;
  background-position: 95% center;

  &::placeholder {
    font-size: 15px;
    font-size: "gellix-regular";
  }
`;

const RecentBlogsTitle = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.1px;
  width: 100%;

  @media (max-width: 400px) {
    font-size: 24px;
  }
`;

const Line = styled.div`
  height: 0.5px;
  background: #bcbcbc;
  width: 100%;
`;

const RecentBlogsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const SingleRecentBlog = styled.div`
  display: flex;
  gap: 20px;
  cursor: pointer;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

const Img = styled.div`
  flex: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const RecentBlogDesc = styled.div`
  flex: 2;
  color: #706e6c;
  text-align: left;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  display: flex;
  align-items: center;
`;

const CategoryTitle = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.1px;
  width: 100%;
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const CategoryItem = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  cursor: pointer;

  &.active {
    color: orange;
  }
`;

const TagsTitle = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.1px;
  width: 100%;
`;

const TagsList = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const TagItem = styled.div`
  padding: 8px 16px;
  color: #fff;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  background-color: #aaa;

  &.active {
    background: #eb6502;
  }
`;

const Paginator = styled.div`
  display: flex;
  gap: 12px;
  padding: 40px 0;
  justify-content: center;
  align-items: center;
`;

const PageInfo = styled.div`
  padding: 12px 24px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  height: 30px;
  cursor: pointer;

  &.current {
    background-color: #eb6502;
    color: #ffffff;
  }

  @media (max-width: 425px) {
    padding: 5px 15px;
  }
`;

const NextArrow = styled.div`
  padding: 12px 24px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;

  @media (max-width: 425px) {
    padding: 5px 15px;
  }
`;

const PrevArrow = styled.div`
  padding: 12px 24px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;

  img {
    transform: rotate(180deg);
  }

  @media (max-width: 425px) {
    padding: 5px 15px;
  }
`;
