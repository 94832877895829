import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import closeBtn from "../../../assets/images/apartments/gallery/newDesign/icons/close-W-btn.svg";
import Slider from "react-slick";
import arrowLeft from "../../../assets/images/apartments/gallery/newDesign/icons/leftArrW.svg";
import arrowRight from "../../../assets/images/apartments/gallery/newDesign/icons/rightarrW.svg";
import { CustomContext } from "../../context/StoreContext";

function KeyHandOverModal({ modal, setModal, assets, current }) {
  const { active , setactive} = useContext(CustomContext);
  const slickSliderRef = useRef(null);
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  useEffect(() => {
    if (current) {
      let index = "";
      assets?.forEach((obj, ind) => {
        if (obj.id === current) {
          index = ind;
        }
      });

      if (index) {
        slickSliderRef.current.slickGoTo(index);
      }
      
    }
  }, [current]);

  useEffect(() => {
    if(modal){
      setactive(true);
    }else{
      setactive(false);
    }
  }, [modal])


  return (
    <Section className={modal && "active"}>
      <Overlay onClick={() => setModal(false)} />
      <Modal>
      <CloseIcon onClick={() => setModal(false)}>
          <img src={closeBtn} alt="" />
      </CloseIcon>
        <Container className="conta">
          <Slider ref={slickSliderRef} {...settings}>
            {assets?.map((obj) => (
              <Item className="h">
                <Img src={obj?.image} />
              </Item>
            ))}
          </Slider>
        </Container>
      </Modal>
    </Section>
  );
}

export default KeyHandOverModal;

const Section = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: 11;

  &.active {
    visibility: visible;
  }
`;

const Modal = styled.div`
  width: 70vw;
  max-width: 750px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding:0px;
  box-sizing: border-box;
  gap: 20px;
  z-index: 400;
  height: 90vh;
  min-height: 550px;
  max-height: 650px;
  position: relative;
  justify-content: center;
  align-items: center;
  /* background-color:red; */

  @media (max-width: 625px) {
    width: 90vw;
    justify-content: center;
  }

  @media (max-width: 525px) {
    max-height: 650px;
    height: 70vh;
    min-height: 550px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  padding: 15px;
  width: 100%;
  height: 100%;
  justify-content: center;

  .slick-next {
    top: calc(90%);
    right: 30% !important;
    display: block !important;
  }
  .slick-prev {
    top: calc(90%);
    left: 15% !important;
    display: block !important;
  }

  .slick-next:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowRight}) !important;
    background-size: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 60px;
  }
  .slick-prev:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowLeft}) !important;
    background-size: contain;
    background-repeat: no-repeat;
    height: 60px;
    width:60px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: black;
  opacity: 0.8;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
`;

const CloseIcon = styled.span`
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer;
  img {
    width: 18px;
  }
`;

const Item = styled.div`
  width: 90% !important;
  height: 100%;
  display: block;
  /* border: 10px solid #dfdfdf; */
  border-radius: 10px;
  height: 60vh;
  max-height: 500px;

  img{
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  video{
    height: auto;
    object-fit: cover;
    width: 100%;
    min-height: 400px;
  }

  .player{
    min-height:400px;
    div{
      height: 100% !important;
      width: 100% !important;
    }

    @media(max-width:580px){
      min-height:auto !important;
      height:50vh;
    }
  }

  @media(max-width:580px){
    width:100% !important;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;
