import React from 'react'
import styled from 'styled-components';

function ApprtmentAbout() {
  return (
    <MainContainer>
      <Title>Amenities Abound! Luxury Living Close to Everything</Title>
        <Para>Luxury living in Kochi with ready-to-move premium 2/3HK apartments having ultramodern facilities. Situated within walking distance of the city's major landmarks, we redefine urban living in Kakkanad.</Para>
    </MainContainer>
  )
}

export default ApprtmentAbout

const MainContainer = styled.div`
  padding-top: 40px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media all and (max-width: 480px) {
    padding-top: 40px;
  }
`;
const Title = styled.h2`
  font-family: "gellix-medium";
  text-align: center;
  margin-bottom: 10px;
  @media (max-width: 1300px) {
    font-size: 21px;
    width: 90%;
  }
`;

const Para = styled.p`
  line-height: 28px;
  text-align: justify;
  margin: 0 auto;
  @media (max-width: 1300px) {
    width: 90%;
  }

`;