import React, { useEffect, useState } from "react";
import styled from "styled-components";

import closeIcon from "../../assets/images/drawer/closeBtn.svg";
import phnIcon from "../../assets/images/drawer/phnIcon.svg";
import mailIcon from "../../assets/images/drawer/mailIcon.svg";
import instaIcon from "../../assets/images/drawer/instaIcon.svg";
import fbIcon from "../../assets/images/drawer/fbIcon.svg";
import linkIcon from "../../assets/images/drawer/linkIcon.svg";
import youtubeIcon from "../../assets/images/drawer/youtubeIcon.svg";
import landphone from '../../assets/images/icons/landphone-sm.svg';
import { accountsConfig } from "../../axiosConfig";

function Drawer({ setactive, active, navigate }) {
  const [contactInfo, setcontactInfo] = useState({})
  useEffect(() => {
    const html = document.querySelector("html");
    if (active) {
      html.classList.add("model-enabled");
    } else {
      html.classList.remove("model-enabled");
    }
  }, [active]);

  useEffect(() => {
    accountsConfig.get('web/list-contact-details/').then((response)=>{
      const { StatusCode , data}  = response.data.app_data;
      
      if(StatusCode === 6000){
        setcontactInfo(data[0])
      }
    })
  }, [])

  return (
    <Section className={active && "show"}>
      <SideBar className={active && "active"}>
        <CloseBtn>
          <img onClick={() => setactive(false)} src={closeIcon} alt="" />
        </CloseBtn>
        <Container>
          <List>
            <Item className="mob" onClick={() => navigate("/")}>
              Home
            </Item>
            <Item className="mob" onClick={() => navigate("/apartments-for-sale-in-kochi")}>
              Apartments
            </Item>
            <Item className="mob" onClick={() => navigate("/villas-for-sale-in-kochi")}>
              Villas
            </Item>
            <Item className="mob" onClick={() => navigate("/our-community")}>
              Our Community
            </Item>
            <Item onClick={() => navigate("/about")}>About</Item>
            <Item onClick={() => navigate("/blogs")}>Blogs</Item>
            <Item onClick={() => navigate("/services")}>
              Services    
            </Item>
            <Item onClick={() => navigate("/contact")}>Contact</Item>
          </List>
          <ContactInfo>
            <Title>Contact Us</Title>
            <Body>
              <Element>
                <Icon>
                  <img src={phnIcon} alt="" />
                </Icon>
                <Content>
                  <Span href={`tel:${contactInfo?.phone1}`}>{contactInfo?.phone1}</Span>
                  <Span href={`tel:${contactInfo?.phone2}`}>{contactInfo?.phone2}</Span>
                </Content>
              </Element>
              <Element>
                <Icon>
                  <img src={landphone} alt="" />
                </Icon>
                <Content>
                <Span href={`tel:${contactInfo?.phone3}`}>{contactInfo?.phone3}</Span>
                </Content>
              </Element>
              <Element>
                <Icon>
                  <img src={mailIcon} alt="" />
                </Icon>
                <Content>
                <Span href={`mailto:${contactInfo?.email1}`}>{contactInfo?.email1}</Span>
                </Content>
              </Element>
            </Body>
          </ContactInfo>
          <SocialMediaIcons>
            <Title>Follow</Title>
            <IconsContainer>
              <Icon href="https://www.instagram.com/classichomes_kochi/" target="_blank">
                <img src={instaIcon} alt="" />
              </Icon>
              <Icon href="https://www.facebook.com/classichomesonline/" target="_blank">
                <img src={fbIcon} alt="" />
              </Icon>
              <Icon href="https://www.linkedin.com/company/classic-homes-builders/" target="_blank">
                <img src={linkIcon} alt="" />
              </Icon>
              <Icon href="https://www.youtube.com/channel/UC3qkORguoJJE_iJP5gCP1pQ" target="_blank">
                <img src={youtubeIcon} alt="" />
              </Icon>
            </IconsContainer>
          </SocialMediaIcons>
        </Container>
      </SideBar>
    </Section>
  );
}

export default Drawer;

const Section = styled.div`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  inset: 0;
  z-index: 252;
  background-color: #00000058;
  visibility: hidden;

  &.show {
    visibility: visible;
  }
`;

const SideBar = styled.div`
      width:300px;
      height: 100vh;
      background: #2B2A29;
      overflow: auto;
      transform: translateX(300px);
      transition: all .4s ease-in;
      padding: 50px 18px;
      box-sizing: border-box;
      &.active{
      transform: translateX(0px);
      transition: all .6s ease;
      }
`;

const Container = styled.div`
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
const CloseBtn = styled.div`
  width: 100%;

  img {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
`;
const List = styled.div``;
const Item = styled.div`
    padding: 12px;
    border-bottom: 1px solid #8E8E8E;
    color: #FFF;
    font-family: 'gellix-regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    &.mob{
      display: none;
    }
    
    @media(max-width:1300px){
      &.mob{
        display: block;
      }
    }
`;
const Icon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 21px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Title = styled.div`
  color: #ffa466;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-top: 1px solid #8e8e8e;
`;
const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
const SocialMediaIcons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
const Element = styled.div`
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #8e8e8e;
  padding: 10px 0;
`;
const Span = styled.a`
  color: #fff;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 4px;
`;
