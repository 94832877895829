import React, { useEffect } from "react";

// imports
import Header from "../includes/Header";
import Spotlight from "../includes/home/Spotlight";
import About from "../includes/home/About";
import Projects from "../includes/home/Projects";
import Footer from "../includes/Footer";
import OnloadPopUp from "../includes/modal/OnloadPopUp";
import { useState } from "react";
import styled from "styled-components";
import ClassicStory from "../includes/home/ClassicStory";
import Celebrations from "../includes/home/Celebrations";
import Loader from "../includes/helper/Loader";
import Helmet from "../includes/Helmet";
import { accountsConfig } from "../../axiosConfig";

function Home() {
  const [modal, setmodal] = useState(false);
  const [loader, setloader] = useState(true);
  const [datas, setdatas] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setloader(false);
    }, 3000);

    // delaying onload pop
    setTimeout(() => {
      setmodal(true);
    }, 20000);
  }, []);

  // geting seo tags
  useEffect(() => {
    accountsConfig
      .get("web/list-link-seo/", {
        params: {
          links: window.location.pathname,
        },
      })
      .then((res) => {
        const { data, StatusCode } = res.data.app_data;
        if (StatusCode === 6000) {
          setdatas(data[0]);
        } else {
          setdatas({});
        }
      });
  }, []);

  return (
    <Container>
      <Helmet
        titleData={datas?.meta_title}
        descriptionData={datas?.meta_description}
      />
      <Header />
      <Spotlight />
      <About />
      <Projects />
      {/* <UpdatedTestimonial /> */}
      <Celebrations />
      <ClassicStory />
      {/* <Contact /> */}
      <Footer />
      <OnloadPopUp modal={modal} setModal={setmodal} />
      <Loader status={loader} />
    </Container>
  );
}

export default Home;

const Container = styled.div`
  /* width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  display: block;
  opacity: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -2; */
`;
