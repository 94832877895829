import React from "react";
import styled from "styled-components";
import img2 from "../../../assets/images/about/about-img-2.png";
import img3 from "../../../assets/images/about/img-3.png";
import icon1 from "../../../assets/images/about/icons/Customer_first.svg";
import icon2 from "../../../assets/images/about/icons/Integrity.svg";
import icon3 from "../../../assets/images/about/icons/Reliability.svg";
import icon4 from "../../../assets/images/about/icons/Sustainability.svg";

import icon5 from "../../../assets/images/about/icons/properties.svg";
import icon6 from "../../../assets/images/about/icons/experience.svg";
import icon7 from "../../../assets/images/about/icons/people.svg";

function MoreInfo() {
  return (
    <Main>
      <Section>
        <Wrapper className="wrapper">
          <Left>
            <Subtitle>BEST BUILDERS IN KOCHI</Subtitle>
            <Line />
            <Title>
              <Classic>UPHOLDING</Classic>
              VALUES
            </Title>
            <ParaConatiner>
              <Para>
                At Classic Homes, our commitment to integrity, transparency, and
                ethical business practices forms the bedrock of our operations.
                We prioritize honesty, reliability, and a customer-centric
                approach, ensuring that every interaction resonates with trust
                and respect.
              </Para>
            </ParaConatiner>
            <PointsList>
              <Point>
                <img src={icon1} alt="" />
                <PointName>Customer Centric</PointName>
              </Point>
              <Point>
                <img src={icon3} alt="" />
                <PointName>Ultimate Reliability</PointName>
              </Point>
              <Point>
                <img src={icon4} alt="" />
                <PointName>Sustainability</PointName>
              </Point>
              <Point>
                <img src={icon2} alt="" />
                <PointName>Integrity</PointName>
              </Point>
            </PointsList>
          </Left>
          <Right>
            <img src={img2} alt="" />
          </Right>
        </Wrapper>
      </Section>
      <Section2 bg={img3}>
        <Wrapper2 className="wrapper">
          <CounterContainer>
            <Counter>
              <img src={icon7} alt="" />
              <CounterInfo>
                <Num>300+</Num>
                <Name>Happy Clients</Name>
              </CounterInfo>
            </Counter>
            <Counter>
              <img src={icon6} alt="" />
              <CounterInfo>
                <Num>29+</Num>
                <Name>Years Experience</Name>
              </CounterInfo>
            </Counter>
            <Counter>
              <img src={icon5} alt="" />
              <CounterInfo>
                <Num>40+</Num>
                <Name>Completed Properties</Name>
              </CounterInfo>
            </Counter>
          </CounterContainer>
        </Wrapper2>
      </Section2>
    </Main>
  );
}

export default MoreInfo;

const Main = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const Section = styled.div`
  margin: 0px;
  background-color: #f8f8f8;
  padding: 130px 0;

  @media (max-width: 768px) {
    padding: 64px 0;
  }
`;

const Section2 = styled.div`
  height: 475px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props?.bg});
  width: 100%;

  @media (max-width: 580px) {
    height: 850px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: row-reverse;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    gap: 40px;
  }
`;

const Wrapper2 = styled.div`
  display: flex;
  gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 25px;

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;
const Subtitle = styled.div`
  color: #606060;

  /* Para reg 16 caps */
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
`;
const Line = styled.div`
  height: 0.5px;
  width: 50px;
  background-color: #eb6502;
`;
const Title = styled.div`
  display: flex;
  color: #eb6502;
  font-family: "gellix-regular";
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 3.75px;
  flex-direction: column;

  @media (max-width: 1200px) {
    flex-direction: row;
  }

  @media (max-width: 875px) {
    font-size: 50px;
  }
  @media (max-width: 580px) {
    /* font-size: 65px; */
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: var(--heading-fz);
    line-height: 34px;
  }
`;
const Classic = styled.div`
  color: #606060;

  @media (max-width: 580px) {
    font-size: var(--heading-fz);
  }
`;
const ParaConatiner = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-top: 20px;
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  img {
    width: 80%;
    height: auto;
    display: block;

    @media (max-width: 1200px) {
      width: 70%;
      margin: 0 auto;
    }
  }
`;
const Para = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  text-align: justify;

  @media (max-width: 1200px) {
    text-align: justify;
  }
`;

const PointsList = styled.div`
  display: flex;
  gap: 63px;
  margin-top: 20px;

  @media (max-width: 1200px) {
    justify-content: space-between;
  }

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
  @media (max-width: 393px) {
    justify-content: center;
  }
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    width: 40%;
  }

  @media (max-width: 393px) {
    width: 60%;
  }

  img {
    width: 63px;
    height: 60px;
  }
`;

const PointName = styled.p`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.27px;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const CounterContainer = styled.div`
  width: 65%;
  height: 100%;
  background: rgba(235, 101, 2, 0.85);
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  gap: 10px;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 580px) {
    flex-direction: column;
  }
`;

const Counter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: center;
  img {
    width: 80.112px;
    height: 80px;
  }
`;

const CounterInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const Num = styled.div`
  color: #fff;
  font-family: "gellix-regular";
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Name = styled.p`
  color: #fff;
  font-family: "gellix-regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.375px;
  margin: 0;
  padding: 0;
`;
