export function getFirstAndBalanceWords(sentence) {
  // Remove leading and trailing whitespaces
  const trimmedSentence = sentence.trim();

  // Split the sentence into an array of words
  const words = trimmedSentence.split(/\s+/);

  // Extract the first word
  const firstWord = words[0];

  // Extract the "balance" words (all words except the first one)
  const balanceWords = words.slice(1);

  // Join the "balance" words back into a string
  const balanceWordsString = balanceWords.join(" ");
  
  const balanceWordsStringLength = balanceWords.length;

  // Return an object with the first word and the "balance" words
  return { firstWord, balanceWords: balanceWordsString,length: balanceWordsStringLength};
}
