import React from 'react'
import styled from 'styled-components';
import breadCrumb from '../../../assets/images/about/breadCrumb/Breadcrumb.jpg';

function BreadCrumb() {
  return (
    <Section bg={breadCrumb}>
        <Content>
            <Heading>
            BLOGS <span>& ARTICLES</span> 
            </Heading>
            <Line/>
            <SubHeading>
            latest news & articles
            </SubHeading>
        </Content>
    </Section>
  )
}

export default BreadCrumb;


const Section = styled.div`
    height: calc(400px - 83px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
    /* background-image: url(${(props)=>props?.bg}); */
    position: relative;

    /* &::after{
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.25);
    } */

    @media screen and (max-width: 580px) {
      height: calc(300px - 83px);
    }
`;

const Content = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    z-index: 1;
`;
const Heading = styled.div`
    color: #606060;
    font-family: 'gellix-medium';
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.75px;

    span{
      color:#EB6502;
    }
    
    @media(max-width:650px){
        font-size: 45px;
    }
    @media(max-width:475px){
        font-size: 35px;
    }

    @media(max-width:400px){
        font-size: 30px;
    }
    @media(max-width:350px){
        font-size: 25px;
    }

    `;
const Line = styled.div`
      height: 1px;
      width:80px;
      background-color: #606060;
`;
const SubHeading = styled.div`
        color: #000000;
        font-family: 'gellix-regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;
`;
