import React from "react";
import styled from "styled-components";
import breadCrumb from "../../../../assets/images/about/breadCrumb/Breadcrumb.jpg";

function Banner() {
  return (
    <Section bg={breadCrumb}>
      <Content>
        <Heading>DOCUMENTS & KEY <span>HANDOVER</span></Heading>
        <Line />
        <SubHeading>
          A Glimpse into Key handovers
        </SubHeading>
      </Content>
    </Section>
  );
}

export default Banner;

const Section = styled.div`
  height: calc(400px - 83px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  background-size: cover;
  /* background-image: url(${(props) => props?.bg}); */
  position: relative;
  background-color: #F8F8F8;

  /* &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.25);
  } */
`;

const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  z-index: 1;
`;
const Heading = styled.div`
  color: #606060;
  font-family: "gellix-medium";
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3.75px;
  text-align:center;

  span{
    color:#EB6502;
  }

  @media all and (max-width: 768px) {
    font-size: 48px;
  }
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
  @media all and (max-width: 480px) {
    font-size: 22px;
  }
`;
const Line = styled.div`
  height: 1px;
  width: 100px;
  background-color: #606060;
`;
const SubHeading = styled.div`
  /* color: #606060;
  font-family: "gellix-light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px; */
  color: #606060;
  font-family: "gellix-regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  @media all and (max-width: 480px) {
    font-size: 14px;
    text-align: center;
  }
  @media all and (max-width: 400px) {
    font-size: 12px;
    width: 80%;
  }
`;
