import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import styled from "styled-components";
import pattern from "../../../../assets/images/apartments/pattern1.png";
import { getFirstAndBalanceWords } from "../../../general/common/helper";

import ImagePopUp from "../../../includes/modal/ImagePopUp";
import arrowLeft from "../../../../assets/images/apartments/gallery/newDesign/icons/leftArr.svg";
import arrowRight from "../../../../assets/images/apartments/gallery/newDesign/icons/rightarr.svg";
import ReactPlayer from "react-player";

export default function ApartmentFeatures({ getSingleProject }) {
  const [result, setResult] = useState("");
  const [projectImages, setProjectImages] = useState([]);
  const [currentImage, setcurrentImage] = useState("");
  const [imageModal, setimageModal] = useState(false);
  const [currentIndex, setcurrentIndex] = useState(null);

  const path = window.location.pathname;

  // slider ref
  const sliderRef = useRef();

  const settings = {
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        },
      },
    ],
    afterChange: (currentSlide) => {
      if (sliderRef.current.classList.contains("set-first-slide")) {
        sliderRef.current.classList.remove("set-first-slide");
      }
    },
  };

  useEffect(() => {
    if (getSingleProject?.name) {
      setResult(getFirstAndBalanceWords(getSingleProject.name));
    }
    if (getSingleProject?.projectassets?.length > 0) {
      setProjectImages(getSingleProject?.projectassets);
      setcurrentImage(getSingleProject?.projectassets[0]?.image);
    }
  }, [projectImages, getSingleProject]);

  useEffect(() => {
    if (window.innerWidth < 600) {
      sliderRef.current.classList.add("set-first-slide");
    }
  }, []);

  const handleImageSelect = (index) => {
    setcurrentImage(projectImages[index]?.image);
    setcurrentIndex(index);
  };
  
  
  const handleModalChange = (index) => {
    setcurrentIndex(index);
    setimageModal(true);
  };

  return (
    <MainContainer>
      <Wrapper className="wrapper">
        <Content>
          <Left villa={path.includes("/villas-for-sale-in-kochi/")}>
            {/* <Title>
              {result?.firstWord} {result?.length > 1 && <br />}
              <span>{result?.balanceWords}</span>
            </Title> */}
            {/* {path.includes("/villas/") && (
              <SubTitleContainer>
                <Line />
                <SubTitle>BEST BUILDERS IN KOCHI</SubTitle>
              </SubTitleContainer>
            )} */}

            {getSingleProject?.qr_code && (
              <CoverRera>
                <QRTitle>
                  <img src={getSingleProject?.qr_code} alt="qr-code" />
                </QRTitle>
                <QRTitle>{getSingleProject?.rera_number}</QRTitle>
              </CoverRera>
            )}
            {!path.includes("/villas-for-sale-in-kochi/") && (
              <Description>{getSingleProject?.description}</Description>
            )}
          </Left>
          <Right villa={path.includes("/villas-for-sale-in-kochi/")}>
            {path.includes("/villas-for-sale-in-kochi/") ? (
              <Description>{getSingleProject?.description}</Description>
            ) : (
              <>
                <Img>
                  <img src={pattern} alt="" />
                </Img>
                <Counts>
                  <CoverCount>
                    <Span>{getSingleProject?.floors}</Span>
                    <SpanTitle>Floors</SpanTitle>
                  </CoverCount>
                  <CoverCount>
                    <Span>{getSingleProject?.units}</Span>
                    <SpanTitle>Units</SpanTitle>
                  </CoverCount>
                  <CoverCount>
                    <Span>{getSingleProject?.bhk_sqrft} </Span>
                    <SpanTitle>
                      {path.includes("/villas-for-sale-in-kochi/")
                        ? "Sq. Ft."
                        : getSingleProject?.name === "CLASSIC CAPITOL"
                        ? "Sq. Ft."
                        : "BHK"}
                    </SpanTitle>
                  </CoverCount>
                </Counts>
              </>
            )}
          </Right>
        </Content>
      </Wrapper>

      <Wrapper className="wrapper gallery-wrapper">
        <PhotoGallery>
          <SliderContainer ref={sliderRef}>
            <TopSection>
              <RightSectionTitle>
                Project <span>Gallery</span>
              </RightSectionTitle>
            </TopSection>
            <Slider {...settings} className="slider12">
              {projectImages &&
                projectImages?.map((img, indx) => {
                  if (img.media_type === "image") {
                    return (
                      <Item
                        onClick={() => handleModalChange(indx)}
                        className="item"
                      >
                        <img src={img?.image} alt="" />
                      </Item>
                    );
                  } else {
                    return (
                      <Item
                        onClick={() => handleModalChange(indx)}
                        className="item"
                      >
                        <Overlay onClick={() => handleModalChange(indx)} />
                        <ReactPlayer
                          url={img?.media_type === "url" ? img.url : img.video}
                          playsinline
                          muted
                          controls
                        />
                      </Item>
                    );
                  }
                })}
            </Slider>
          </SliderContainer>
          <ImagePopUp
            modal={imageModal}
            setModal={setimageModal}
            assets={projectImages}
            current={currentIndex}
          />
        </PhotoGallery>
      </Wrapper>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  padding: 0;
`;
const Wrapper = styled.div`
  &.gallery-wrapper {
    margin: 0 auto 90px;
  }
`;
const Content = styled.div`
  display: flex;
  gap: 50px;
  @media all and (max-width: 1440px) {
    gap: 100px;
  }
  @media all and (max-width: 1250px) {
    flex-direction: column;
  }
  @media all and (max-width: 768px) {
    gap: 50px;
  }
`;
const Left = styled.div`
  display: ${(props) => (props.villa ? "none" : "block")};
  width: 50%;
  @media all and (max-width: 1475px) {
    flex: 1;
  }
  @media all and (max-width: 1250px) {
    width: 100%;
  }
`;
const Title = styled.h2`
  color: #606060;
  letter-spacing: 2px;
  font-family: "gellix-bold";
  font-size: 48px;
  span {
    color: #eb6502;
  }
  @media all and (max-width: 1440px) {
    font-size: 54px;
  }
  @media all and (max-width: 768px) {
    font-size: 46px;
  }
  @media all and (max-width: 768px) {
    font-size: 28px;
  }
`;
const SubTitle = styled.p`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
  padding: 0;
  margin: 0;
`;

const Line = styled.hr`
  width: 50px;
  background-color: #eb6502;
  height: 1px;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  text-decoration: none;
`;
const SubTitleContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin: 16px 0;
`;

const CoverRera = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;
const QR = styled.div`
  width: 18%;
  margin-right: 20px;
`;
const QRTitle = styled.h3`
  color: #606060;
  font-family: "gellix-medium";
  letter-spacing: 2px;
  margin-right: 20px;
  font-size: 26px;
  @media all and (max-width: 1440px) {
    font-size: 24px;
  }
  @media all and (max-width: 480px) {
    font-size: 16px;
  }

  img {
    max-height: 150px;
  }
`;
const Description = styled.p`
  color: #706e6c;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  @media all and (max-width: 1440px) {
    font-size: 16px;
  }
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: ${(props) => (props.villa ? "100%" : "50%")};
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  background-size: contain;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: 1700px) {
    min-height: 175px;
  }

  @media all and (max-width: 1440px) {
    background-position: center;
    background-size: 100%;
    justify-content: center;
  }
  @media all and (max-width: 1250px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => (!props.villa ? `url(${pattern})` : "none")};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
`;
const Counts = styled.div`
  display: flex;
  gap: 70px;
  @media all and (max-width: 1440px) {
    margin-left: 0;
  }

  @media all and (max-width: 580px) {
   gap: 45px;
  }

`;
const CoverCount = styled.div``;
const Span = styled.h2`
  color: #606060;
  font-family: "gellix-bold";
  font-size: 90px;
  @media all and (max-width: 1440px) {
    font-size: 84px;
  }
  @media all and (max-width: 1350px) {
    font-size: 75px;
  }
  @media all and (max-width: 580px) {
    font-size: 38px;
  }
`;
const SpanTitle = styled.p`
  color: #606060;
  text-align: center;

  @media screen and (max-width:580px) {
      font-size: 15px;
  } 
`;

const PhotoGallery = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;
`;

const Gallery = styled.div`
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
const LeftGallery = styled.div``;
const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
const RightGallery = styled.div``;
const CoverInner = styled.div`
  display: flex;
`;
const CoverImage = styled.div``;

const Img = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  display: none;

  @media (min-width: 1250px) {
    display: flex;
    align-items: center;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: fill;
    max-height: 325px;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  min-height: 100vh;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const RightSectionTitle = styled.p`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 20px 0;

  @media (max-width: 1300px) {
    font-size: 55px;
  }

  @media (max-width: 415px) {
    font-size: 45px;
  }
  @media (max-width: 350px) {
    font-size: 40px;
  }
  span {
    color: #eb6502;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 35px;
  margin: 50px 0;

  @media (min-width: 2000px) {
    width: 110%;
  }

  .slick-track {
    display: flex !important;
    gap: 15px;
  }

  &.set-first-slide{
    .slick-track {
    transform: translate3d(0,0,0) !important;
  }
  }

  .slick-next {
    top: calc(100% + 25px) !important;
    right: 38% !important;
    display: block !important;
  }

  .slick-prev {
    top: calc(100% + 25px) !important;
    left: 35% !important;
    display: block !important;
  }

  .slick-next:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowRight}) !important;
    background-size: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 70px !important;
  }
  .slick-prev:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowLeft}) !important;
    background-size: contain;
    background-repeat: no-repeat;
    height: 60px;
    width: 70px !important;
  }

  @media (max-width: 580px) {
  .slick-next {
    right: calc(15% + 50px) !important;
   }
  .slick-prev {
    left: calc(15%) !important;
  }

  .slick-track {
    gap: 0px;
  }
  }
`;

const Item = styled.div`
  display: flex !important;
  width: 100% !important;
  outline: none;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  height: 250px;
  background-size: cover;
  position: relative;
  cursor: pointer;

  div {
    height: 100% !important;
    width: 100% !important;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &.active {
    border: 3px solid #eb6502;
  }
`;

const ParentContainer = styled.div`
  width: 130%;
  overflow: hidden;
  position: absolute;
  left: -40px;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
`;
