import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";

import closeBtn from "../../../assets/images/apartments/gallery/newDesign/icons/close-W-btn.svg";
import arrowLeft from "../../../assets/images/apartments/gallery/newDesign/icons/leftArrW.svg";
import arrowRight from "../../../assets/images/apartments/gallery/newDesign/icons/rightarrW.svg";
import ReactPlayer from "react-player";
import { CustomContext } from "../../context/StoreContext";

function ImagePopUp({ modal, setModal, assets, current }) {
  const { setactive} = useContext(CustomContext);
  const slickSliderRef = useRef(null);
  const [states, setStates] = useState({});

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
    infinite: false,
    afterChange: (currentSlide) => {
      const allFalseItems = Object.fromEntries(
        Object.keys(states).map((key) => [key, false])
      );
      setStates(allFalseItems);
      if(modal){
        setStates((prev) => ({ ...prev, [currentSlide]: true }));
      }
    },
  };

  // useEffect(() => {
  //   if(current){
  //     slickSliderRef.current.slickGoTo(current);
  //   }
  // },[current])
  useEffect(() =>{
    if (current > -1) {
      setStates((prev) => ({ ...prev, [current]: false }))

      if (current > -1) {
        slickSliderRef.current.slickGoTo(current);
      }
    }
  },[current]);

  useEffect(() => {
    if(modal){
      setactive(true);
    }else{
      setactive(false);
    }
  }, [modal])

  const handleModalClose = () => {
    const allFalseItems = Object.fromEntries(
      Object.keys(states).map((key) => [key, false])
    );
    setStates(allFalseItems);
    setModal(false);
  };

  const handleVideoPlay = (index) => {
    setStates((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Section className={modal && "active"}>
      <Overlay onClick={() => handleModalClose()}  />
      <Modal>
        <CloseIcon onClick={() => handleModalClose()}>
          <img src={closeBtn} alt="" />
        </CloseIcon>
        <Container>
          <Slider ref={slickSliderRef} {...settings} className="slider-2">
            {assets?.map((obj,indx) =>{
               if (obj.media_type === "image") {
                return (
                  <Item>
                    <img src={obj?.image} alt="" />
                  </Item>
                );
              } else {
                return (
                  <Item className="video" onClick={()=>handleVideoPlay(indx)}> 
                    <ReactPlayer
                    url={obj?.media_type === 'url' ? obj.url : obj.video}
                    playing={states[indx]}
                    playsinline
                    controls
                    height={"400px"}
                    />
                  </Item>
                );
              }
            })}
          </Slider>
        </Container>
      </Modal>
    </Section>
  );
}

export default ImagePopUp;

const Section = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: 11;

  &.active {
    visibility: visible;
  }
`;

const Modal = styled.div`
  width: 75%;
  max-width: 800px;
  background-color: #35353585;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 20px;
  z-index: 11;
  max-height: 670px;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  @media (max-width: 850px) {
    height: 80%;
  }

  @media (max-width: 625px) {
    width: 90vw;
    justify-content: center;
  }

  @media (max-width: 580px) {
    height: 65%;
    max-height: 480px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* overflow: hidden; */
  width: 100%;
  height: 90%;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 25px;

  .slider-2 {
    width: 100%;
    height: 100%;
  }

  .slick-list {
    height: 95%;
  }

  .slick-next {
    top: calc(100% + 20px) !important;
    right: 35px !important;
    display: block !important;
  }
  .slick-prev {
    top: calc(100% + 20px) !important;
    left: 25px !important;
    display: block !important;
  }

  .slick-next:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowRight}) !important;
    background-size: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 50px;
    width: 60px !important;
  }
  .slick-prev:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowLeft}) !important;
    background-size: contain;
    background-repeat: no-repeat;
    height: 50px;
    width: 60px !important;
  }

  @media(max-width:580px){
    .slick-next {
    right: 25% !important;
  }
  .slick-prev {
    left: 25% !important;
  }
  }

`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: #000000c7;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
`;

const CloseIcon = styled.span`
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;

  img {
    width: 18px;
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;

  div{
    /* height: 100% !important; */
    width: 100% !important;
  }

  img{
    width: 100%;
    object-fit: cover;
    display: block;
    max-height: 450px;
  }
  video{
    width: 100%;
    object-fit: cover;
    display: block;
     /* max-height: 450px; */
  }

  &::after{
    content: '';
    position: absolute;
    inset: 0;
  }

  @media(max-width:580px){
    height: 450px;

    img{
      height: 100%;
    }
  }
`;
