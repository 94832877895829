import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { CarlSliderSkeleton, CarlSliderSkeleton1, eventSliderData } from "../../../../../assets/data/dummyData";
import ImagePreviewModal from "../../../modal/ImagePreviewModal";
import playIcon from '../../../../../assets/images/blogInner/icons/playBtn.svg'

function CardSlider({assetsData,CardId}) {
  const [hovered, sethovered] = useState(false);
  const [clicked, setclicked] = useState(false);
  const [clickedCard, setclickedCard] = useState(false);
  const [status, setstatus] = useState(false);
  const [animateCard, setanimateCard] = useState(false);
  const [selectedImg, setselectedImg] = useState('');
  const [items, setitems] = useState([]);
  const [assets, setassets] = useState([]);
  const [modal, setmodal] = useState(false);
  
  let id = null;
  const scrollConatiner = useRef();

  // Memoize the assetsData prop
  const memoizedAssetsData = useMemo(() => assetsData, [assetsData]);

  // assetsData after removing the preview imgs
  let newAssetsData = assets.filter((item)=> !item?.show)

  useEffect(() => {
    let data = []
    if(assetsData?.length === 1){
      data = CarlSliderSkeleton.concat(assetsData)
    }

    if(assetsData?.length === 2){
      data = CarlSliderSkeleton1.concat(assetsData)
    }
    
    // setitems(assetsData)
    // setassets(assetsData)
    // setclicked(false);
    
    if(data.length === 0){
       setitems(assetsData)
       setassets(assetsData)
       setclicked(false);
    }else{
      setitems(data)
      setassets(data)
      setclicked(false);
    }
    
    // setitems(data)
    // setassets(data)
    // setclicked(false);
    
  },[memoizedAssetsData])


  useEffect(() => {
    if(assets?.length > 0){
      if(!modal){
        setitems(assets)
        setclicked(false)
      }
    }
  },[modal])

  const setAnimation = ()=>{
      setstatus(true);

      if(id){
        clearTimeout(id)
      }
      id = setTimeout(() => {
        setstatus(false)
      },1000);
  }
  
  // this fucntion helps to track the card click . each card have diffrent action 
  // only image preview is show when the clicked is true
  const handleCardClick = (indx,type='Card') => {
    if(type === "Last-Card"){
      setclicked(true)
    }

    if(items?.length !== assets?.length){
        const indexOfseclectedCard = assets?.findIndex((obj)=>obj.id === indx)
        const indexOfseclectedCardInItems = items.findIndex((obj)=>obj.id === indx)
        const indexOfPrevseclectedCardInItems = assets?.findIndex((obj)=>obj.id === clickedCard)
        setselectedImg(assets[indexOfseclectedCard])
        const temp = JSON.parse(JSON.stringify(items));
        temp.splice(indexOfseclectedCardInItems,1,assets[indexOfPrevseclectedCardInItems]);
        setitems(temp)
        
    }else{
        const indexOfseclectedCard = assets?.findIndex((obj)=>obj.id === indx)
        setselectedImg(assets[indexOfseclectedCard])
        const temp = JSON.parse(JSON.stringify(assets));
        temp.splice(indexOfseclectedCard,1)
        setitems(temp)
    }
    if(clickedCard){
      setAnimation()
    }
    setclickedCard(indx)
  }
  
  
  return (
    <Section className={`${clicked && 'active'}`}>
      <CardSliderConatiner>
        <DisplayArea className={`${clicked && "show"} ${(selectedImg.type === "video" || selectedImg.type === "url") && "video"}`} onClick={()=>setmodal(true)}>
          <img src={selectedImg?.image} className={`${clicked && status && "scale-animation"}`}  alt="" />
        </DisplayArea>
        <CardsContainer className={clicked && "clicked-style"} ref={scrollConatiner}>
          {items?.map((item, indx) => {
            if (indx === assets?.length - 2){
              return (
                <Card
                  id={`card-${CardId}-${indx}`}
                  key={indx}
                  className={`${!clicked && hovered && "hovered-second-last"} ${
                    clicked ? "clicked" : "second-last"
                  }
                  ${item?.show && 'hide' }
                  `}
                  indx={indx}
                  hovered={ clicked ? true : false}
                  onClick={() => clicked && handleCardClick(item?.id)}
                >
                  <img src={item?.image} alt="" />
                </Card>
              );
            } else if (indx === assets?.length - 3) {
              return (
                <Card
                  id={`card-${CardId}-${indx}`}
                  key={indx}
                  className={` ${!clicked && hovered && "hovered-third-last"} ${
                    clicked ? "clicked" : "third-last"
                  }
                  ${item?.show && 'hide' }
                  `}
                  hovered={ clicked ? true : false}
                  onClick={() => clicked && handleCardClick(item?.id)}
                >
                  <img src={item?.image} alt="" />
                </Card>
              );
            } else if (indx === assets?.length - 1) {
              return (
                <Card
                  id={`card-${CardId}-${indx}`}
                  key={indx}
                  className={`last ${clicked && "clicked"} ${item?.show && 'hide' }`}
                  onMouseOver={() => sethovered(true)}
                  onMouseLeave={() => sethovered(false)}
                  onClick={() => handleCardClick(item?.id,'Last-Card')}
                  hovered={ clicked ? true : false}
                  >
                  <img src={item?.image} alt="" />
                </Card>
              );
            } else {
              return (
                <Card
                  id={`card-${CardId}-${indx}`}
                  key={indx}
                  indx={indx}
                  className={`${clicked && "clicked"} ${animateCard === item.id && 'animate-card'} ${item?.show && 'hide' } `}
                  hovered={ clicked ? true : false}
                  onClick={() => clicked && handleCardClick(item?.id)}
                >
                  <img src={item?.image} alt="" />
                </Card>
              );
            }
          })}
        </CardsContainer>
        <ImagePreviewModal modal={modal} setModal={setmodal} assets={newAssetsData?.length === assets ? newAssetsData : newAssetsData?.reverse()}  current={selectedImg?.id}/>
      </CardSliderConatiner>
    </Section>
  );

}

export default CardSlider;

const Section = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;

  &.active{
    height: 600px;
  }

  @media(max-width:580px){
    height: 350px;
    &.active{
    height: 500px;
  }
  }
  @media(max-width:400px){
    height: 350px;
    &.active{
    height: 400px;
  }
  }
`;

const CardSliderConatiner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  max-height: 600px;
  gap:25px;
`;

const DisplayArea = styled.div`
  width: 100%;
  max-height: 350px;
  visibility: hidden;
  height: 0%;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  
  &.show {
    visibility: visible;
    height: 85%;
    flex: 2;
    animation: fade .3s;
  }

  &.video{
    &::after{
      content: '';
      position: absolute;
      inset: 0;
      background-image: url(${playIcon});
      background-size: 60px;
      background-repeat: no-repeat;
      background-position:center;
    }
  }


  img{
    width: 100%;
    height: 100%;
    
    &.scale-animation{
    animation: scale-animi .5s;
    }
    
  }

  @keyframes scale-animi {
      0% { transform: scale(0);
      }
      100% {transform: scale(1);
      }      
  }
  
  @keyframes fade {
    from { opacity:.1;
      transform: scale(.8);
    }
    50% { opacity:.5}
    to{ opacity:1;
      transform: scale(1);
    }
  }

  @media(max-width:580px){
    max-height: 300px;
  }
  @media(max-width:430px){
    max-height: 250px;
  }
`;

const CardsContainer = styled.div`
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  position: relative;
  height: 100%;

  &:hover{
    &::-webkit-scrollbar {
    height: 7px;
    }
  }

  &::-webkit-scrollbar {
    width:1px;
    height: 0px;
  }
  &::-webkit-scrollbar-track{
    background-color: #fff8f8;
    width:3px
  }
  &::-webkit-scrollbar-thumb{
    background-color: #ffd3b3dc;
    width:10px
  }

  &.clicked-style {
    display: flex;
    align-items: flex-end;
    height: 20%;
    gap:15px;
    overflow-x: scroll;
    overflow-y: visible;
    scroll-behavior: smooth;
    padding-bottom: 10px;
  }

  &.flx-rev{
    flex-direction:row-reverse;
  }
`;

const Card = styled.div`
  position: absolute;
  transition: all 0.2s linear;
  border-radius: 10px;
  overflow: hidden;
  
  img {
    width: 100%;
    max-width: 500px;
  }

  &.last {
    cursor: pointer;
  }

  &.second-last {
    transform: translate(25px, -20px);
  }

  &.third-last {
    transform: translate(-25px, 20px);
  }

  &.hovered-second-last {
    transform: translate(35px, -30px);
  }

  &.hovered-third-last {
    transform: translate(-35px, 30px);
  }

  &:hover{
    ${(props)=>{
      if(props.hovered){
          return`
          transform:translateY(-17px);
          `
      }else{
        return '';
      }
    }}
  }

  &.clicked {
    min-width: 80px;
    max-width: 120px;
    position: unset;
    display: block;
    animation: clicked-animation 0.2s;
    cursor: pointer;
    z-index:4;
    height:55px;
  }

  &.clicked{
    &.hide{
      display: none;
    }
  }

  @media(max-width:600px){
    &.second-last {
    transform: translate(15px, -10px);
    }

  &.third-last {
    transform: translate(-15px, 10px);
  }
  }

   @media(max-width:400px){
    &.second-last {
    transform: translate(10px, -5px);
  }

  &.third-last {
    transform: translate(-10px, 15px);
  }
  }


  @keyframes clicked-animation {
    from {
      transform: translate(-60px, -120px);
    }
    to {
      transform: translate(0px, 0px);
    }
  }
`;
