import React from 'react'
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import styled from 'styled-components';
import BreadCrumb from '../includes/blogInner/BreadCrumb';
import BlogDetail from '../includes/blogInner/BlogDetail';

function BlogInner() {
  return (
    <>
    <Header/>
    <Section>
       <BreadCrumb/>
       <BlogDetail/>
    </Section>
    <Footer/>
    </>
  )
}

export default BlogInner;

const Section = styled.div`
      display: flex;
      flex-direction: column;
      gap: 100px;
      background-color:#F8F8F8;

      @media(max-width:968px){
        gap:64px;
      }
`;