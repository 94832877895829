import React, { createContext, useState } from 'react'
export const CustomContext = createContext();

function StoreContext({children}) {
  const [active, setactive] = useState(false);

  return (
    <CustomContext.Provider value={{active,setactive}}>
      {children}
    </CustomContext.Provider>
  )
}

export default StoreContext;