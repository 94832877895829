import React, { useEffect, useState } from "react";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import styled from "styled-components";
import Banner from "../includes/apartments/Banner";
import ApartmentList from "../includes/apartments/ApartmentList";
import ApartmentsDetails from "../includes/apartments/ApartmentsDetails";
import { accountsConfig } from "../../axiosConfig";
import Helmet from "../includes/Helmet";
import ApprtmentAbout from "../includes/apartments/ApprtmentAbout";

function Apartments() {
  const [aprtmentList, setAprtmentList] = useState();
  const [datas, setdatas] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    accountsConfig
      .get("projects/list-type-project/", {
        params: {
          project_type: "apartment",
        },
      })
      .then((res) => {
        const data = res.data.app_data.data;
        if (res.data.app_data.StatusCode == 6000) {
          setAprtmentList(data);
        } else {
          setAprtmentList([]);
        }
      });
  }, []);
  
  useEffect(() => {
    accountsConfig.get('web/list-link-seo/',{
      params:{
        links:window.location.pathname
      }
    })
    .then((res) => {
     const { data , StatusCode } = res.data.app_data;
       if(StatusCode === 6000){
          setdatas(data[0])
       }else{
        setdatas({})
       }
    })
  },[])

  return (
    <>
      <Helmet titleData={datas?.meta_title} descriptionData={datas?.meta_description} />
      <Header />
      <Section>
        <Banner />
        <ApprtmentAbout/>
        <ApartmentList aprtmentList={aprtmentList} />
        <ApartmentsDetails />
      </Section>
      <Footer />
    </>
  );
}

export default Apartments;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
