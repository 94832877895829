import React, { useEffect, useState} from "react";
import styled from "styled-components";
import BreadCrumb from "../includes/contact/BreadCrumb";
import ContactInfo from "../includes/contact/ContactInfo";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import { accountsConfig } from "../../axiosConfig";
import Helmet from "../includes/Helmet";


function Contact() {
  const [datas, setdatas] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  
  useEffect(() => {
    accountsConfig.get('web/list-link-seo/',{
      params:{
        links:'/contact'
      }
    })
    .then((res) => {
     const { data , StatusCode } = res.data.app_data;
       if(StatusCode === 6000){
          setdatas(data[0])
       }else{
        setdatas({})
       }
    })
  },[])


  return (
    <Main>
      <Helmet titleData={datas?.meta_title} descriptionData={datas?.meta_description} />
      <Header />
      <Section>
        {/* <BreadCrumb /> */}
        <ContactInfo />
      </Section>
      <Footer />
    </Main>
  );
}

export default Contact;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 130px;
  background: #f8f8f8;

  @media(max-width:580px){
    gap:65px;
  }
`;
