import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

//imports
import leftQuote from "../../../assets/images/testimonials/qoute.png";
import leftImage from "../../../assets/images/testimonials/image36.png";
import qouteimage from "../../../assets/images/testimonials/Vector.png";
import { accountsConfig } from "../../../axiosConfig";
import ReactPlayer from "react-player";
import { Tooltip } from "react-tooltip";
import thumbnail from "../../../assets/images/testimonials/thumbnail.png";
import playIcon from "../../../assets/images/blogInner/icons/playBtn.svg";
import arrowLeft from "../../../assets/images/apartments/gallery/newDesign/icons/leftArr.svg";
import arrowRight from "../../../assets/images/apartments/gallery/newDesign/icons/rightarr.svg";

export default function UpdatedTestimonial() {
  const [States, setStates] = useState([]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    afterChange: (currentSlide) => {
      const allFalseItems = Object.fromEntries(
        Object.keys(States).map((key) => [key, false])
      );
      setStates(allFalseItems);
    },
    responsive: [
      {
        breakpoint: 500,
      },
    ],
  };
  //list testimonials
  const [listTestimonials, setListTestimonials] = useState([]);

  const playerConfig = {
    youtube: {
      playerVars: {
        controls: 0, // Hide controls
      },
    },
  };

  useEffect(() => {
    accountsConfig.get("web/list-testimonials/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode === 6000) {
        setListTestimonials(data);

        // setting the playing states of all object
        data?.forEach((obj, ind) => {
          setStates((prev) => ({ ...prev, [ind]: false }));
        });
      } else {
        setListTestimonials([]);
      }
    });
  }, []);

  const handlePlaying = (index) => {
    setStates((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Container>
      <Wrapper>
        <Content>
          <LeftImage>
            <img src={leftImage} alt="image" />
          </LeftImage>
          <CoverTest>
            <MainTopCover>
              <CoverTitles>
                <SubTitle>TESTIMONIALS</SubTitle>
                <Line></Line>
                <Title> Words from Our Delighted Homeowners</Title>
              </CoverTitles>
              <QouteImage>
                <img src={qouteimage} alt="" />
              </QouteImage>
            </MainTopCover>
            <TestimonialSection>
              <Slider {...settings} className="testimonial-slider">
                {listTestimonials?.map((item, index) =>
                  item?.media_type === "image" ? (
                    <Card className="text">
                      <ImageContainer>
                        <img src={item.image} alt="image" />
                      </ImageContainer>

                      <DetailSection className="section">
                        <CoverName>
                          <CoverNameText>
                            <Name>{item.name}</Name>
                            <CoverSpan>
                              <SpanText>{item.designation}</SpanText>
                              <SpanText> {item.location}</SpanText>
                            </CoverSpan>
                          </CoverNameText>
                        </CoverName>
                        {/* <LeftQuate>
                          <img src={leftQuote} alt="image" />
                        </LeftQuate> */}
                        <CoverDescription>
                          <Description
                            data-tooltip-id="capture"
                            data-tooltip-place="top"
                            // data-tooltip-content={item.description}
                            data-tooltip-html={`<p>${item.description}</p>`}
                          >
                            {item.description.length > 300
                              ? item.description.slice(0, 300) + "..."
                              : item.description}
                          </Description>
                          <DescriptionMob
                            data-tooltip-id="capture"
                            // data-tooltip-content={item.description}
                            data-tooltip-place="top"
                            data-tooltip-html={`<p>${item.description}</p>`}
                          >
                            {item.description.length > 150
                              ? item.description.slice(0, 150) + "..."
                              : item.description}
                          </DescriptionMob>
                        </CoverDescription>
                        <Tooltip
                          place="top"
                          id="capture"
                          className="tooltip-description"
                        />
                      </DetailSection>
                    </Card>
                  ) : (
                    <Card className="videoslide">
                      <Controls onClick={() => handlePlaying(index)}></Controls>
                      <ReactPlayer
                        url={item?.video ? item?.video : item?.url}
                        playsinline
                        width={1000}
                        config={playerConfig}
                        playing={States[index]}
                      />
                      <Play className="video">
                        <SpanText className="video">{item.name} </SpanText>
                        <SpanText className="video"> {item.location}</SpanText>
                      </Play>
                    </Card>
                  )
                )}
              </Slider>
            </TestimonialSection>
          </CoverTest>
        </Content>
      </Wrapper>
    </Container>
  );
}
const Container = styled.div`
  padding: 0px 0 150px 0;
  background-color: #fff;
  margin-top: 40px;
`;
const Wrapper = styled.div`
  width: 100% !important;
`;
const CoverDescription = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 580px) {
    .tooltip-description {
      width: 80% !important;
    }
  }
`;
const Content = styled.div`
  display: flex;
  gap: 50px;
`;
const QouteImage = styled.div`
  margin-top: 80px;
  width: 80px;
  @media (max-width: 1300px) {
    display: none;
  }
`;
const Card = styled.div`
  display: flex !important;
  width: 98% !important;
  gap: 30px;
  height: 325px;
  &.text {
    justify-content: center;
    position: relative;
  }
  position: relative;
  padding: 40px;
  box-sizing: border-box;
  &.videoslide {
    width: 90% !important;
    margin: 0 auto;
    padding: 20px;
    height: 100%;
  }

  @media all and (max-width: 1440px) {
    align-items: center;
  }

  @media (min-width: 580px) {
    video {
      object-fit: contain !important;
    }
  }

  @media (max-width: 1300px) {
    &.text {
      flex-direction: column;
      background-color: #2b2a29;
      gap: 10px;
      width: 88% !important;
      margin: 0 auto;
      padding: 20px 10px;
    }
  }

  @media (max-width: 850px) {
    &.text {
      width: 95% !important;
    }
  }

  @media all and (max-width: 768px) {
    &.text {
      flex-direction: column;
      height: unset;
    }
  }

  @media (max-width: 580px) {
    height: 450px !important;
    &.videoslide {
      width: 95% !important;
      margin: 0 auto;
      padding: 0;
      background-color: #2b2a29;

      div:not(.video){
        height: 100% !important;
      }
    }
  }
`;
const TestimonialSection = styled.div`
  width: 85%;
  margin: 0 auto;
  margin-top: 50px;

  /* .slick-next {
    right: 20px !important;
  }
  @media (max-width: 1300px) {
    .slick-prev {
      left: -50px !important;
    }
  }
  @media (max-width: 850px) {
    .slick-prev {
      top: calc(100% + 25px) !important;
      left: 50px !important;
    }
    .slick-next {
      top: calc(100% + 25px) !important;
      right: 115px !important;
    }

    .slick-prev::before {
      width: 40px !important;
    }

    .slick-next::before {
      width: 40px !important;
    }
  } */

   .slick-next {
    top: calc(100% + 25px) !important;
    right: 38% !important;
    display: block !important;
  }
  .slick-prev {
    top: calc(100% + 25px) !important;
    left: 35% !important;
    display: block !important;
  }

  .slick-next:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowRight}) !important;
    background-size: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 70px !important;
  }
  .slick-prev:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowLeft}) !important;
    background-size: contain;
    background-repeat: no-repeat;
    height: 60px;
    width: 70px !important;
  }

  @media (max-width: 580px) {
  .slick-next {
    right: calc(15% + 50px) !important;
   }
  .slick-prev {
    left: calc(15%) !important;
  }
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Play = styled.div`
  background: linear-gradient(90deg, #eb6502 0%, rgba(255, 164, 102, 0.6) 100%);
  position: absolute;
  bottom: 30%;
  padding: 10px 20px;

  @media (max-width: 580px) {
    bottom: 12%;
    width: 60%;
    margin: 0 auto;
  }
`;
const SubTitle = styled.h3`
  font-family: "gellix-light";
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 20px;
`;
const Line = styled.div`
  background-color: var(--orange);
  width: 60px;
  height: 1px;
  margin: ${({ type }) => (type == "str" ? "" : "0 auto")};
`;
const Title = styled.h2`
  font-size: 32px;
  font-family: "gellix-medium";
  color: var(--orange);
  text-align: center;
  margin: 20px 0;
  text-transform: uppercase;
  @media (max-width: 480px) {
    font-size: 20px;
    letter-spacing: 2px;
  }
`;

const Qoute = styled.div`
  width: 40px;
  @media (max-width: 1650px) {
    width: 30px;
  }
  @media (max-width: 480px) {
    width: 30px;
  }
`;
const DetailSection = styled.div`
  /* width: 35%; */
  background-color: #2b2a29;
  padding: 80px 25px 0px 25px;
  height: 100%;
  width: 100%;

  @media (max-width: 1300px) {
    &.section {
      padding: 0;
    }
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Description = styled.p`
  color: #fff;
  line-height: 28px;
  width: 85%;
  text-align: center;
  @media (max-width: 1650px) {
    font-size: 14px;
  }
  @media (max-width: 580px) {
    display: none;
  }
`;
const DescriptionMob = styled.p`
  display: none;
  @media (max-width: 1650px) {
    font-size: 14px;
  }
  @media (max-width: 580px) {
    font-size: 14px;
    color: #fff;
    display: block;
    line-height: 22px;
    text-align: center;
  }
  @media (max-width: 400px) {
    font-size: 13px;
    text-align: justify;
  }
`;
const CoverName = styled.div`
  display: flex;
  justify-content: center;
`;
const Name = styled.h2`
  font-family: "gellix-bold";
  color: var(--orange);
  font-size: 21px;
  @media (max-width: 480px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;
const ImageContainer = styled.div`
  width: 25%;
  position: absolute;
  left: 10px;
  top: -0px;

  @media (max-width: 1300px) {
    position: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const CoverNameText = styled.div``;
const CoverSpan = styled.div`
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
const SpanText = styled.div`
  color: #c9c9c9;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &.video {
    color: #fff;
  }
`;

const LeftImage = styled.div`
  width: 30%;

  @media (max-width: 1300px) {
    display: none;
  }

  img {
    display: block;
    height: 100%;
  }
`;
const CoverTest = styled.div`
  width: 65%;
  margin-top: 40px;

  @media (max-width: 1300px) {
    width: 95%;
    margin:  0 auto;
    margin-top: 40px;
  }
  
  @media (max-width: 580px) {
    margin-top: 20px;
  }
`;
const MainTopCover = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1300px) {
    justify-content: center;
  }
`;
const CoverTitles = styled.div``;
const LeftQuate = styled.div``;

const Controls = styled.div`
  position: absolute;
  inset: 0;
  z-index: 25;
  width: 100%;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &.show {
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background-image: url(${playIcon});
      background-size: 60px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;
