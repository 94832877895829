import React, { useEffect, useState } from "react";
import Header from "../Header";
import Banner from "./includes/Banner";
import styled from "styled-components";
import Footer from "../Footer";
import Gallery from "./includes/Gallery";
import { accountsConfig } from "../../../axiosConfig";
import NewGallery from "./includes/NewGallery";
import Helmet from "../Helmet";

export default function Events() {
  const [datas, setdatas] = useState({});
  
  useEffect(() => {
    accountsConfig.get('web/list-link-seo/',{
      params:{
        links:window.location.pathname
      }
    })
    .then((res) => {
     const { data , StatusCode } = res.data.app_data;
       if(StatusCode === 6000){
          setdatas(data[0])
       }else{
        setdatas({})
       }
    })
  },[])

  return (
    <Container>
      <Helmet titleData={datas?.meta_title} descriptionData={datas?.meta_description} />
      <Header />
      <Banner />
      {/* <Gallery listEvents={listEvents} /> */}
      <NewGallery/>
      <Footer />
    </Container>
  );
}

const Container = styled.div``;
