import React from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import Helmet from '../includes/Helmet'
import HomeCareServicesEnquiry from '../includes/homecareservices/HomeCareServicesEnquiry'

function HomeCareServices() {
    return (
        <>
            <Helmet titleData={"Home Care Services | Classic homes"} descriptionData={"HomeCareServices"} />
            <Header />
            <HomeCareServicesEnquiry/>
            <Footer />
        </>
    )
}

export default HomeCareServices