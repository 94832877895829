import React from "react";
import styled from "styled-components";
import img4 from "../../../assets/images/about/img-4.png";

function FounderInfo() {
  return (
    <Section>
      <Wrapper className="wrapper">
        <Left>
          <Subtitle>BEST BUILDERS IN KOCHI</Subtitle>
          <Line />
          <Title>
            <Classic>FOUNDER</Classic>& PARTNER
          </Title>
          <ParaConatiner>
            <Para>
              Classic Homes represents a 28-year legacy of crafting exceptional
              living spaces that have made us one of the best builders in Kochi.
              Our journey goes beyond just bricks and mortar; it's a narrative
              of creating homes that resonate with life's most cherished
              moments. From picturesque villas to contemporary apartments in
              Kochi, each creation is a testament to our unwavering commitment
              to exquisite design and unparalleled quality.
            </Para>
            <Para>
              Leading this enterprise is Er. K.T Mathew, one of the top
              real-estate developers in Ernakulam. He is central to Classic
              Homes' journey, a visionary industry expert whose strategic vision
              has forged lasting ties with multinational companies.
            </Para>
            <Para>
              As the State President of GRACE (Graduate Civil Engineers)
              Association, Er. Mathew's passion has been instrumental in shaping
              our journey. His leadership has steered Classic Homes, infusing it
              with innovation and a commitment to ethical business practices
            </Para>
            <Para>
              Together with his partners he has elevated Classic Homes to one of
              Kochi's top builders, specializing in spectacular villas and
              apartment projects. His grand vision and dedication have
              transformed the real estate landscape, earning him recognition as
              a licensed realtor (K-RERA/AG/0029/2020) contributing to the
              city's finest living spaces.
            </Para>
          </ParaConatiner>
        </Left>
        <Right>
          <img src={img4} alt="" />
        </Right>
      </Wrapper>
    </Section>
  );
}

export default FounderInfo;

const Section = styled.div`
  margin-bottom: 130px;
`;

const Wrapper = styled.div`
      display: flex;
      gap:25px;

      @media(max-width:1200px){
        flex-direction: column-reverse;
        gap:40px;
      }
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 25px;

  @media(max-width: 768px){
      justify-content: center;
      align-items: center;
  }
`;
const Subtitle = styled.div`
  color: #606060;

  /* Para reg 16 caps */
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
`;
const Line = styled.div`
  height: 0.5px;
  width: 50px;
  background-color: #eb6502;
`;
const Title = styled.div`
  display: flex;
  color: #eb6502;
  font-family: "gellix-regular";
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 3.75px;
  flex-direction: column;

  @media(max-width:1200px){
      flex-direction: row;
      gap:10px;
  }

  @media(max-width: 875px){
        font-size: 50px;
  }
  @media(max-width: 685px){
        font-size: 40px;
  }
  @media(max-width: 580px){
        font-size: 65px;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap:0;
  }


`;
const Classic = styled.div`
  color: #606060;
`;
const ParaConatiner = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-top: 20px;
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    display: block;

    @media(max-width:1200px){
      width: 70%;
      margin: 0 auto;
    }
  }
`;
const Para = styled.div`
  color: #706e6c;
  font-family: "gellix-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */

  @media(max-width:1200px){
      text-align: justify;
  }
`;
