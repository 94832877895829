import React from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import Header from "../includes/Header";
import pageNotFound from "../../assets/images/404/404.svg";
import arrow from "../../assets/images/404/arrow.svg";
import Footer from "../includes/Footer";

function PageNotFound() {
  const navigate = useNavigate();

  const handleRedirect = ()=>{
        navigate('/')
  }

  return (
    <Section>
      <Header />
      <Wrapper className="wrapper">
        <Container>
          <Img>
            <img src={pageNotFound} alt="" />
          </Img>
          <Info>
            <Text>
              Oops, We could not find <br />
              the page you are looking for.
            </Text>
            <Para>
              The page you are looking for might have been removed, had its name
              changed, or is temporarily unavailable.
            </Para>
          </Info>
          <Button onClick={()=>handleRedirect()}>
            <img src={arrow} alt="" /> Back to Home Page
          </Button>
        </Container>
      </Wrapper>
      <Footer />
    </Section>
  );
}

export default PageNotFound;

const Section = styled.section``;

const Wrapper = styled.div``;

const Container = styled.div`
  width: 80%;
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  gap: 45px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 580px) {
      width:100%
  }
`;

const Img = styled.div`
  img {
    width: 90%;
    object-fit: contain;
  }
`;

const Info = styled.div``;

const Text = styled.h1`
  color: #28464d;
  font-style: normal;
  font-weight: 800;
  font-family: "gellix-bold";
  font-size: 2.5rem;
  text-align: center;

  @media screen and (max-width: 768px) {
     font-size: 2rem;
  }

  @media screen and (max-width: 580px) {
     font-size: 1.5rem;
  }
`;

const Para = styled.p`
  color: #436972;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;

  @media screen and (max-width:580px) {
       font-size: 1rem;
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  display: flex;
  background-color: #eb6502;
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-family: "gellix-bold";
  /* width: 250px; */
  gap: 15px;
  align-items: center;
  cursor: pointer;

  img {
    width: 25px;
  }
`;
