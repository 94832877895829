import React, { useState } from 'react'
import styled from 'styled-components';
import closeBtn from "../../../assets/images/icons/close-icon.svg";
import { ClipLoader } from 'react-spinners';

function RentalsEnqiry() {
  const [isModal, setModal] = useState(false)
  const [loading, setloading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target
    // setformData((prev)=>{
    //   return {
    //     ...prev,
    //     [name] : value
    //   }
    // })
  }
  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <CloseIcon onClick={() => setModal(false)}>
          <img src={closeBtn} alt="" />
        </CloseIcon>
        <Heading>
          REQUEST A CALL BACK
        </Heading>
        <Form >
          <FormItem>
            <Name>
              Your Name <span>*</span>
            </Name>
            <TextInput
              name="name"
              type="text"
              required={true}
              onChange={(e) => handleChange(e)}
            />
          </FormItem>

          <FormItem>
            <Name>
              Number <span>*</span>
            </Name>
            <TextInput
              name="phone"
              type="number"
              onChange={(e) => handleChange(e)}
              required
            />
          </FormItem>
          <FormItem>
            <Name>
            Flat or Villa
            </Name>
            <TextInput
              name="email"
              type="text"
              onChange={(e) => handleChange(e)}
            />
          </FormItem>
          <FormItem>
            <Name>
            Year of property Purchase 
            </Name>
            <TextInput
              name="email"
              type="text"
              onChange={(e) => handleChange(e)}
            />
          </FormItem>
          <FormItem className="fw">
            <Name>
              Message
            </Name>
            <TextAreaInput
              name="message"
              type="text"
              required
              rows={2}
              onChange={(e) => handleChange(e)}
            />
          </FormItem>

          <SubmitBtn>
            {loading ? <ClipLoader
              color='#ffffff'
              loading={loading}
              // cssOverride={override}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            /> : "Send Message"}
          </SubmitBtn>

        </Form>
      </Modal>
    </Container>
  )
}

export default RentalsEnqiry

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background:var(--lightgrey);
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  background-color: #ffffff;
  &::-webkit-scrollbar {
    display: none;
  }
  /* @media all and (max-width: 1380px) {
    left: 58%;
  } */
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 350px;
  }
  @media all and (max-width: 360px) {
    width: 330px;
  }
`;

const CloseIcon = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  img {
    width: 25px;
  }
`;

const Heading = styled.h1`
    color: #EB6502;
    font-family: 'gellix-regular';
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.25px;
    padding: 20px;
`;
const Form = styled.form`
    display: flex;
    /* width: 100%; */
    flex-wrap: wrap;
    gap:14px;
    justify-content: space-between;
    padding: 20px;
    @media(max-width:480px){
      padding: 30px;
    }
`;

const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    width:45%;
    gap: 10px;

    &.fw{
        width: 100%;
    }

    @media(max-width:580px){
      width: 100%;
    }
`;

const Name = styled.div`
    color: #706E6C;
    text-align: justify;
    font-family: 'gellix-regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    span{
      color: red;
    }
`;

const TextInput = styled.input`
  outline:none;
  border: none;
  border-bottom: 1px solid #606060;

  @media(max-width:580px){
    background-color: transparent;
  }
`;

const TextAreaInput = styled.textarea`
  outline:none;
  border: none;
  border-bottom: 1px solid #606060;
  resize: vertical;

  @media(max-width:580px){
    background-color: transparent;
  }
`;

const SubmitBtn = styled.button`
  padding: 16px 27px;
  background: #EB6502;
  outline: none;
  border: none;
  color: #FFF;
  font-family: 'gellix-regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  cursor: pointer;
`;