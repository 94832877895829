import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import imageIcon from '../../../assets/images/homecareservices/imagesicons.svg';
import videoIcon from '../../../assets/images/homecareservices/videoicon.svg';
import closeIcon from '../../../assets/images/homecareservices/close.svg';
import { accountsConfig } from '../../../axiosConfig';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';


function HomeCareServicesEnquiry() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ name: "", number: "", flat_or_villa: "", year_of_property: "", maintenance_requirment: "" });
  const [error, setError] = useState({ name: "", number: "", flat_or_villa: "", year_of_property: "", maintenance_requirment: "" });
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [uploadError, setUploadError] = useState("");
  const [apartmentsVillas, setApartmentsVillas] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value
    }));
    setError((prev) => ({
      ...prev,
      [name]: ""
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setData((prev) => ({
      ...prev,
      flat_or_villa: selectedOption ? selectedOption.value : ''
    }));
    setError((prev) => ({
      ...prev,
      flat_or_villa: ""
    }));
  };


  const handleFileUpload = (e) => {
    const { name, files } = e.target;
    const newFiles = Array.from(files);

    if (name === "images") {
      setImages((prev) => [...prev, ...newFiles]);
    } else if (name === "videos") {
      const validVideos = newFiles.filter(file => file.size <= 60 * 1024 * 1024);
      if (validVideos.length !== newFiles.length) {
        setUploadError("Some videos exceed the 60 MB limit and were not uploaded.");
      }
      setVideos((prev) => [...prev, ...validVideos]);
    }
  };

  const removeFile = (name, index) => {
    if (name === "images") {
      setImages((prev) => prev.filter((_, i) => i !== index));
    } else if (name === "videos") {
      setVideos((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation logic
    const newErrors = {};
    if (!data.name) newErrors.name = "Name is required";
    if (!data.number) newErrors.number = "Number is required";
    if (!data.flat_or_villa) newErrors.flat_or_villa = "Flat or Villa is required";
    if (!data.year_of_property) newErrors.year_of_property = "Year of property purchase is required";
    if (!data.maintenance_requirment) newErrors.maintenance_requirment = "Maintenance requirement is required";

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("number", data.number);
      formData.append("flat_or_villa", data.flat_or_villa);
      formData.append("year_of_property", data.year_of_property);
      formData.append("maintenance_requirment", data.maintenance_requirment);

      // Append images and videos to the formData
      images.forEach((image) => {
        formData.append("files", image);
      });

      videos.forEach((video) => {
        formData.append("files", video);
      });

      try {
        const res = await accountsConfig.post("web/home-care-service-enquiry/", formData);

        // Handle the response data if needed
        if (res.data.app_data.StatusCode === 6000) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1500,
            iconColor: "#0e2d5e",
          });
          navigate('/services')
          console.log("Form submitted successfully:", res.data);
        } else {
          // Handle failure
          console.log("Submission failed:", res.data);
        }
      } catch (error) {
        console.log("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  
  const fetchAppartmentAndvillas = async () => {
    try {
      let apartmentsData = [];
      let villasData = [];

      const res = await accountsConfig.get("projects/list-type-project/?project_type=apartment");
      if (res.data.app_data.StatusCode === 6000) {

        apartmentsData = res.data.app_data.data.map(appart => ({
          value: appart.name,
          label: appart.name
        }));
      }

      const res2 = await accountsConfig.get("projects/list-type-project/?project_type=villas");
      console.log(res2, 'sifandaxo');

      if (res2.data.app_data.StatusCode === 6000) {
        villasData = res2.data.app_data.data.map(villas => ({
          value: villas.name,
          label: villas.name
        }));
      }

      const combinedData = [...apartmentsData, ...villasData];

      setApartmentsVillas(combinedData);
    } catch (error) {
      console.error("Error fetching apartments and villas:", error);
    }
  };


  useEffect(() => {
    fetchAppartmentAndvillas()
  }, [])


  return (
    <Section>
      <Wrapper className="wrapper">
        <Container>
          <BoxContainer>
            <Box>
              <Title>
                <Classic>Home </Classic>Maintenance
              </Title>
              <Line />
            </Box>
          </BoxContainer>
          <FormContainer onSubmit={handleSubmit}>
            <Form>
              <FormItem>
                <Name>
                  Your Name <span>*</span>
                </Name>
                <TextInput
                  name="name"
                  type="text"
                  value={data?.name}
                  onChange={handleChange}
                />
                {error.name && <Error>{error.name}</Error>}
              </FormItem>
              <FormItem>
                <Name>
                  Mobile Number <span>*</span>
                </Name>
                <TextInput
                  name="number"
                  type="number"
                  value={data?.number}
                  onChange={handleChange}
                />
                {error.number && <Error>{error.number}</Error>}
              </FormItem>
              <FormItem>
                <Name>
                  Flat or Villa <span>*</span>
                </Name>
                {/* <TextInput
                  name="flat_or_villa"
                  type="text"
                  value={data?.flat_or_villa}
                  onChange={handleChange}
                /> */}
                <Select
                  onChange={handleSelectChange}
                  options={apartmentsVillas}
                  placeholder="Flat or Villa"
                  isSearchable
                  name="flat_or_villa"
                />

                {error.flat_or_villa && <Error>{error.flat_or_villa}</Error>}
              </FormItem>
              <FormItem>
                <Name>
                  Year of property Purchase <span>*</span>
                </Name>
                <TextInput
                  name="year_of_property"
                  type="number"
                  value={data?.year_of_property}
                  onChange={handleChange}
                />
                {error.year_of_property && <Error>{error.year_of_property}</Error>}
              </FormItem>
              <FormItem className="fw">
                <Name>
                  Maintenance requirement <span>*</span>
                </Name>
                <TextAreaInput
                  name="maintenance_requirment"
                  value={data?.maintenance_requirment}
                  rows={2}
                  onChange={handleChange}
                />
                {error.maintenance_requirment && <Error>{error.maintenance_requirment}</Error>}
              </FormItem>
              <FormItem>
                <Name>
                  Upload Images
                </Name>
                <TextInput
                  name="images"
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileUpload}
                />
              </FormItem>
              <FormItem>
                <Name>
                  Upload Videos
                </Name>
                <TextInput
                  name="videos"
                  accept="video/*"
                  type="file"
                  onChange={handleFileUpload}
                />
                {uploadError && <Error>{uploadError}</Error>}
              </FormItem>
            </Form>
            <RightSide>
              <SubTitle>Images <span>& Videos</span></SubTitle>
              <Line className='images' />
              <ImagesContainer>
                {images.map((image, index) => (
                  <div key={index} className='list-image'>
                    <img src={URL.createObjectURL(image)} alt={image.name} className="preview" />                    <p>{image.name}</p>
                    <img src={closeIcon} alt="remove" onClick={() => removeFile('images', index)} />
                  </div>
                ))}
                {videos.map((video, index) => (
                  <div key={index} className='list-image'>
                    <img src={videoIcon} alt="" />
                    <p>{video.name}</p>
                    <img src={closeIcon} alt="remove" onClick={() => removeFile('videos', index)} />
                  </div>
                ))}
              </ImagesContainer>
              <SubmitBtn type='submit'>
                {loading ? <ClipLoader
                  color='#ffffff'
                  loading={loading}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                /> : "Send Request"}
              </SubmitBtn>
            </RightSide>
          </FormContainer>
        </Container>
      </Wrapper>
    </Section>
  )
}

export default HomeCareServicesEnquiry;


// Styles remain the same as in your code

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 130px;
  margin-top: 60px;

  @media (max-width: 900px) {
    row-gap: 64px;
  }

  @media screen and (max-width:580px) {
     margin-top: 10px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 130px;

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: 900px) {
    row-gap: 64px;
  }
  @media (max-width: 580px) {
    gap: 10px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 130px;
  column-gap: 40px;

  &.service-container {
    column-gap: 60px;
  }

  @media (max-width: 580px) {
    width: 80%;
    margin: 0 auto;

    &.service-container {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    row-gap: 64px;
  }

  @media (max-width: 1200px) {
    column-gap: 100px !important;
  }

  @media (min-width: 2200px) {
    column-gap: 130px !important;
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;

  &.card {
    gap: 0;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 2200px) {
    width: 100%;
  }
  @media (max-width: 580px) {
    gap: 10px;
  }
`;

const Title = styled.div`
  display: flex;
  color: #eb6502;
  font-family: "gellix-regular";
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 3.75px;
  gap: 10px;

  @media (max-width: 1350px) {
    font-size: 60px;
  }

  @media (max-width: 580px) {
    text-align: center;
    font-size: var(--heading-fz);
    justify-content: center;
    margin: 0 auto;
    font-size: 20px;
  }
`;

const Line = styled.div`
  height: 0.5px;
  width: 50px;
  background-color: #eb6502;

  &.images {
    margin-top: 6px;
  }

  @media screen and (max-width: 580px) {
    margin: 0 auto;
  }
`;


const SubTitle = styled.div`
  /* Para reg 16 caps */
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
  color: #606060;
  text-transform: uppercase;
  span{
    color: #eb6502;
  }

  @media screen and (max-width: 580px) {
    text-align: center;
    width: 100%;
  }
`;

const Classic = styled.div`
  color: #606060;
  font-size: 65px;

  @media (max-width: 1350px) {
    font-size: 60px;
  }

  @media (max-width: 580px) {
    text-align: center;
    font-size: 20px;
    
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (max-width:768px){
    flex-direction: column;
  }
`

const RightSide = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  padding: 20px;
  @media(max-width:480px){
      width: auto;
    }
`

const Form = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap:14px;
    justify-content: space-between;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 20;
    @media(max-width:480px){
      padding: 30px;
      width: auto;
    }
`;

const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    width:45%;
    gap: 10px;

    &.fw{
        width: 100%;
    }

    @media(max-width:580px){
      width: 100%;
    }
`;

const Name = styled.div`
    color: #706E6C;
    text-align: justify;
    font-family: 'gellix-regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    span{
      color: red;
    }
`;

const TextInput = styled.input`
  outline:none;
  border: none;
  border-bottom: 1px solid #606060;
  background-color: transparent;
`;

const TextAreaInput = styled.textarea`
  outline:none;
  border: none;
  border-bottom: 1px solid #606060;
  resize: vertical;
  background-color: transparent;
`;

const SubmitBtn = styled.button`
  padding: 16px 27px;
  background: #EB6502;
  outline: none;
  border: none;
  color: #FFF;
  font-family: 'gellix-regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; 
  cursor: pointer;
  float: right;
  margin-top: 20px;
`;

const ImagesContainer = styled.div`
  height: 250px;
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  
  .list-image{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 40px;
  }
  .list-image img{
    width: 20px;
    cursor: pointer;
  }
  @media (max-width:581px){
    height: auto;
    margin-top: 10px;
  }
`

const Error = styled.p`
  font-size: 12px;
  color: red;
  margin-top: -6px;
`