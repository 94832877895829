import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CardSlider from "./slidecard/CardSlider";
import { accountsConfig } from "../../../../axiosConfig";
import { ClipLoader } from "react-spinners";
import Slider from "react-slick";

export default function NewGallery() {
  const [events, setevents] = useState([]);
  const [year, setyear] = useState(2020);
  const [years, setyears] = useState([]);
  const [loading, setloading] = useState(false);

  const sliderRef = useRef();

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    arrows: true,
    infinite:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0
        }
      }
    ],
    afterChange: (currentSlide) => {
      if(sliderRef.current.classList.contains('set-first-slide')){
        sliderRef.current.classList.remove('set-first-slide');
      }
    },
  };

  useEffect(() => {
    accountsConfig.get("customer_celebrations/list-years/").then((response) => {
      const { StatusCode, data } = response.data.app_data;
      if (StatusCode === 6000) {
        setyears(data);
        setyear(data[0]);
      } else {
        setyears([]);
      }
    });
    
  }, []);
  
  useEffect(() => {
    setloading(true);
    accountsConfig
      .get("customer_celebrations/list-year-event/", {
        params: {
          year: year,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data.app_data;
        if (StatusCode === 6000) {
          setevents(data);
          setloading(false);
        } else {
          setevents([]);
          setloading(false);
        }
      });
  }, [year]);

  useEffect(() => {
    if(window.innerWidth < 600){
       sliderRef.current.classList.add('set-first-slide')
    }
  }, [])

  return (
    <Container>
      <Wrapper className="wrapper">
        <YearDisplay ref={sliderRef}>
          <Slider {...settings}>
            {years.map((obj,index) => (
              <Year
                className={obj === year && "active"}
                onClick={() => setyear(obj)}
                key={index}
              >
                {obj}
              </Year>
            ))}
          </Slider>
        </YearDisplay>

        <CardSliderContainer>
          {loading ? (
            <LoaderDiv>
              <ClipLoader
                color={"#EB6502"}
                loading={loading}
                // cssOverride={override}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </LoaderDiv>
          ) :(
            events.map((event, id) => {
              if (event?.event_assets?.length > 0) {
                return (
                  <Card key={id}>
                    <Title>
                      {event?.event_name?.split(" ")[0]}
                      <span>{event?.event_name?.split(" ")[1]}</span>
                    </Title>
                    <CardSlider assetsData={event?.event_assets} CardId={id} />
                  </Card>
                );
              }
            })
          )}
        </CardSliderContainer>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 40px 0;
  @media all and (max-width: 480px) {
    padding: 20px 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  gap: 80px;
  flex-direction: column;
`;
const Play = styled.div`
  position: absolute;
  color: #fff;
  border: 1px solid #fff;
  top: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const CoverBottom = styled.div`
  position: relative;
`;
const Arrow = styled.div`
  position: absolute;
  top: 35%;
  z-index: 1;
  cursor: pointer;
  @media all and (max-width: 480px) {
    width: 50px;
  }
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
`;
const CoverSlider = styled.div`
  margin-bottom: 150px;
  @media all and (max-width: 980px) {
    margin-bottom: 100px;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 50px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
const Top = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
  @media all and (max-width: 480px) {
    display: block;
  }
`;
const FixedCard = styled.div`
  position: relative;
  width: 50%;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const Label = styled.div`
  display: none;
  @media all and (max-width: 480px) {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #000000;
    border-bottom: 1px solid var(--orange);
    width: fit-content;
    padding-bottom: 10px;
  }
`;
const ImageContainer = styled.div`
  height: 100%;
  filter: brightness(0.7);
  img {
    height: 100%;
  }
`;
const Title = styled.h1`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  span {
    color: #eb6502;
    margin: 0 10px;
  }

  @media (max-width: 400px) {
    font-size: 35px;
  }
`;

const BottomSlider = styled.div`
  transition: transform 0.3s ease;
`;
const BottomSliders = styled.div`
  display: flex;
  gap: 10px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  transition: transform 0.3s ease;
`;
const Image = styled.div`
  transition: transform 0.3s ease;
  /* min-width: 150px !important; */
  width: 96% !important;
  filter: brightness(0.6);
  position: relative;
  @media all and (max-width: 480px) {
    min-width: 100px;
  }
  cursor: pointer;
`;

const CardSliderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1750px) {
    justify-content: center;
  }
`;

const Card = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;

  @media (max-width: 1300px) {
    width: 80%;
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const YearDisplay = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  /* overflow-y:hidden;
  overflow-x: auto; */
  width:80%;
  padding-bottom: 5px;
  margin: 0 auto;

  .slick-slider{
    width:100%;
  }


  .slick-prev , .slick-next{
    background-color: #EB6502 !important;
    border-radius: 9px !important;
  }

  @media(max-width:580px){
   /* .slick-slider{
    width:90%;
   } */
  }

  &.set-first-slide{
    .slick-track{
      transform: translate3d(0px, 0px, 0px) !important;
    }
  }
`;

const Year = styled.div`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.88px;
  cursor: pointer;
  padding: 4px;
  width: 50px !important;
  text-align: center;
  display: flex !important;
  margin: 0 auto;

  @media(max-width:600px){
    width:50%;
  }

  &.active {
    color: #eb6502;
    border-bottom: 1.5px solid #eb6502;
  }
`;

const LoaderDiv = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
