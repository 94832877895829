import React from "react";
import styled from "styled-components";

export default function ApartmentsDetails() {
  const detailArray = [
    {
      id: "1",
      title: "Own Your Dream Luxury Apartments and Flats in Kochi",
      description:
        "Kochi, Kerala's economic hub, has seen a tremendous increase in the number of people settling in the city, as well as other amenities such as employment possibilities and education. With the increasing need and yearning of investors to capitalize on premium designed flats, Classic Homes offers you a broad choice of apartments in Kochi. Each three-bedroom apartment has premium finishes and cutting-edge contemporary conveniences. Fitness centre, Children's play area, Multipurpose Hall, Solar powered common areas, Generator backup, Intercom facility, Centralized gas, 24/7 security, KWA connection, Borewell, Incinerator, Waste management system, and other contemporary and traditional amenities are also included in our residential projects.",
    },
    {
      id: "2",
      title: "Classic Avenir Suites Apartments in Kakkanad",
      description:
        "Classic Avenir Suites, a premium apartment project of Classic Homes is strategically located just 1km from The Collectorate at Kakkanad. This beautiful 10 floor tower comprise 34 units, all of 3BHK type. With close proximity to the Infopark, Seaport Airport Road, ongoing metro rail project, Raja Giri institutions, food courts, malls etc, Classic Avenir Suites is a home for the settlers and wise investors. ",
    },
    {
      id: "3",
      title: "Classic Vintage Apartments in Kochi",
      description:
        "Classic vintage apartments located at Karangarapady Kochi is a luxury apartment project by classic homes for people who love to live in calm serene but enjoy all the city amenities. This is a ten-story high-rise with 40 flats for the selective elite. The 10th level has been exclusively designated as a children's play space, and the apartment includes efficient waste management systems. The common area lighting is solar powered, and each unit has a centralized gas supply. Within a 3-kilometer radius, all urban comforts and points of public interest can be found. The Seaport-Airport Road is only 1.2 kilometres distant. ",
    },
    {
      id: "4",
      title: "Sunny Sands and Summer Sands Apartments in Thrikkakara",
      description:
        "A twin tower to Classic Sunny Sands and Summer Sands located at Thrikkakara offers all contemporary conveniences and traditional comforts. The roomy residence ranges from 1150 sq. ft. to 1920 sq. ft. and enjoys lots of natural light, total seclusion, and well-designed space.",
    },
    {
      id: "5",
      title: "Live Close to Every Amenities",
      description:
        "Our apartments are an exuberant exhibition of cherished urban living with best-in-class modern facilities and technologically advanced features that have been painstakingly chosen to enhance contemporary lifestyle to a new level. The city's largest shopping/entertainment landmarks as well as corporate companies are all within walking distance from Classic apartments. As a result, travelling to the office every day and striking the right balance between work and life becomes easy. Ready to move luxury apartments for sale in Kochi at 73 lakhs onwards contains all necessary and ultramodern facilities and nearby amenities. Both 3 BHK and 2 BHK are available under this price range with advanced technological facilities and a closed gate community. With a variety of modern amenities, allowing residents to live in a healthier environment.",
    },
    {
      id: "6",
      title: "",
      description:
        "Huge open spaces, solar-powered common area, gorgeous architecture and a wonderful mix of lush green landscapes are what makes Classic apartments closer to your dream home concept. These 3 BHK apartments for in Kakkanad include all of the conveniences necessary for urban living. With leading IT firms and the metro station just nearby, Classic apartments are always the most convenient choice. These 3 BHK Flats in Kochi are stylishly constructed to provide you with luxury living. The property is located in a high-end visible infill premium residential area of Kochi. ",
    },
    {
      id: "7",
      title: "",
      description:
        "In comparison to other apartments in the city, this would be an excellent real estate investment as well. With Classic Homes and its well-designed architectural structure, it's time to believe the impossible. The magnificent 3 BHK apartments will wow you every day with our spacious design, which is ideal for all your personal and professional events. Classic Homes seek to disrupt established norms and standards by pioneering creative apartment projects and changing views of excellence. ",
    },
    {
      id: "8",
      title: "",
      description:
        "Today if you are searching for the most spacious flats for sale in Kochi, Obviously the first one you would run into will be Classic. We focus ultimately on enriching communities and society as a whole by building luxury apartments that meet the most modern trends. So if you are in quest of one undoubtedly you can come to Classic. We promise that you won't be disappointed.",
    },
   
    
  ];
  const faq = [
    {
      id: "f3453322",
      question: "Which are the best areas to buy apartments in Kochi?",
      ans: "Kakkanad, Edappally, Kaloor, Palarivattom, Kadavantra, Maradu, Aluva, M G Road are the prominent locations in Cochin which are considered among the best places to buy apartments in Kochi.",
    },
    {
      id: "f7687897653",
      question: "What is the normal cost of 3 BHK flats in Kochi?",
      ans: "The cost of flats in Kochi is mostly determined by the property's location. A wide range of flats are available for an average price and for luxury. For more information about our 3BHK price, contact us.",
    },
  ];
  return (
    <MainContainer>
      <Wrapper className="wrapper">
        <Content>
          <Left>
            <DetailSection>
              {detailArray.map((item, index) => (
                <Cover key={index}>
                  <TitleSection>{item?.title} </TitleSection>
                  <Descr>{item.description} </Descr>
                  
                </Cover>
              ))}
            </DetailSection>
          </Left>
          <Line></Line>
          <Right>
            <Heading>Frequently Asked Questions</Heading>
            {faq.map((item, index) => (
              <Cover key={index}>
                <CoverQ>
                  <FaqQuestion>{index + 1}. </FaqQuestion>
                  <FaqQuestion>{item.question} </FaqQuestion>
                </CoverQ>
                <FaqAns>{item.ans} </FaqAns>
              </Cover>
            ))}
          </Right>
        </Content>
      </Wrapper>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  padding: 80px 0;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  gap: 50px;
  @media all and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const Left = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const DetailDescription = styled.p`
  line-height: 28px;
  text-align: justify;
`;
const DetailSection = styled.div`
  margin-top: 30px;
`;
const Line = styled.div`
  background-color: #777777;
  width: 1px;
`;
const Cover = styled.div`
  margin-bottom: 30px;
`;
const CoverQ = styled.div`
  display: flex;
  gap: 5px;
`;
const TitleSection = styled.h3`
  color: #000000b2;
  font-family: "gellix-bold";
  letter-spacing: 2px;
  margin-bottom: 20px;
`;
const Descr = styled.p`
  line-height: 28px;
  text-align: justify;
`;
const Right = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Heading = styled.h3`
  color: #000000b2;
  margin-bottom: 30px;
  font-family: "gellix-bold";
  letter-spacing: 2px;
`;
const FaqQuestion = styled.h4`
  font-family: "gellix-medium";
  font-weight: 600;
  letter-spacing: 1px;
`;
const FaqAns = styled.p`
  text-align: justify;
  line-height: 28px;
`;
