import React, { useEffect, useState } from 'react'
import BreadCrumb from '../includes/blogs/BreadCrumb';
import styled from 'styled-components';
import BlogsDisplay from '../includes/blogs/BlogsDisplay';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import Helmet from '../includes/Helmet';
import { accountsConfig } from '../../axiosConfig';

function Blogs() {
  const [datas, setdatas] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  
  useEffect(() => {
    accountsConfig.get('web/list-link-seo/',{
      params:{
        links:'/blogs'
      }
    })
    .then((res) => {
     const { data , StatusCode } = res.data.app_data;
       if(StatusCode === 6000){
          setdatas(data[0])
       }else{
        setdatas({})
       }
    })
  },[])

  return (
    <>
    <Helmet titleData={datas?.meta_title} descriptionData={datas?.meta_description} />
    <Header/>
    <Section>
     <BreadCrumb/>
     <BlogsDisplay/>
    </Section>
     <Footer/>
    </>
  )
}

export default Blogs;

const Section = styled.div`
      display: flex;
      flex-direction: column;
      gap: 100px;
      background-color:#F8F8F8;

      @media(max-width:768px){
        gap:64px;
      }
`;