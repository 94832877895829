import React, { useEffect, useState } from "react";
import Header from "../Header";
import Banner from "./Apartment-single/Banner";
import ApartmentFeatures from "./Apartment-single/ApartmentFeatures";
import styled from "styled-components";
import Amenities from "./Apartment-single/Amenities";
import Footer from "../Footer";
import Map from "./Apartment-single/Map";
import TalkToExpert from "./Apartment-single/TalkToExpert";
import { accountsConfig } from "../../../axiosConfig";
import { useParams } from "react-router-dom";
import Helmet from "../Helmet";

export default function ApartmentsSingle() {
  const [getSingleProject, setGetSingleProject] = useState();
  const { id } = useParams();

  // get single project
  useEffect(() => {
    accountsConfig.get(`projects/list-single-project/${id}`,{}).then((res) => {
      if (res.data.app_data.StatusCode == 6000) {
        const [data] = res.data.app_data.data;
        setGetSingleProject(data);
      } else {
        window.location.href ='/page-not-found'
      }
    });
  }, [id]);
  
  return (
    <MainContainer>
      <Helmet titleData={getSingleProject?.meta_title} descriptionData={getSingleProject?.meta_description} />
      <Header />
      <Banner getSingleProject={getSingleProject} />
      <ApartmentFeatures getSingleProject={getSingleProject} />
      <Amenities getSingleProject={getSingleProject} />
      <Map getSingleProject={getSingleProject}  />
      <TalkToExpert getSingleProject={getSingleProject} />
      <Footer />
    </MainContainer>
  );
}
const MainContainer = styled.div``;
