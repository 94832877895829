import React, { useEffect, useState } from "react";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import styled from "styled-components";
import VillasList from "../includes/villas/VillasList";
import Banner from "../includes/villas/Banner";
import { accountsConfig } from "../../axiosConfig";
import Helmet from "../includes/Helmet";
import VillasAbout from "../includes/villas/VillasAbout";

function Villas() {
  const [villasList, setVillasList] = useState();
  const [datas, setdatas] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    accountsConfig
      .get("projects/list-type-project/", {
        params: {
          project_type: "villas",
        },
      })
      .then((res) => {
        const data = res.data.app_data.data;
        if (res.data.app_data.StatusCode == 6000) {
          setVillasList(data);
        } else {
          setVillasList([]);
        }
      });
  }, []);
  

  useEffect(() => {
    accountsConfig.get('web/list-link-seo/',{
      params:{
        links:'/villas-for-sale-in-kochi'
      }
    })
    .then((res) => {
     const { data , StatusCode } = res.data.app_data;
       if(StatusCode === 6000){
          setdatas(data[0])
       }else{
        setdatas({})
       }
    })
  },[])

  return (
    <>
    <Helmet titleData={datas?.meta_title} descriptionData={datas?.meta_description} />
      <Header />
      <Section>
        <Banner />
        <VillasAbout/>
        <VillasList villasList={villasList} />
      </Section>
      <Footer />
    </>
  );
}

export default Villas;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
