import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./components/routing/router/MainRouter";
import "remixicon/fonts/remixicon.css";
import "./assets/css/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "sweetalert2/src/sweetalert2.scss";
import "react-toastify/dist/ReactToastify.css";
import StoreContext from "./components/context/StoreContext";
function App() {
  return (
    <BrowserRouter>
      <StoreContext>
      <MainRouter />
      </StoreContext>
    </BrowserRouter>
  );
}

export default App;
