import React from 'react'
import styled from 'styled-components';
import breadCrumb from '../../../assets/images/about/breadCrumb/Breadcrumb.jpg';

function BreadCrumb() {
  return (
    <Section bg={breadCrumb}>
        <Content>
            <Heading>
            CONTACT
            </Heading>
            <Line/>
            <SubHeading>
            Your Dream Home may be a Call Away
            </SubHeading>
        </Content>
    </Section>
  )
}

export default BreadCrumb;


const Section = styled.div`
    height: calc(500px - 83px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
    background-image: url(${(props)=>props?.bg});
    position: relative;

    &::after{
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.25);
    }
`;

const Content = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    z-index: 1;
`;
const Heading = styled.div`
    color: #FFF;
    font-family: 'gellix-medium';
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.75px;
    `;
const Line = styled.div`
      height: 1px;
      width:100px;
      background-color: #FFFFFF;
`;
const SubHeading = styled.div`
        color: #FFF;
        font-family: 'gellix-light';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;

        @media(max-width:615px){
            text-align: center;
        }
        @media(max-width:580px){
            font-size: 14px;
        }
`;