import React, { useEffect, useState } from "react";
import styled from "styled-components";
import img1 from "../../../../assets/images/apartments/amenities/Group 209.png";
import { accountsConfig } from "../../../../axiosConfig";
import { useParams } from "react-router-dom";
import FloorPlanPopUp from "../../modal/FloorPlanPopUp";

export default function Amenities({ getSingleProject}) {
  const [active, setActive] = useState("Amenities");
  const [amenities, setAmenities] = useState();
  const [tabs, settabs] = useState([]);
  const [modal, setmodal] = useState(false);
  const [img, setimg] = useState('');
  const { id } = useParams();
  const path = window.location.pathname;

  // showing only tabs that have data
  useEffect(() => {
    const tempArry = []
    if(getSingleProject?.amenities?.length > 0){
      const temp =  {
          id: 45,
          title: "Amenities",
        }
      tempArry.push(temp)
    }
    if(getSingleProject?.floorplan?.length > 0){
      const temp =  {
        id: 415,
        title: "Floor plans",
      }
      tempArry.push(temp)
    }
    if(getSingleProject?.specification?.length > 0){
      const temp =  {
        id: 425,
        title: "Specifications",
        }
        tempArry.push(temp)
    }
    if(getSingleProject?.layout?.length > 0){
      const temp =  {
        id: 426,
        title: "Layout",
        }
        tempArry.push(temp)
    }
    settabs(tempArry)

    if(tempArry.length > 0){
      setActive(tempArry[0].title)
    }

  },[getSingleProject?.id])

  return (
    <MainContainer className={tabs.length === 0 && 'hide'}>
      <Wrapper className="wrapper">
        <Content>
          <TopBar>
            {tabs.map((item, index) =>{
            return (
              <Tab
                key={index}
                onClick={() => setActive(item.title)}
                className={active == item.title && "active"}
                type={item.title}
              >
                {item.title}
              </Tab>
            )
            })}
          </TopBar>
          {active == "Amenities" ? (
            <AmenitiesSection>
              {getSingleProject &&
                getSingleProject?.amenities.map((item, index) => (
                  <Card key={index}>
                    <ImgContainer>
                      <img src={item.logo} alt="" />
                    </ImgContainer>
                    <Text>{item.name} </Text>
                  </Card>
                ))}
            </AmenitiesSection>
          ) : active  == "Specifications" ? (
            <SpecificationSection>
              {getSingleProject?.specification.map((item, index) => (
                <CoverSpecific>
                  <SpecificText>{item.name}</SpecificText>
                  <SpecificDescription>{item.description}</SpecificDescription>
                </CoverSpecific>
              ))}
            </SpecificationSection>
          ) : active == "Floor plans" ? (
            <Flooral>
              {getSingleProject?.floorplan.map((item, index) => (
                <FloralCard key={index} href={item?.floorplan} target="_blank" >
                  <img src={item.image} alt="" />
                </FloralCard>
              ))}
            </Flooral>
          ) : active == "Layout" ? (
            <Layout>
              {getSingleProject?.layout.map((item, index) => (
                <LayoutCard key={index} href={item?.layout} target="_blank" > 
                  <img src={item.image} alt="" />
                </LayoutCard>
              ))}
            </Layout>
          ) : (
            ""
          )}
        </Content>
      </Wrapper>
      {/* <FloorPlanPopUp
        img={img}
        setModal={setmodal}
        modal={modal}
      /> */}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  background-color: #f4f4f4;
  padding: 80px 0;

  &.hide{
    display: none;
  }
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const TopBar = styled.div`
  display: flex;
  gap: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid #403f3e;
  margin-bottom: 50px;

  @media(max-width:600px){
    flex-wrap: wrap;
  }
`;
const Tab = styled.div`
  color: #2b2a29;
  font-family: "gellix-bold";
  letter-spacing: 1px;
  position: relative;
  cursor: pointer;
  transition: 0.4s ease;
  &.active {
    color: #eb6502;
    transition: 0.4s ease;
    &::after {
      transition: 0.4s ease;
      content: "";
      position: absolute;
      width: 40px;
      height: 3px;
      border-radius: 10px;
      background-color: #eb6502;
      bottom: -21px;
      left: 25%;
    }
  }
  @media(max-width:600px){
    width:40%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after{
      left: 40% !important;
    }
  }

  @media all and (max-width: 480px) {
    font-size: 12px;
  }
`;
const AmenitiesSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;
const Card = styled.div`
  width: 25%;
  margin-bottom: 30px;
  @media all and (max-width: 480px) {
    width: 50%;
  }
`;
const ImgContainer = styled.div`
  width: 15%;
  margin: 0 auto;
  margin-bottom: 10px;
  @media all and (max-width: 480px) {
    width: 35%;
  }
`;
const Text = styled.div`
  color: #383736;
  font-size: 14px;
  text-align: center;
`;

const SpecificationSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const CoverSpecific = styled.div`
  width: 45%;
  margin-bottom: 30px;
  
  @media all and (max-width: 580px) {
    width: 100%;
  }
`;
const SpecificText = styled.div`
  color: var(--orange);
`;
const SpecificDescription = styled.p`
  color: #706e6c;
`;
const Flooral = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;
const FloralCard = styled.a`
  width: 31.33%;
  cursor: pointer;
  @media all and (max-width: 1440px) {
    width: 30%;
  }

  @media all and (max-width: 580px) {
    width: 100%;
  }
`;

const Layout = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;

const LayoutCard = styled.a`
  width: 32.33%;
  cursor: pointer;
  @media all and (max-width: 1440px) {
    width: 30%;
  }
  @media all and (max-width: 580px) {
    width: 100%;
  }
`;
