import React from 'react'
import img1 from '../../../assets/images/about/about-img-1.png';
import img4 from '../../../assets/images/about/img.svg';
import styled from 'styled-components';

function BriefInfo() {
  return (
    <Section>
      <Wrapper className="wrapper">
        <Left>
          <Subtitle>BEST BUILDERS IN KOCHI</Subtitle>
          <Line />
          <Title>
            <Classic>CLASSIC</Classic> HOMES
          </Title>
          <ParaConatiner>
            <Para>
            Classic Homes represents a 29-year legacy of crafting exceptional living spaces that have made us one of the best builders in Kochi.
            Our journey goes beyond just bricks and mortar; it's a narrative of creating homes that resonate with life's most cherished moments.
            From picturesque villas to contemporary apartments in Kochi, each creation is a testament to our unwavering commitment to exquisite design and unparalleled quality.
            </Para>
            <Para>
                Er. K. T. Mathew,  a graduate Civil Engineer has had hands-on experience in the building industry for the past 3 decades and is one of the best real estate developers in Ernakulam, Kerala.
                Home’s journey, a visionary industry expert whose strategic vision has brought about great achievements in the real estate business.
            </Para>
            <Para>
            Today Classic Homes is privileged to have 2 of the new gen joining the firm, Mr. Paul Thomas Mathew, a graduate Civil Engineer who heads the interior firm Classic Insides and Mr. Abel Thomas Mathew a law graduate.
            </Para>
            </ParaConatiner>
          </Left>
          <Right>
            <img src={img4} alt="" />
          </Right>
        </Wrapper>
    </Section>
  );
}

export default BriefInfo;

const Section = styled.div`
  margin: 0px;
`;
const Wrapper = styled.div`
      display: flex;
      gap:25px;

      @media(max-width:1200px){
         flex-direction: column-reverse;
         gap:40px;
      }
`;

const Left = styled.div`
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 20px;

      @media(max-width: 768px){
            justify-content: center;
            align-items: center;
      }
`;
const Subtitle = styled.div`
  color: #606060;

  /* Para reg 16 caps */
  font-family: "gellix-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
`;
const Line = styled.div`
  height: 0.5px;
  width: 50px;
  background-color: #eb6502;
`;
const Title = styled.div`
      display: flex;
      color: #EB6502;
      font-family: 'gellix-regular';
      font-size: 65px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 3.75px;
      gap: 10px;

      @media(max-width: 875px){
        font-size: 50px;
      }

      @media(max-width: 580px){
        text-align: center;
        justify-content: center;
        align-items: center;
        /* flex-direction: column; */
        gap:5px;
        font-size: var(--heading-fz);
      }
`;
const Classic = styled.div`
  color: #606060;

  @media (max-width: 580px) {
    font-size: var(--heading-fz);
  }
`;
const ParaConatiner = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

      img{
        width:95%;
        height:auto;
        display: block;

        @media(max-width:1200px){
            width:70%;
            margin: 0 auto;
        }

        @media(max-width:580px){
            width:90%;
            margin: 0 auto;
        } 
      }
`;

const Para = styled.div`
        color: #706E6C;
        font-family:'gellix-regular' ;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        text-align: justify;


        @media(max-width:1200px){
            text-align: justify;
        }
`;
