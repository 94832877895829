import React from 'react';
import image from '../../../assets/images/blogs/not-found.png';
import styled from 'styled-components';

function Nodata({h}) {
  return (
   <Box h={h} className='box'>
    <img src={image} alt="" />
   </Box>
  )
}

export default Nodata;

const Box = styled.div`
      width:100%;
      height: ${(props)=>props.h};
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width:100%;
        object-fit: contain;
        height: 100%;
      }
`;