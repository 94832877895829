import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ImageZoom from "react-image-zooom";


import closeBtn from "../../../assets/images/icons/close-icon.svg";

function FloorPlanPopUp({ modal, setModal,img }) {
  return (
    <Section className={modal && "active"}>
      <Overlay />
      <Modal>
        <CloseIcon onClick={() => setModal(false)}>
          <img src={closeBtn} alt="" />
        </CloseIcon>
        <Container>
          {
            img && <ImageZoom src={img} alt="event-image" zoom="180"/>
          }  
        </Container>
      </Modal>
    </Section>
  );
}

export default FloorPlanPopUp;

const Section = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: 11;

  &.active {
    visibility: visible;
  }
`;

const Modal = styled.div`
  width: 604px;
  border-radius: 5px;
  background-color:#646464;
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
  box-sizing: border-box;
  gap: 20px;
  z-index: 11;
  height: 80vh;
  min-height: 530px;
  max-height: 650px;
  position: relative;
  justify-content: center;
  align-items: center;

  @media (max-width: 625px) {
    width: 90vw;
    justify-content: center;
  }

  @media (max-width: 525px) {
    min-height: 450px;
    max-height: 650px;
    padding: 25px;
  }

  @media (max-width: 400px) {
    min-height: 375px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* overflow: hidden; */
  padding: 15px;
  width: 100%;
  height: 100%;
  justify-content: center;

  img{
    width:100%;
    height:100%;
    object-fit: contain;
  }

`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: black;
  opacity: 0.6;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
`;

const CloseIcon = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  img {
    width: 25px;
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;
