import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import ServiceSection from '../includes/services/ServiceSection';
import { accountsConfig } from '../../axiosConfig';
import Helmet from '../includes/Helmet';

function Services() {
  const [datas, setdatas] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  
  useEffect(() => {
    accountsConfig.get('web/list-link-seo/',{
      params:{
        links:'/services'
      }
    })
    .then((res) => {
     const { data , StatusCode } = res.data.app_data;
       if(StatusCode === 6000){
          setdatas(data[0])
       }else{
        setdatas({})
       }
    })
  },[])
  
  return (
    <Main>
      <Helmet titleData={datas?.meta_title} descriptionData={datas?.meta_description} />
      <Header />
      <Section>
        <ServiceSection/>
      </Section>
      <Footer />
    </Main>
  )
}

export default Services;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 130px;

  @media(max-width:900px){
    row-gap: 64px;
  }
`;