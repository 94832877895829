import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";

import gif from "../../../assets/images/Gif/gif.gif";
import animategifVid from "../../../assets/images/Gif/gifVideo.mp4";
import arrow from "../../../assets/images/Gif/arrow.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { accountsConfig } from "../../../axiosConfig";
import closeBtn from "../../../assets/images/icons/close-icon.svg";
import ClipLoader from "react-spinners/ClipLoader";
import { CustomContext } from "../../context/StoreContext";

function OnloadPopUp({ modal, setModal, type }) {
  const sliderRef = useRef(null);

  const [currentPos, setcurrentPos] = useState(1);
  const [finished, setfinished] = useState(true);
  const [videoPlayed, setvideoPlayed] = useState(false);
  const [error, seterror] = useState("");
  const [formData, setformData] = useState({ name: "", email: "", phone: "" ,project:''});
  const [loading, setloading] = useState(false);
  const { setactive } = useContext(CustomContext);
  const [projects, setprojects] = useState([]);

  // fetching all projects
  useEffect(() => {
    accountsConfig
      .get("projects/get-projects")
      .then((res) => {
        const { data, StatusCode } = res.data.app_data;
        if (StatusCode === 6000) {
          setprojects(data);
        } else {
          setprojects([]);
        }
      });
  }, [modal]);

  useEffect(() => {
    const html = document.querySelector("html");
    if (modal) {
      html.classList.add("model-enabled");
      setactive(true);
    } else {
      if (type === "Enquiry") {
        handleReset();
      }

      html.classList.remove("model-enabled");
      setactive(false);
    }
  }, [modal]);

  useEffect(() => {
    if (currentPos < 2) {
      setfinished(false);
    }
  }, [currentPos]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    seterror(null);
    setformData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const SubmitEnquiry = () => {
    const form = new FormData();
    Object.entries(formData).forEach((item) => {
      form.append(item[0], item[1]);
    });
    setloading(true);
    accountsConfig.post("projects/add-enquiry/", form).then((response) => {
      const { data, StatusCode } = response.data.app_data;
      if (StatusCode === 6000) {
        setloading(false);
        setfinished(true);
        setcurrentPos(2);
        SlideMove();
      }
    });
  };

  const navigate = useNavigate();

  // slider each item
  const SlideMove = () => {
    sliderRef.current.style.transform = `translateX(-105%)`;
  };

  const handleValidation = (index) => {
    switch (index) {
      case 1:
        if (!formData.name || formData.name.length < 3) {
          seterror(1);
          return false;
        } else {
          return true;
        }
      case 3:
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(formData.email)) {
          seterror(3);
          return false;
        } else {
          return true;
        }

      case 2:
        const numberRegex = /^\d{10}$/;
        if (!numberRegex.test(formData.phone)) {
          seterror(2);
          return false;
        } else {
          return true;
        }

      case 4:
          if (!formData?.project) {
            seterror(4);
            return false;
          } else {
            return true;
          };

      default:
        break;
    }
  };

  const handleReset = () => {
    setcurrentPos(1);
    sliderRef.current.style.transform = `none`;
    setformData({ name: "", email: "", phone: "" });
  };

  return (
    <Section className={modal && "active"}>
      <Overlay />
      <Modal>
        <CloseIcon onClick={() => setModal(false)}>
          <img src={closeBtn} alt="" />
        </CloseIcon>
        <Container>
          <Title>
            <span>SEARCHING FOR YOUR DREAM PROPERTY?</span>
          </Title>
          <GifArea currentPos={currentPos}>
            {videoPlayed ? (
              <img src={gif} alt="" />
            ) : (
              <video
                playsInline
                muted
                autoPlay
                src={animategifVid}
                onEnded={() => setvideoPlayed(true)}
              ></video>
            )}
          </GifArea>
          <Register>
            {!finished && (
              <Span>
                Register Your <Highlight>Interest</Highlight>
              </Span>
            )}
            <Line />
            {finished && (
              <Span className="last">
                <Box onClick={() => navigate("/apartments-for-sale-in-kochi")}>
                  Browse Properties
                </Box>
                <Arrow2 onClick={() => navigate("/apartments-for-sale-in-kochi")}>
                  <img src={arrow} alt="" />
                </Arrow2>
              </Span>
            )}
          </Register>

          <InputArea className="slider-parent" ref={sliderRef}>
            <Item>
              <ItemConatiner>
                <Top>
                  {error === 1 && <ErrorMsg>Enter valid name</ErrorMsg>}
                </Top>
                <Input
                  placeholder="Your Name*"
                  id="1"
                  name="name"
                  onChange={(e) => handleChange(e)}
                  value={formData?.name}
                />
              </ItemConatiner>
              <ItemConatiner>
                <Top>
                  {error === 4 && <ErrorMsg>Select Project</ErrorMsg>}
                </Top>
                <Select name="project" onChange={(e)=>handleChange(e)}>
                  {
                    projects?.length > 0 ? <Option disabled selected value={":"}>Select Project*</Option> :
                    <Option disabled selected>No Project Found</Option>
                  }
                  
                  {
                    projects?.map((item)=>(
                      <Option value={item?.id}>{item?.name} - {item?.project_type === "apartment" ? "Apartment" : "Villa"}</Option>
                    ))
                  }
                </Select>
              </ItemConatiner>
              <ItemConatiner>
                <Top>
                  {error === 2 && <ErrorMsg>Enter Phone Number</ErrorMsg>}
                  <Arrow
                    onClick={() => {
                      if (handleValidation(1) && handleValidation(4) && handleValidation(2)) {
                        SubmitEnquiry();
                      } else {
                        setTimeout(() => {
                          seterror("");
                        }, 3000);
                      }
                    }}
                  >
                    {loading ? (
                      <ClipLoader
                        color={"#eb6502"}
                        loading={loading}
                        // cssOverride={override}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      <img src={arrow} alt="" />
                    )}
                  </Arrow>
                </Top>
                <Input
                  id="2"
                  name="phone"
                  type="number"
                  placeholder="Phone Number*"
                  onChange={(e) => handleChange(e)}
                  value={formData?.phone}
                />
              </ItemConatiner>
            </Item>
            <Item>
              <Top className="last">
                <TopTitle>
                  Thanks For Your <span>Interest</span>
                </TopTitle>
                {/* <TopSubtitle>Thanks For Your Interest</TopSubtitle> */}
              </Top>
            </Item>
          </InputArea>

          <ProgressBar>
            <BarContainer>
              <Bar pos={currentPos} />
            </BarContainer>
          </ProgressBar>
        </Container>
      </Modal>
    </Section>
  );
}

export default OnloadPopUp;

const Section = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  z-index: 253;
  transition: all .25s ease-in-out;
  transform: scale(0,0);

  &.active {
    visibility: visible;
    transform: scale(1,1);
  };
`;

const Modal = styled.div`
  width: 604px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
  box-sizing: border-box;
  gap: 20px;
  z-index: 11;
  height: 80vh;
  min-height: 600px;
  max-height: 650px;
  position: relative;

  @media (max-width: 625px) {
    width: 90vw;
    justify-content: center;
  }

  @media (max-width: 525px) {
    min-height: 600px;
    max-height: 650px;
    padding: 25px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: black;
  opacity: 0.6;
`;

const Title = styled.div`
  color: #777;
  text-align: center;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const GifArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38%;

  img {
    width: 45%;
    height: 100%;
  }
  video {
    width: 75%;
    height: 100%;
  }
`;

const Register = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

const InputArea = styled.div`
  display: flex;
  transition: transform 0.8s ease-in;
  gap: 5px;
`;

const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const BarContainer = styled.div`
  width: 250px;
  border-radius: 5px;
  overflow: hidden;
  height: 3px;
  background-color: #cdcdcd;
`;

const Bar = styled.div`
  background-color: #eb6502;
  width: ${(props) => props.pos * 50}%;
  height: 100%;
`;

const Span = styled.span`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  flex: 1;

  @media (max-width: 625px) {
    flex: 2;
    &.last {
      flex: 3;
    }
  }

  @media (max-width: 525px) {
    font-size: 21px;
    &.last {
      font-size: 18px;
    }
  }

  &.last {
    color: #706e6c;
    text-align: justify;
    font-family: "gellix-regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 175%; /* 31.5px */
    display: flex;
    gap: 15px;
    align-items: center;
  }
`;

const Highlight = styled.span`
  color: #eb6502;
`;

const Line = styled.div`
  flex: 1;
  width: 100%;
  height: 1.5px;
  background-color: #cdcdcd;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  box-sizing: border-box;
  position: relative;

  &.last {
    flex-direction: column;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  /* transform: translateX(${(props) => props.transform}%); */
  min-width: 100%;
`;

const ItemConatiner = styled.div`
  width: 100%;
`;

const Name = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  flex: 1;
  position: absolute;
  right: 10px;
  bottom: -30px;
  img {
    width: 25px;
  }

  @media screen and (max-width: 580px) {
    right: 25px;
  }
`;

const Arrow2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 25px;
  }
`;

const RevArrow = styled.div`
  img {
    transform: rotate(180deg);
    width: 20px;
  }
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid #cdcdcd;
  outline: none;
  width: 100%;
  padding-left: 10px;
  font-size: 16px;
  height: 40px;
  touch-action: manipulation; /* This property can help prevent double-tap zoom on some browsers */

  &::placeholder {
    color: #706e6c;
    text-align: justify;
    font-family: "gellix-regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%; /* 28px */
  }
`;

const TopTitle = styled.div`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;

  @media (max-width: 525px) {
    font-size: 21px;
  }

  span {
    color: #eb6502;
  }
`;

const TopSubtitle = styled.div`
  color: #706e6c;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
`;

const Box = styled.div`
  border-bottom: 1px solid #eb6502;
  cursor: pointer;
`;

const CloseIcon = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  img {
    width: 25px;
  }
`;

const ErrorMsg = styled.div`
  color: red;
  text-align: justify;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  display: flex;
  flex: 1;
`;

const Select = styled.select`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  outline: none;
  width: 100%;
  padding-left: 8px;
  font-size: 16px;
  height: 40px;
  touch-action: manipulation; /* This property can help prevent double-tap zoom on some browsers */
`;

const Option = styled.option`
  color: #606060;
  font-family: "gellix-regular";
  font-size: 16px;
  letter-spacing: 0.36px;
  margin: 5px 0;
`;
