import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

//imports
import img from "../../../assets/images/fam.png";
import qoute from "../../../assets/images/testimonials/qoute.png";
import qouteright from "../../../assets/images/testimonials/qoute-right.png";
import { accountsConfig } from "../../../axiosConfig";
import ReactPlayer from "react-player";
import { Tooltip } from "react-tooltip";

export default function Testimonials() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 500,
      },
    ],
  };
  //list testimonials
  const [listTestimonials, setListTestimonials] = useState([]);

  useEffect(() => {
    accountsConfig.get("web/list-testimonials/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode === 6000) {
        setListTestimonials(data);
      } else {
        setListTestimonials([]);
      }
    });
  }, []);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <SubTitle>TESTIMONIALS</SubTitle>
          <Line></Line>
          <Title> Words from Our Delighted Homeowners</Title>
          <TestimonialSection>
            <Slider {...settings} className="testimonial-slider">
              {listTestimonials.map((item, index) =>
                item.video == null ? (
                  <Card className="text">
                    <ImageContainer>
                      <img src={item.image} alt="image" />
                    </ImageContainer>
                    <DetailSection>
                      <Qoute>
                        <img src={qoute} alt="icon" />
                      </Qoute>
                      <Description
                        data-tooltip-id="capture"
                        // data-tooltip-content={item.description}
                        data-tooltip-html={`<p>${item.description}</p>`}
                      >
                        {item.description.length > 250
                          ? item.description.slice(0, 250) + "..."
                          : item.description}
                      </Description>
                      <DescriptionMob
                        data-tooltip-id="capture"
                        // data-tooltip-content={item.description}
                        data-tooltip-html={`<p>${item.description}</p>`}
                      >
                        {item.description.length > 100
                          ? item.description.slice(0, 100) + "..."
                          : item.description}
                      </DescriptionMob>
                      <Tooltip id="capture" className="tooltip-description" />
                      <CoverName>
                        <CoverNameText>
                          <Name>{item.name}</Name>
                          <CoverSpan>
                            <SpanText>{item.designation}</SpanText>
                            <SpanText> {item.location}</SpanText>
                          </CoverSpan>
                        </CoverNameText>
                        <Qoute>
                          <img src={qouteright} alt="" />
                        </Qoute>
                      </CoverName>
                    </DetailSection>
                  </Card>
                ) : (
                  <Card>
                    <ReactPlayer url={item.video} controls width={1000} />
                    <Play>
                      <SpanText className="video">{item.name} </SpanText>
                      <SpanText className="video"> {item.location}</SpanText>
                    </Play>
                  </Card>
                )
              )}
            </Slider>
          </TestimonialSection>
        </Content>
      </Wrapper>
    </Container>
  );
}
const Container = styled.div`
  background-color: var(--black);
  padding: 60px 0;
  margin-bottom: 50px;
`;
const Wrapper = styled.div`
  @media (max-width: 580px) {
    width: 100% !important;
  }
`;
const Content = styled.div``;
const Card = styled.div`
  display: flex !important;
  width: 98% !important;
  gap: 30px;
  &.text {
    justify-content: center;
    align-items: center;
    height: 400px;
  }
  position: relative;
  background-color: #161616;
  padding: 40px;
  box-sizing: border-box;
  @media all and (max-width: 1440px) {
    align-items: center;
  }
  @media all and (max-width: 768px) {
    &.text {
      flex-direction: column;
      height: unset;
    }
  }
`;
const TestimonialSection = styled.div`
  margin-top: 50px;
  width: 70%;
  margin: 0 auto;
  @media all and (max-width: 1440px) {
    width: 85%;
  }

  @media(max-width:850px){
    .slick-prev{
      top:calc(100%) !important;
      left: 50px !important;
    }
    .slick-next{
      top:calc(100%) !important;
      right: 50px !important;
    }

    .slick-prev::before{
      width:40px !important;
    }

    .slick-next::before{
      width:40px !important;
    }
  }

  @media (max-width: 580px) {
    width: 90%;
  }
`;
const Play = styled.div`
  background: linear-gradient(90deg, #eb6502 0%, rgba(255, 164, 102, 0.6) 100%);
  position: absolute;
  bottom: 30%;
  padding: 10px 20px;
`;
const SubTitle = styled.h3`
  font-family: "gellix-light";
  letter-spacing: 2px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
`;
const Line = styled.div`
  background-color: var(--orange);
  width: 60px;
  height: 1px;
  margin: ${({ type }) => (type == "str" ? "" : "0 auto")};
`;
const Title = styled.h1`
  font-family: "gellix-medium";
  color: var(--orange);
  text-align: center;
  margin: 20px 0;
  text-transform: uppercase;
  @media (max-width: 480px) {
    font-size: 20px;
    letter-spacing: 2px;
  }
`;

const Qoute = styled.div`
  width: 40px;
  @media (max-width: 1650px) {
    width: 30px;
  }
  @media (max-width: 480px) {
    width: 30px;
  }
`;
const DetailSection = styled.div`
  width: 50%;
  @media all and (max-width: 1440px) {
    width: 50%;
  }
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Description = styled.p`
  color: #fff;
  line-height: 28px;
  @media (max-width: 1650px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;
const DescriptionMob = styled.p`
  display: none;
  @media (max-width: 1650px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    text-align: justify;
    color: #fff;
    display: block;
    line-height: 22px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
    text-align: justify;
  }
`;
const CoverName = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Name = styled.h1`
  font-family: "gellix-bold";
  color: var(--orange);
  @media (max-width: 1650px) {
    font-size: 24px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;
const ImageContainer = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const CoverNameText = styled.div``;
const CoverSpan = styled.div`
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
const SpanText = styled.div`
  color: #c9c9c9;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &.video {
    color: #fff;
  }
`;
