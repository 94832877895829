import React, { useEffect, useRef, useState } from "react";
import Banner from "./key/Banner";
import styled from "styled-components";
import ViewDetail from "./key/ViewDetail";
import Header from "../Header";
import arrowLeft from "../../../assets/images/apartments/gallery/newDesign/icons/leftArr.svg";
import arrowRight from "../../../assets/images/apartments/gallery/newDesign/icons/rightarr.svg";

import Slider from "react-slick";
import { accountsConfig } from "../../../axiosConfig";
import Footer from "../Footer";
import KeyHandOverModal from "../modal/KeyHandOverModal";
import Helmet from "../Helmet";

export default function KeyHandler() {
  const [fetchId, setFetchid] = useState("");
  const [fetchIndex, setFetchindex] = useState("");
  const [keyHandover, setKeyHandover] = useState([]);
  const [fetchItem, setFetchItem] = useState([]);
  const [modal, setModal] = useState(false);
  const [current, setcurrent] = useState("");
  const [datas, setdatas] = useState({});
  
  const scrollRef = useRef(null);
  const TopRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  
  useEffect(() => {
    accountsConfig.get('web/list-link-seo/',{
      params:{
        links:window.location.pathname
      }
    })
    .then((res) => {
     const { data , StatusCode } = res.data.app_data;
       if(StatusCode === 6000){
          setdatas(data[0])
       }else{
        setdatas({})
       }
    })
  },[])

  const scrollFunction = () => {
    if (scrollRef) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollTop = () => {
    if (TopRef) {
      TopRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    accountsConfig
      .get(`customer_celebrations/list-keyhandover/`, {})
      .then((res) => {
        const data = res.data.app_data.data;
        if (res.data.app_data.StatusCode == 6000) {
          setKeyHandover(data);
        } else {
          setKeyHandover([]);
        }
      });
  }, []);
  
  return (
    <Container>
      <Helmet titleData={datas?.meta_title} descriptionData={datas?.meta_description} />
      <Header />
      <Banner />
      <Content>
        <Wrapper className="wrapper" ref={TopRef}>

          {fetchId && (
            <Top>
              <Cover>
                <Card
                  onClick={() => {
                      setFetchid("");
                      setFetchindex('');
                  }}
                >
                  <Frame></Frame>
                  <ImageContainer>
                    <img src={keyHandover[fetchIndex].image} alt="image" />
                  </ImageContainer>
                </Card>
                <Name dangerouslySetInnerHTML={{ __html: keyHandover[fetchIndex]?.name }}></Name>
              </Cover>
              <ViewDetail data={keyHandover[fetchIndex].key_assets} fetchedData={keyHandover[fetchIndex]} />
            </Top>
          )}

          <Cards>
            {keyHandover.map((item, index) => (
              <>
                <Cover>
                  <Card
                    onClick={() => {
                      if (fetchId === item.id) {
                        setFetchid("");
                        setFetchindex('');
                      } else {
                        setFetchid(item.id);
                        setFetchindex(index);
                        scrollTop();
                      }
                    }}
                  >
                    <Frame></Frame>
                    <ImageContainer>
                      <img src={item.image} alt="image" />
                    </ImageContainer>
                  </Card>
                  <Name dangerouslySetInnerHTML={{ __html: item?.name }}></Name>
                </Cover>
              </>
            ))}
          </Cards>
          {/* ----------------mobile----------------- */}
          <MobCards>
            {}
            <Slider {...settings}>
              {keyHandover.map((item, index) => (
                <MobCard
                  onClick={() => {
                    // setFetchid(item.id);
                    // scrollFunction();
                    // setFetchItem(item);
                    setcurrent(item);
                    setModal(true);
                  }}
                >
                  <MobBottom>
                    <MobImgContainer>
                    <img src={item.image} alt="image" />
                    </MobImgContainer>
                  </MobBottom>
                  <MobText>
                    <MobName>{item.name}</MobName>
                    <Designation>
                      {item.designation && item.designation + ","}{" "}
                      {item.location}
                    </Designation>
                  </MobText>
                </MobCard>
              ))}
            </Slider>
          </MobCards>
          <KeyHandOverModal
              modal={modal}
              setModal={setModal}
              assets={current?.key_assets}
              current={current?.id}
            />
          <GallerySection ref={scrollRef}>
            <Section>
              <CoverBox>
                <Box>
                  <img
                    src={
                      fetchItem?.key_assets && fetchItem.key_assets[0]?.images
                    }
                    alt=""
                  />
                </Box>
                <Box>
                  <img
                    src={
                      fetchItem?.key_assets && fetchItem.key_assets[1]?.images
                    }
                    alt=""
                  />
                </Box>
              </CoverBox>
              <CoverBox>
                <Box>
                  <img
                    src={
                      fetchItem?.key_assets && fetchItem.key_assets[0]?.images
                    }
                    alt=""
                  />
                </Box>
                <Box>
                  <img
                    src={
                      fetchItem?.key_assets && fetchItem.key_assets[1]?.images
                    }
                    alt=""
                  />
                </Box>
              </CoverBox>
            </Section>
          </GallerySection>
        </Wrapper>
      </Content>
      <Footer />
    </Container>
  );
}

const Container = styled.div``;
const GallerySection = styled.div`
  margin-top: 80px;
  display: none;
  @media all and (max-width: 480px) {
    display: block;
  }
`;
const Section = styled.div`
  display: flex;
  gap: 5px;
`;
const CoverBox = styled.div``;
const Box = styled.div`
  margin-bottom: 5px;
`;
const Cover = styled.div`
  width: 24%;
  cursor: pointer;
  position: relative;
  @media all and (max-width: 1440px) {
    width: 22%;
  }
  @media all and (max-width: 1200px) {
    width: 30%;
  }
  @media all and (max-width: 768px) {
    width: 40%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Content = styled.div`
  padding: 80px 0;
  background-color: #f8f8f8;

  @media (max-width: 580px) {
    padding: 0px 0;
  }
`;
const Wrapper = styled.div`
  width: 90% !important;
`;
const Cards = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  @media all and (max-width: 580px) {
    display: none;
  }
`;
const Card = styled.div`
  width: 100%;
  position: relative;
  @media all and (max-width: 1440px) {
    width: 22%;
  }
  @media all and (max-width: 1440px) {
    width: 28%;
  }
  @media all and (max-width: 1440px) {
    width: 100%;
  }
`;
const Frame = styled.div`
  border: 1px solid #a8a8a8;
  padding: 20px;
  width: 90%;
  position: absolute;
  box-sizing: border-box;
  height: 90%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
`;
const ImageContainer = styled.div`
  /* position: relative; */
`;
const Name = styled.p`
  color: #ffffff;
  font-family: "gellix-medium";
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #eb6502;
  padding: 10px;
  border-radius: 0px 0px 25px 0px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const MobName = styled.h1`
  font-family: "gellix-medium";
  color: var(--orange);
  font-family: "gellix-medium";
  @media (max-width: 580px) {
    font-size: 16px;
    width:95%;
  }
`;
const MobCards = styled.div`
  display: none;
  .slick-next {
    top: calc(100% + 25px);
    right: 30% !important;
    display: block !important;
  }
  .slick-prev {
    top: calc(100% + 25px);
    left: 15% !important;
    display: block !important;
  }

  .slick-next:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowRight}) !important;
    background-size: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 60px;
  }
  .slick-prev:before {
    display: flex !important;
    content: "" !important;
    background-image: url(${arrowLeft}) !important;
    background-size: contain;
    background-repeat: no-repeat;
    height: 60px;
    width:60px;
  }

  @media all and (max-width: 580px) {
    display: block;
  }
`;
const MobCard = styled.div`
  background-color: #fff;
  padding: 20px;
  cursor: pointer;

  @media screen  and (max-width:580px) {
       height:320px !important;
  }
`;
const MobTop = styled.div`
  display: flex;
  gap: 5px;
`;
const MobBottom = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 5px;

  @media screen and (max-width:580px) {
      height: 80%;
  } 
`;
const MobText = styled.div`
  margin-top: 10px;
`;
const Designation = styled.div`
  color: #606060;

  @media screen and (max-width: 580px) {
     font-size: 14px;
     margin-top: 5px;
  }
`;
const MobImgContainer = styled.div`
  &.client {
    width: 40%;
    img {
      height: 100%;
    }
  }
  &.pic {
    width: 60%;
  }
  /* img {
    height: 100%;
  } */
`;

const Top = styled.div`
      display: flex;
      flex-direction: column;
      gap:25px;
      margin-bottom: 20px;
      justify-content: center;
      align-items:center;
`;
